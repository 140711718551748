/**
 * @generated SignedSource<<ed058ebb6c72c18138dfa4df534b192e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SecuritiesIssueFilter = {
  and?: ReadonlyArray<SecuritiesIssueFilter> | null;
  byId?: IntFilter | null;
  maxUnitsPerSubscriber?: IntFilter | null;
  not?: SecuritiesIssueFilter | null;
  ofId?: IntFilter | null;
  or?: ReadonlyArray<SecuritiesIssueFilter> | null;
  originationDate?: DateFilter | null;
  proceedsId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByOfId?: SecuritiesAssetFilter | null;
  securitiesAssetByUnitValueAssetId?: SecuritiesAssetFilter | null;
  securitiesAssetByUnitValueAssetIdExists?: boolean | null;
  securitiesIssueDocumentsByIssueId?: SecuritiesIssueToManySecuritiesIssueDocumentFilter | null;
  securitiesIssueDocumentsByIssueIdExist?: boolean | null;
  securitiesIssueoffersByBonusId?: SecuritiesIssueToManySecuritiesIssueofferFilter | null;
  securitiesIssueoffersByBonusIdExist?: boolean | null;
  securitiesIssueoffersByIssueId?: SecuritiesIssueToManySecuritiesIssueofferFilter | null;
  securitiesIssueoffersByIssueIdExist?: boolean | null;
  securitiesOriginationByIssueId?: SecuritiesOriginationFilter | null;
  securitiesOriginationByIssueIdExists?: boolean | null;
  securitiesPocketByProceedsId?: SecuritiesPocketFilter | null;
  securitiesPocketByProceedsIdExists?: boolean | null;
  securitiesProfileByById?: SecuritiesProfileFilter | null;
  securitiesProfileByByIdExists?: boolean | null;
  unitValue?: BigIntFilter | null;
  unitValueAssetId?: IntFilter | null;
  units?: IntFilter | null;
};
export type IntFilter = {
  distinctFrom?: number | null;
  equalTo?: number | null;
  greaterThan?: number | null;
  greaterThanOrEqualTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  notDistinctFrom?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
};
export type DateFilter = {
  distinctFrom?: any | null;
  equalTo?: any | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any> | null;
  isNull?: boolean | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notDistinctFrom?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any> | null;
};
export type BigIntFilter = {
  distinctFrom?: number | null;
  equalTo?: number | null;
  greaterThan?: number | null;
  greaterThanOrEqualTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  notDistinctFrom?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
};
export type SecuritiesIssueToManySecuritiesIssueDocumentFilter = {
  every?: SecuritiesIssueDocumentFilter | null;
  none?: SecuritiesIssueDocumentFilter | null;
  some?: SecuritiesIssueDocumentFilter | null;
};
export type SecuritiesIssueDocumentFilter = {
  and?: ReadonlyArray<SecuritiesIssueDocumentFilter> | null;
  issueId?: IntFilter | null;
  legaldocId?: IntFilter | null;
  not?: SecuritiesIssueDocumentFilter | null;
  or?: ReadonlyArray<SecuritiesIssueDocumentFilter> | null;
  rowId?: IntFilter | null;
  securitiesIssueByIssueId?: SecuritiesIssueFilter | null;
  securitiesLegaldocByLegaldocId?: SecuritiesLegaldocFilter | null;
};
export type SecuritiesLegaldocFilter = {
  and?: ReadonlyArray<SecuritiesLegaldocFilter> | null;
  file?: StringFilter | null;
  isDefault?: BooleanFilter | null;
  locale?: StringFilter | null;
  not?: SecuritiesLegaldocFilter | null;
  or?: ReadonlyArray<SecuritiesLegaldocFilter> | null;
  policyId?: IntFilter | null;
  role?: StringFilter | null;
  rowId?: IntFilter | null;
  securitiesAgreementsByLegaldocId?: SecuritiesLegaldocToManySecuritiesAgreementFilter | null;
  securitiesAgreementsByLegaldocIdExist?: boolean | null;
  securitiesAssetDocumentsByLegaldocId?: SecuritiesLegaldocToManySecuritiesAssetDocumentFilter | null;
  securitiesAssetDocumentsByLegaldocIdExist?: boolean | null;
  securitiesDiscretionarygroupsByMembershipTermsId?: SecuritiesLegaldocToManySecuritiesDiscretionarygroupFilter | null;
  securitiesDiscretionarygroupsByMembershipTermsIdExist?: boolean | null;
  securitiesIssueDocumentsByLegaldocId?: SecuritiesLegaldocToManySecuritiesIssueDocumentFilter | null;
  securitiesIssueDocumentsByLegaldocIdExist?: boolean | null;
  securitiesPolicyByPolicyId?: SecuritiesPolicyFilter | null;
  securitiesPolicyByPolicyIdExists?: boolean | null;
};
export type StringFilter = {
  distinctFrom?: string | null;
  distinctFromInsensitive?: string | null;
  endsWith?: string | null;
  endsWithInsensitive?: string | null;
  equalTo?: string | null;
  equalToInsensitive?: string | null;
  greaterThan?: string | null;
  greaterThanInsensitive?: string | null;
  greaterThanOrEqualTo?: string | null;
  greaterThanOrEqualToInsensitive?: string | null;
  in?: ReadonlyArray<string> | null;
  inInsensitive?: ReadonlyArray<string> | null;
  includes?: string | null;
  includesInsensitive?: string | null;
  isNull?: boolean | null;
  lessThan?: string | null;
  lessThanInsensitive?: string | null;
  lessThanOrEqualTo?: string | null;
  lessThanOrEqualToInsensitive?: string | null;
  like?: string | null;
  likeInsensitive?: string | null;
  notDistinctFrom?: string | null;
  notDistinctFromInsensitive?: string | null;
  notEndsWith?: string | null;
  notEndsWithInsensitive?: string | null;
  notEqualTo?: string | null;
  notEqualToInsensitive?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notInInsensitive?: ReadonlyArray<string> | null;
  notIncludes?: string | null;
  notIncludesInsensitive?: string | null;
  notLike?: string | null;
  notLikeInsensitive?: string | null;
  notStartsWith?: string | null;
  notStartsWithInsensitive?: string | null;
  startsWith?: string | null;
  startsWithInsensitive?: string | null;
};
export type BooleanFilter = {
  distinctFrom?: boolean | null;
  equalTo?: boolean | null;
  greaterThan?: boolean | null;
  greaterThanOrEqualTo?: boolean | null;
  in?: ReadonlyArray<boolean> | null;
  isNull?: boolean | null;
  lessThan?: boolean | null;
  lessThanOrEqualTo?: boolean | null;
  notDistinctFrom?: boolean | null;
  notEqualTo?: boolean | null;
  notIn?: ReadonlyArray<boolean> | null;
};
export type SecuritiesLegaldocToManySecuritiesAgreementFilter = {
  every?: SecuritiesAgreementFilter | null;
  none?: SecuritiesAgreementFilter | null;
  some?: SecuritiesAgreementFilter | null;
};
export type SecuritiesAgreementFilter = {
  and?: ReadonlyArray<SecuritiesAgreementFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  completedAt?: DatetimeFilter | null;
  legaldocId?: IntFilter | null;
  not?: SecuritiesAgreementFilter | null;
  or?: ReadonlyArray<SecuritiesAgreementFilter> | null;
  profileId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesLegaldocByLegaldocId?: SecuritiesLegaldocFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesProfileByProfileIdExists?: boolean | null;
  userId?: IntFilter | null;
};
export type DatetimeFilter = {
  distinctFrom?: any | null;
  equalTo?: any | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any> | null;
  isNull?: boolean | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notDistinctFrom?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any> | null;
};
export type AuthUserFilter = {
  accountsCwReferrallandingdatumByReferralId?: AccountsCwReferrallandingdatumFilter | null;
  accountsCwReferrallandingdatumByReferralIdExists?: boolean | null;
  and?: ReadonlyArray<AuthUserFilter> | null;
  authUserGroupsByUserId?: AuthUserToManyAuthUserGroupFilter | null;
  authUserGroupsByUserIdExist?: boolean | null;
  authUserUserPermissionsByUserId?: AuthUserToManyAuthUserUserPermissionFilter | null;
  authUserUserPermissionsByUserIdExist?: boolean | null;
  authtokenTokenByUserId?: AuthtokenTokenFilter | null;
  authtokenTokenByUserIdExists?: boolean | null;
  cmsGlobalpagepermissionsByUserId?: AuthUserToManyCmsGlobalpagepermissionFilter | null;
  cmsGlobalpagepermissionsByUserIdExist?: boolean | null;
  cmsPagepermissionsByUserId?: AuthUserToManyCmsPagepermissionFilter | null;
  cmsPagepermissionsByUserIdExist?: boolean | null;
  cmsPageuserByUserPtrId?: CmsPageuserFilter | null;
  cmsPageuserByUserPtrIdExists?: boolean | null;
  cmsPageusergroupsByCreatedById?: AuthUserToManyCmsPageusergroupFilter | null;
  cmsPageusergroupsByCreatedByIdExist?: boolean | null;
  cmsPageusersByCreatedById?: AuthUserToManyCmsPageuserFilter | null;
  cmsPageusersByCreatedByIdExist?: boolean | null;
  cmsUsersettingByUserId?: CmsUsersettingFilter | null;
  cmsUsersettingByUserIdExists?: boolean | null;
  dateJoined?: DatetimeFilter | null;
  djangoAdminLogsByUserId?: AuthUserToManyDjangoAdminLogFilter | null;
  djangoAdminLogsByUserIdExist?: boolean | null;
  djangocmsFormsFormsubmissionsByCreatedById?: AuthUserToManyDjangocmsFormsFormsubmissionFilter | null;
  djangocmsFormsFormsubmissionsByCreatedByIdExist?: boolean | null;
  email?: StringFilter | null;
  filerClipboardsByUserId?: AuthUserToManyFilerClipboardFilter | null;
  filerClipboardsByUserIdExist?: boolean | null;
  filerFilesByOwnerId?: AuthUserToManyFilerFileFilter | null;
  filerFilesByOwnerIdExist?: boolean | null;
  filerFolderpermissionsByUserId?: AuthUserToManyFilerFolderpermissionFilter | null;
  filerFolderpermissionsByUserIdExist?: boolean | null;
  filerFoldersByOwnerId?: AuthUserToManyFilerFolderFilter | null;
  filerFoldersByOwnerIdExist?: boolean | null;
  firstName?: StringFilter | null;
  gameScoresByUserId?: AuthUserToManyGameScoreFilter | null;
  gameScoresByUserIdExist?: boolean | null;
  guardianUserobjectpermissionsByUserId?: AuthUserToManyGuardianUserobjectpermissionFilter | null;
  guardianUserobjectpermissionsByUserIdExist?: boolean | null;
  isActive?: BooleanFilter | null;
  isStaff?: BooleanFilter | null;
  isSuperuser?: BooleanFilter | null;
  lastLogin?: DatetimeFilter | null;
  lastName?: StringFilter | null;
  mamaCasProxygrantingticketsByUserId?: AuthUserToManyMamaCasProxygrantingticketFilter | null;
  mamaCasProxygrantingticketsByUserIdExist?: boolean | null;
  mamaCasProxyticketsByUserId?: AuthUserToManyMamaCasProxyticketFilter | null;
  mamaCasProxyticketsByUserIdExist?: boolean | null;
  mamaCasServiceticketsByUserId?: AuthUserToManyMamaCasServiceticketFilter | null;
  mamaCasServiceticketsByUserIdExist?: boolean | null;
  not?: AuthUserFilter | null;
  notificationsNotificationsByRecipientId?: AuthUserToManyNotificationsNotificationFilter | null;
  notificationsNotificationsByRecipientIdExist?: boolean | null;
  oauth2ProviderAccesstokensByUserId?: AuthUserToManyOauth2ProviderAccesstokenFilter | null;
  oauth2ProviderAccesstokensByUserIdExist?: boolean | null;
  oauth2ProviderApplicationsByUserId?: AuthUserToManyOauth2ProviderApplicationFilter | null;
  oauth2ProviderApplicationsByUserIdExist?: boolean | null;
  oauth2ProviderGrantsByUserId?: AuthUserToManyOauth2ProviderGrantFilter | null;
  oauth2ProviderGrantsByUserIdExist?: boolean | null;
  oauth2ProviderRefreshtokensByUserId?: AuthUserToManyOauth2ProviderRefreshtokenFilter | null;
  oauth2ProviderRefreshtokensByUserIdExist?: boolean | null;
  or?: ReadonlyArray<AuthUserFilter> | null;
  password?: StringFilter | null;
  paypalOrdersByUserId?: AuthUserToManyPaypalOrderFilter | null;
  paypalOrdersByUserIdExist?: boolean | null;
  registrationRegistrationprofileByUserId?: RegistrationRegistrationprofileFilter | null;
  registrationRegistrationprofileByUserIdExists?: boolean | null;
  rowId?: IntFilter | null;
  securitiesAgreementsByUserId?: AuthUserToManySecuritiesAgreementFilter | null;
  securitiesAgreementsByUserIdExist?: boolean | null;
  securitiesCancelordersByOriginatorId?: AuthUserToManySecuritiesCancelorderFilter | null;
  securitiesCancelordersByOriginatorIdExist?: boolean | null;
  securitiesGrantsByInstigatorId?: AuthUserToManySecuritiesGrantFilter | null;
  securitiesGrantsByInstigatorIdExist?: boolean | null;
  securitiesGrantsByUserId?: AuthUserToManySecuritiesGrantFilter | null;
  securitiesGrantsByUserIdExist?: boolean | null;
  securitiesOrdersByOriginatorId?: AuthUserToManySecuritiesOrderFilter | null;
  securitiesOrdersByOriginatorIdExist?: boolean | null;
  securitiesOriginationfeesByUserId?: AuthUserToManySecuritiesOriginationfeeFilter | null;
  securitiesOriginationfeesByUserIdExist?: boolean | null;
  securitiesProfilesByReferralUserId?: AuthUserToManySecuritiesProfileFilter | null;
  securitiesProfilesByReferralUserIdExist?: boolean | null;
  securitiesProfilesByUserId?: AuthUserToManySecuritiesProfileFilter | null;
  securitiesProfilesByUserIdExist?: boolean | null;
  securitiesReferralfingerprintsByReferralId?: AuthUserToManySecuritiesReferralfingerprintFilter | null;
  securitiesReferralfingerprintsByReferralIdExist?: boolean | null;
  securitiesUserpreferenceByUserId?: SecuritiesUserpreferenceFilter | null;
  securitiesUserpreferenceByUserIdExists?: boolean | null;
  username?: StringFilter | null;
  viewflowTasksByOwnerId?: AuthUserToManyViewflowTaskFilter | null;
  viewflowTasksByOwnerIdExist?: boolean | null;
};
export type AccountsCwReferrallandingdatumFilter = {
  and?: ReadonlyArray<AccountsCwReferrallandingdatumFilter> | null;
  authUserByReferralId?: AuthUserFilter | null;
  message?: StringFilter | null;
  not?: AccountsCwReferrallandingdatumFilter | null;
  or?: ReadonlyArray<AccountsCwReferrallandingdatumFilter> | null;
  referralId?: IntFilter | null;
  rowId?: IntFilter | null;
};
export type AuthUserToManyAuthUserGroupFilter = {
  every?: AuthUserGroupFilter | null;
  none?: AuthUserGroupFilter | null;
  some?: AuthUserGroupFilter | null;
};
export type AuthUserGroupFilter = {
  and?: ReadonlyArray<AuthUserGroupFilter> | null;
  authGroupByGroupId?: AuthGroupFilter | null;
  authUserByUserId?: AuthUserFilter | null;
  groupId?: IntFilter | null;
  not?: AuthUserGroupFilter | null;
  or?: ReadonlyArray<AuthUserGroupFilter> | null;
  rowId?: IntFilter | null;
  userId?: IntFilter | null;
};
export type AuthGroupFilter = {
  and?: ReadonlyArray<AuthGroupFilter> | null;
  authGroupPermissionsByGroupId?: AuthGroupToManyAuthGroupPermissionFilter | null;
  authGroupPermissionsByGroupIdExist?: boolean | null;
  authUserGroupsByGroupId?: AuthGroupToManyAuthUserGroupFilter | null;
  authUserGroupsByGroupIdExist?: boolean | null;
  cmsGlobalpagepermissionsByGroupId?: AuthGroupToManyCmsGlobalpagepermissionFilter | null;
  cmsGlobalpagepermissionsByGroupIdExist?: boolean | null;
  cmsPagepermissionsByGroupId?: AuthGroupToManyCmsPagepermissionFilter | null;
  cmsPagepermissionsByGroupIdExist?: boolean | null;
  cmsPageusergroupByGroupPtrId?: CmsPageusergroupFilter | null;
  cmsPageusergroupByGroupPtrIdExists?: boolean | null;
  filerFolderpermissionsByGroupId?: AuthGroupToManyFilerFolderpermissionFilter | null;
  filerFolderpermissionsByGroupIdExist?: boolean | null;
  guardianGroupobjectpermissionsByGroupId?: AuthGroupToManyGuardianGroupobjectpermissionFilter | null;
  guardianGroupobjectpermissionsByGroupIdExist?: boolean | null;
  name?: StringFilter | null;
  not?: AuthGroupFilter | null;
  or?: ReadonlyArray<AuthGroupFilter> | null;
  rowId?: IntFilter | null;
};
export type AuthGroupToManyAuthGroupPermissionFilter = {
  every?: AuthGroupPermissionFilter | null;
  none?: AuthGroupPermissionFilter | null;
  some?: AuthGroupPermissionFilter | null;
};
export type AuthGroupPermissionFilter = {
  and?: ReadonlyArray<AuthGroupPermissionFilter> | null;
  authGroupByGroupId?: AuthGroupFilter | null;
  authPermissionByPermissionId?: AuthPermissionFilter | null;
  groupId?: IntFilter | null;
  not?: AuthGroupPermissionFilter | null;
  or?: ReadonlyArray<AuthGroupPermissionFilter> | null;
  permissionId?: IntFilter | null;
  rowId?: IntFilter | null;
};
export type AuthPermissionFilter = {
  and?: ReadonlyArray<AuthPermissionFilter> | null;
  authGroupPermissionsByPermissionId?: AuthPermissionToManyAuthGroupPermissionFilter | null;
  authGroupPermissionsByPermissionIdExist?: boolean | null;
  authUserUserPermissionsByPermissionId?: AuthPermissionToManyAuthUserUserPermissionFilter | null;
  authUserUserPermissionsByPermissionIdExist?: boolean | null;
  codename?: StringFilter | null;
  contentTypeId?: IntFilter | null;
  djangoContentTypeByContentTypeId?: DjangoContentTypeFilter | null;
  guardianGroupobjectpermissionsByPermissionId?: AuthPermissionToManyGuardianGroupobjectpermissionFilter | null;
  guardianGroupobjectpermissionsByPermissionIdExist?: boolean | null;
  guardianUserobjectpermissionsByPermissionId?: AuthPermissionToManyGuardianUserobjectpermissionFilter | null;
  guardianUserobjectpermissionsByPermissionIdExist?: boolean | null;
  name?: StringFilter | null;
  not?: AuthPermissionFilter | null;
  or?: ReadonlyArray<AuthPermissionFilter> | null;
  rowId?: IntFilter | null;
};
export type AuthPermissionToManyAuthGroupPermissionFilter = {
  every?: AuthGroupPermissionFilter | null;
  none?: AuthGroupPermissionFilter | null;
  some?: AuthGroupPermissionFilter | null;
};
export type AuthPermissionToManyAuthUserUserPermissionFilter = {
  every?: AuthUserUserPermissionFilter | null;
  none?: AuthUserUserPermissionFilter | null;
  some?: AuthUserUserPermissionFilter | null;
};
export type AuthUserUserPermissionFilter = {
  and?: ReadonlyArray<AuthUserUserPermissionFilter> | null;
  authPermissionByPermissionId?: AuthPermissionFilter | null;
  authUserByUserId?: AuthUserFilter | null;
  not?: AuthUserUserPermissionFilter | null;
  or?: ReadonlyArray<AuthUserUserPermissionFilter> | null;
  permissionId?: IntFilter | null;
  rowId?: IntFilter | null;
  userId?: IntFilter | null;
};
export type AuthPermissionToManyGuardianGroupobjectpermissionFilter = {
  every?: GuardianGroupobjectpermissionFilter | null;
  none?: GuardianGroupobjectpermissionFilter | null;
  some?: GuardianGroupobjectpermissionFilter | null;
};
export type GuardianGroupobjectpermissionFilter = {
  and?: ReadonlyArray<GuardianGroupobjectpermissionFilter> | null;
  authGroupByGroupId?: AuthGroupFilter | null;
  authPermissionByPermissionId?: AuthPermissionFilter | null;
  contentTypeId?: IntFilter | null;
  djangoContentTypeByContentTypeId?: DjangoContentTypeFilter | null;
  groupId?: IntFilter | null;
  not?: GuardianGroupobjectpermissionFilter | null;
  objectPk?: StringFilter | null;
  or?: ReadonlyArray<GuardianGroupobjectpermissionFilter> | null;
  permissionId?: IntFilter | null;
  rowId?: IntFilter | null;
};
export type DjangoContentTypeFilter = {
  and?: ReadonlyArray<DjangoContentTypeFilter> | null;
  appLabel?: StringFilter | null;
  authPermissionsByContentTypeId?: DjangoContentTypeToManyAuthPermissionFilter | null;
  authPermissionsByContentTypeIdExist?: boolean | null;
  djangoAdminLogsByContentTypeId?: DjangoContentTypeToManyDjangoAdminLogFilter | null;
  djangoAdminLogsByContentTypeIdExist?: boolean | null;
  filerFilesByPolymorphicCtypeId?: DjangoContentTypeToManyFilerFileFilter | null;
  filerFilesByPolymorphicCtypeIdExist?: boolean | null;
  guardianGroupobjectpermissionsByContentTypeId?: DjangoContentTypeToManyGuardianGroupobjectpermissionFilter | null;
  guardianGroupobjectpermissionsByContentTypeIdExist?: boolean | null;
  guardianUserobjectpermissionsByContentTypeId?: DjangoContentTypeToManyGuardianUserobjectpermissionFilter | null;
  guardianUserobjectpermissionsByContentTypeIdExist?: boolean | null;
  model?: StringFilter | null;
  not?: DjangoContentTypeFilter | null;
  notificationsNotificationsByActionObjectContentTypeId?: DjangoContentTypeToManyNotificationsNotificationFilter | null;
  notificationsNotificationsByActionObjectContentTypeIdExist?: boolean | null;
  notificationsNotificationsByActorContentTypeId?: DjangoContentTypeToManyNotificationsNotificationFilter | null;
  notificationsNotificationsByActorContentTypeIdExist?: boolean | null;
  notificationsNotificationsByTargetContentTypeId?: DjangoContentTypeToManyNotificationsNotificationFilter | null;
  notificationsNotificationsByTargetContentTypeIdExist?: boolean | null;
  or?: ReadonlyArray<DjangoContentTypeFilter> | null;
  rosettaClasscontentsByClassCtypeId?: DjangoContentTypeToManyRosettaClasscontentFilter | null;
  rosettaClasscontentsByClassCtypeIdExist?: boolean | null;
  rowId?: IntFilter | null;
  securitiesActivitiesByPolymorphicCtypeId?: DjangoContentTypeToManySecuritiesActivityFilter | null;
  securitiesActivitiesByPolymorphicCtypeIdExist?: boolean | null;
  securitiesAssetsByPolymorphicCtypeId?: DjangoContentTypeToManySecuritiesAssetFilter | null;
  securitiesAssetsByPolymorphicCtypeIdExist?: boolean | null;
  securitiesAuthorizationrulesByCtypeId?: DjangoContentTypeToManySecuritiesAuthorizationruleFilter | null;
  securitiesAuthorizationrulesByCtypeIdExist?: boolean | null;
  securitiesDiscretionarycontentsByPolymorphicCtypeId?: DjangoContentTypeToManySecuritiesDiscretionarycontentFilter | null;
  securitiesDiscretionarycontentsByPolymorphicCtypeIdExist?: boolean | null;
  securitiesLinksByPolymorphicCtypeId?: DjangoContentTypeToManySecuritiesLinkFilter | null;
  securitiesLinksByPolymorphicCtypeIdExist?: boolean | null;
  securitiesTransactionsByPolymorphicCtypeId?: DjangoContentTypeToManySecuritiesTransactionFilter | null;
  securitiesTransactionsByPolymorphicCtypeIdExist?: boolean | null;
  viewflowProcessesByArtifactContentTypeId?: DjangoContentTypeToManyViewflowProcessFilter | null;
  viewflowProcessesByArtifactContentTypeIdExist?: boolean | null;
  viewflowTasksByArtifactContentTypeId?: DjangoContentTypeToManyViewflowTaskFilter | null;
  viewflowTasksByArtifactContentTypeIdExist?: boolean | null;
};
export type DjangoContentTypeToManySecuritiesAssetFilter = {
  every?: SecuritiesAssetFilter | null;
  none?: SecuritiesAssetFilter | null;
  some?: SecuritiesAssetFilter | null;
};
export type SecuritiesAssetFilter = {
  and?: ReadonlyArray<SecuritiesAssetFilter> | null;
  assetClassId?: IntFilter | null;
  citiesLightCountryByDomicileId?: CitiesLightCountryFilter | null;
  citiesLightCountryByDomicileIdExists?: boolean | null;
  code?: StringFilter | null;
  decimals?: IntFilter | null;
  decimalsMin?: IntFilter | null;
  djangoContentTypeByPolymorphicCtypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByPolymorphicCtypeIdExists?: boolean | null;
  domicileId?: IntFilter | null;
  featuredArtId?: IntFilter | null;
  filerImageByFeaturedArtId?: FilerImageFilter | null;
  filerImageByFeaturedArtIdExists?: boolean | null;
  fungibility?: StringFilter | null;
  gameGamesByNotionalAssetId?: SecuritiesAssetToManyGameGameFilter | null;
  gameGamesByNotionalAssetIdExist?: boolean | null;
  icon?: StringFilter | null;
  isActive?: BooleanFilter | null;
  isBurnEnabled?: BooleanFilter | null;
  isEligibilityCheckRequired?: BooleanFilter | null;
  isMintEnabled?: BooleanFilter | null;
  isOfferGroup?: BooleanFilter | null;
  isPromoted?: BooleanFilter | null;
  isQuestionnaireForDerivatives?: BooleanFilter | null;
  isQuestionnaireForSelf?: BooleanFilter | null;
  isStreetEnabled?: BooleanFilter | null;
  isTransferable?: BooleanFilter | null;
  isTransparencyEnabled?: BooleanFilter | null;
  issuerId?: IntFilter | null;
  name?: StringFilter | null;
  not?: SecuritiesAssetFilter | null;
  notionalValue?: BigFloatFilter | null;
  or?: ReadonlyArray<SecuritiesAssetFilter> | null;
  polymorphicCtypeId?: IntFilter | null;
  reportingAssetconversionAssetsByAssetId?: SecuritiesAssetToManyReportingAssetconversionAssetFilter | null;
  reportingAssetconversionAssetsByAssetIdExist?: boolean | null;
  reportingAssetgroupAssetsByAssetId?: SecuritiesAssetToManyReportingAssetgroupAssetFilter | null;
  reportingAssetgroupAssetsByAssetIdExist?: boolean | null;
  reportingJournalsByAssetId?: SecuritiesAssetToManyReportingJournalFilter | null;
  reportingJournalsByAssetIdExist?: boolean | null;
  rowId?: IntFilter | null;
  scoreMultiplier?: BigFloatFilter | null;
  securitiesAssetByUnderlyingId?: SecuritiesAssetFilter | null;
  securitiesAssetByUnderlyingIdExists?: boolean | null;
  securitiesAssetDocumentsByAssetId?: SecuritiesAssetToManySecuritiesAssetDocumentFilter | null;
  securitiesAssetDocumentsByAssetIdExist?: boolean | null;
  securitiesAssetclassByAssetClassId?: SecuritiesAssetclassFilter | null;
  securitiesAssetclassByAssetClassIdExists?: boolean | null;
  securitiesAssetcontentsByObjId?: SecuritiesAssetToManySecuritiesAssetcontentFilter | null;
  securitiesAssetcontentsByObjIdExist?: boolean | null;
  securitiesAssetimagesByAssetId?: SecuritiesAssetToManySecuritiesAssetimageFilter | null;
  securitiesAssetimagesByAssetIdExist?: boolean | null;
  securitiesAssetsByUnderlyingId?: SecuritiesAssetToManySecuritiesAssetFilter | null;
  securitiesAssetsByUnderlyingIdExist?: boolean | null;
  securitiesCompletedquestionnairesByAssetId?: SecuritiesAssetToManySecuritiesCompletedquestionnaireFilter | null;
  securitiesCompletedquestionnairesByAssetIdExist?: boolean | null;
  securitiesConversiontargetsByAssetId?: SecuritiesAssetToManySecuritiesConversiontargetFilter | null;
  securitiesConversiontargetsByAssetIdExist?: boolean | null;
  securitiesDiscretionarygroupsByAssetId?: SecuritiesAssetToManySecuritiesDiscretionarygroupFilter | null;
  securitiesDiscretionarygroupsByAssetIdExist?: boolean | null;
  securitiesExternalassetByAssetPtrId?: SecuritiesExternalassetFilter | null;
  securitiesExternalassetByAssetPtrIdExists?: boolean | null;
  securitiesIssuesByOfId?: SecuritiesAssetToManySecuritiesIssueFilter | null;
  securitiesIssuesByOfIdExist?: boolean | null;
  securitiesIssuesByUnitValueAssetId?: SecuritiesAssetToManySecuritiesIssueFilter | null;
  securitiesIssuesByUnitValueAssetIdExist?: boolean | null;
  securitiesLinksByAssetId?: SecuritiesAssetToManySecuritiesLinkFilter | null;
  securitiesLinksByAssetIdExist?: boolean | null;
  securitiesMarketsByBaseAssetId?: SecuritiesAssetToManySecuritiesMarketFilter | null;
  securitiesMarketsByBaseAssetIdExist?: boolean | null;
  securitiesMarketsByQuoteAssetId?: SecuritiesAssetToManySecuritiesMarketFilter | null;
  securitiesMarketsByQuoteAssetIdExist?: boolean | null;
  securitiesNetworkassetByAssetPtrId?: SecuritiesNetworkassetFilter | null;
  securitiesNetworkassetByAssetPtrIdExists?: boolean | null;
  securitiesOriginationfeesByAssetId?: SecuritiesAssetToManySecuritiesOriginationfeeFilter | null;
  securitiesOriginationfeesByAssetIdExist?: boolean | null;
  securitiesPendingsByAssetId?: SecuritiesAssetToManySecuritiesPendingFilter | null;
  securitiesPendingsByAssetIdExist?: boolean | null;
  securitiesProfileByIssuerId?: SecuritiesProfileFilter | null;
  securitiesProfileByIssuerIdExists?: boolean | null;
  securitiesPropertyassetByAssetPtrId?: SecuritiesPropertyassetFilter | null;
  securitiesPropertyassetByAssetPtrIdExists?: boolean | null;
  securitiesStreetadsByAssetId?: SecuritiesAssetToManySecuritiesStreetadFilter | null;
  securitiesStreetadsByAssetIdExist?: boolean | null;
  securitiesStreetadsByQuoteAssetId?: SecuritiesAssetToManySecuritiesStreetadFilter | null;
  securitiesStreetadsByQuoteAssetIdExist?: boolean | null;
  securitiesVenueByVenueId?: SecuritiesVenueFilter | null;
  securitiesVenueByVenueIdExists?: boolean | null;
  securitiesVenuesByTargetQuoteAssetId?: SecuritiesAssetToManySecuritiesVenueFilter | null;
  securitiesVenuesByTargetQuoteAssetIdExist?: boolean | null;
  securitiesWalletsByAssetId?: SecuritiesAssetToManySecuritiesWalletFilter | null;
  securitiesWalletsByAssetIdExist?: boolean | null;
  sortTerm?: StringFilter | null;
  symbol?: StringFilter | null;
  transfersHoldsByAssetId?: SecuritiesAssetToManyTransfersHoldFilter | null;
  transfersHoldsByAssetIdExist?: boolean | null;
  transfersHoldsByExAssetId?: SecuritiesAssetToManyTransfersHoldFilter | null;
  transfersHoldsByExAssetIdExist?: boolean | null;
  underlyingId?: IntFilter | null;
  unit?: StringFilter | null;
  unitSize?: BigFloatFilter | null;
  useStaticIcon?: BooleanFilter | null;
  venueId?: IntFilter | null;
  visible?: BooleanFilter | null;
  visibleInvest?: BooleanFilter | null;
};
export type BigFloatFilter = {
  distinctFrom?: number | null;
  equalTo?: number | null;
  greaterThan?: number | null;
  greaterThanOrEqualTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  notDistinctFrom?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
};
export type SecuritiesAssetToManySecuritiesAssetFilter = {
  every?: SecuritiesAssetFilter | null;
  none?: SecuritiesAssetFilter | null;
  some?: SecuritiesAssetFilter | null;
};
export type SecuritiesAssetToManySecuritiesAssetcontentFilter = {
  every?: SecuritiesAssetcontentFilter | null;
  none?: SecuritiesAssetcontentFilter | null;
  some?: SecuritiesAssetcontentFilter | null;
};
export type SecuritiesAssetcontentFilter = {
  and?: ReadonlyArray<SecuritiesAssetcontentFilter> | null;
  caption?: StringFilter | null;
  description?: StringFilter | null;
  detail?: StringFilter | null;
  jumbo?: StringFilter | null;
  language?: StringFilter | null;
  name?: StringFilter | null;
  not?: SecuritiesAssetcontentFilter | null;
  objId?: IntFilter | null;
  or?: ReadonlyArray<SecuritiesAssetcontentFilter> | null;
  questionnaire?: StringFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByObjId?: SecuritiesAssetFilter | null;
};
export type SecuritiesAssetToManyGameGameFilter = {
  every?: GameGameFilter | null;
  none?: GameGameFilter | null;
  some?: GameGameFilter | null;
};
export type GameGameFilter = {
  and?: ReadonlyArray<GameGameFilter> | null;
  gameTrialsByGameId?: GameGameToManyGameTrialFilter | null;
  gameTrialsByGameIdExist?: boolean | null;
  not?: GameGameFilter | null;
  notionalAssetId?: IntFilter | null;
  or?: ReadonlyArray<GameGameFilter> | null;
  purseId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByNotionalAssetId?: SecuritiesAssetFilter | null;
  securitiesPocketByPurseId?: SecuritiesPocketFilter | null;
  subordinateWeight?: FloatFilter | null;
};
export type FloatFilter = {
  distinctFrom?: number | null;
  equalTo?: number | null;
  greaterThan?: number | null;
  greaterThanOrEqualTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  notDistinctFrom?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
};
export type GameGameToManyGameTrialFilter = {
  every?: GameTrialFilter | null;
  none?: GameTrialFilter | null;
  some?: GameTrialFilter | null;
};
export type GameTrialFilter = {
  and?: ReadonlyArray<GameTrialFilter> | null;
  endsAt?: DatetimeFilter | null;
  gameGameByGameId?: GameGameFilter | null;
  gameGameByGameIdExists?: boolean | null;
  gameId?: IntFilter | null;
  gamePayoutByTrialId?: GamePayoutFilter | null;
  gamePayoutByTrialIdExists?: boolean | null;
  gameScoresByTrialId?: GameTrialToManyGameScoreFilter | null;
  gameScoresByTrialIdExist?: boolean | null;
  nextUpdateAt?: DatetimeFilter | null;
  not?: GameTrialFilter | null;
  or?: ReadonlyArray<GameTrialFilter> | null;
  ranked?: IntFilter | null;
  rowId?: IntFilter | null;
};
export type GamePayoutFilter = {
  and?: ReadonlyArray<GamePayoutFilter> | null;
  gameTrialByTrialId?: GameTrialFilter | null;
  not?: GamePayoutFilter | null;
  or?: ReadonlyArray<GamePayoutFilter> | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
  trialId?: IntFilter | null;
};
export type SecuritiesTransactionFilter = {
  activityId?: IntFilter | null;
  and?: ReadonlyArray<SecuritiesTransactionFilter> | null;
  djangoContentTypeByPolymorphicCtypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByPolymorphicCtypeIdExists?: boolean | null;
  gamePayoutByTransactionPtrId?: GamePayoutFilter | null;
  gamePayoutByTransactionPtrIdExists?: boolean | null;
  not?: SecuritiesTransactionFilter | null;
  or?: ReadonlyArray<SecuritiesTransactionFilter> | null;
  polymorphicCtypeId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesActivityByActivityId?: SecuritiesActivityFilter | null;
  securitiesActivityByActivityIdExists?: boolean | null;
  securitiesCancelorderByTransactionPtrId?: SecuritiesCancelorderFilter | null;
  securitiesCancelorderByTransactionPtrIdExists?: boolean | null;
  securitiesCloseorderByTransactionPtrId?: SecuritiesCloseorderFilter | null;
  securitiesCloseorderByTransactionPtrIdExists?: boolean | null;
  securitiesErrorresolutionByTransactionPtrId?: SecuritiesErrorresolutionFilter | null;
  securitiesErrorresolutionByTransactionPtrIdExists?: boolean | null;
  securitiesGrantByTransactionPtrId?: SecuritiesGrantFilter | null;
  securitiesGrantByTransactionPtrIdExists?: boolean | null;
  securitiesGrantvestingByTransactionPtrId?: SecuritiesGrantvestingFilter | null;
  securitiesGrantvestingByTransactionPtrIdExists?: boolean | null;
  securitiesIssuesubscriptionByTransactionPtrId?: SecuritiesIssuesubscriptionFilter | null;
  securitiesIssuesubscriptionByTransactionPtrIdExists?: boolean | null;
  securitiesLedgersByTransactionId?: SecuritiesTransactionToManySecuritiesLedgerFilter | null;
  securitiesLedgersByTransactionIdExist?: boolean | null;
  securitiesLoadByTransactionPtrId?: SecuritiesLoadFilter | null;
  securitiesLoadByTransactionPtrIdExists?: boolean | null;
  securitiesMintfeeByTransactionPtrId?: SecuritiesMintfeeFilter | null;
  securitiesMintfeeByTransactionPtrIdExists?: boolean | null;
  securitiesMoveByTransactionPtrId?: SecuritiesMoveFilter | null;
  securitiesMoveByTransactionPtrIdExists?: boolean | null;
  securitiesOrderByTransactionPtrId?: SecuritiesOrderFilter | null;
  securitiesOrderByTransactionPtrIdExists?: boolean | null;
  securitiesOriginationByTransactionPtrId?: SecuritiesOriginationFilter | null;
  securitiesOriginationByTransactionPtrIdExists?: boolean | null;
  securitiesPockettransferByTransactionPtrId?: SecuritiesPockettransferFilter | null;
  securitiesPockettransferByTransactionPtrIdExists?: boolean | null;
  securitiesRetireByTransactionPtrId?: SecuritiesRetireFilter | null;
  securitiesRetireByTransactionPtrIdExists?: boolean | null;
  securitiesStreetsettlementByTransactionPtrId?: SecuritiesStreetsettlementFilter | null;
  securitiesStreetsettlementByTransactionPtrIdExists?: boolean | null;
  securitiesStreetticketByTransactionPtrId?: SecuritiesStreetticketFilter | null;
  securitiesStreetticketByTransactionPtrIdExists?: boolean | null;
  securitiesStreetticketacceptByTransactionPtrId?: SecuritiesStreetticketacceptFilter | null;
  securitiesStreetticketacceptByTransactionPtrIdExists?: boolean | null;
  securitiesStreetticketcancelByTransactionPtrId?: SecuritiesStreetticketcancelFilter | null;
  securitiesStreetticketcancelByTransactionPtrIdExists?: boolean | null;
  securitiesStreetticketrejectByTransactionPtrId?: SecuritiesStreetticketrejectFilter | null;
  securitiesStreetticketrejectByTransactionPtrIdExists?: boolean | null;
  securitiesStreetticketreturnByTransactionPtrId?: SecuritiesStreetticketreturnFilter | null;
  securitiesStreetticketreturnByTransactionPtrIdExists?: boolean | null;
  securitiesStreetticketshipmentByTransactionPtrId?: SecuritiesStreetticketshipmentFilter | null;
  securitiesStreetticketshipmentByTransactionPtrIdExists?: boolean | null;
  securitiesTalentgiftByTransactionPtrId?: SecuritiesTalentgiftFilter | null;
  securitiesTalentgiftByTransactionPtrIdExists?: boolean | null;
  securitiesTradeByTransactionPtrId?: SecuritiesTradeFilter | null;
  securitiesTradeByTransactionPtrIdExists?: boolean | null;
  securitiesTransferByTransactionPtrId?: SecuritiesTransferFilter | null;
  securitiesTransferByTransactionPtrIdExists?: boolean | null;
  securitiesUnloadByTransactionPtrId?: SecuritiesUnloadFilter | null;
  securitiesUnloadByTransactionPtrIdExists?: boolean | null;
  securitiesUnloadholdByTransactionPtrId?: SecuritiesUnloadholdFilter | null;
  securitiesUnloadholdByTransactionPtrIdExists?: boolean | null;
  securitiesUnloadunholdByTransactionPtrId?: SecuritiesUnloadunholdFilter | null;
  securitiesUnloadunholdByTransactionPtrIdExists?: boolean | null;
  time?: DatetimeFilter | null;
  transfersCancelByTransactionPtrId?: TransfersCancelFilter | null;
  transfersCancelByTransactionPtrIdExists?: boolean | null;
  transfersHoldByTransactionPtrId?: TransfersHoldFilter | null;
  transfersHoldByTransactionPtrIdExists?: boolean | null;
  transfersSendByTransactionPtrId?: TransfersSendFilter | null;
  transfersSendByTransactionPtrIdExists?: boolean | null;
  transfersUnholdByTransactionPtrId?: TransfersUnholdFilter | null;
  transfersUnholdByTransactionPtrIdExists?: boolean | null;
};
export type SecuritiesCancelorderFilter = {
  and?: ReadonlyArray<SecuritiesCancelorderFilter> | null;
  authUserByOriginatorId?: AuthUserFilter | null;
  authUserByOriginatorIdExists?: boolean | null;
  not?: SecuritiesCancelorderFilter | null;
  or?: ReadonlyArray<SecuritiesCancelorderFilter> | null;
  originatorId?: IntFilter | null;
  requestId?: StringFilter | null;
  securitiesOrderByTargetId?: SecuritiesOrderFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  targetId?: IntFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesOrderFilter = {
  and?: ReadonlyArray<SecuritiesOrderFilter> | null;
  authUserByOriginatorId?: AuthUserFilter | null;
  authUserByOriginatorIdExists?: boolean | null;
  clientOrderId?: StringFilter | null;
  closeId?: IntFilter | null;
  isBuy?: BooleanFilter | null;
  marketId?: IntFilter | null;
  not?: SecuritiesOrderFilter | null;
  or?: ReadonlyArray<SecuritiesOrderFilter> | null;
  orderType?: IntFilter | null;
  originatorId?: IntFilter | null;
  parentId?: IntFilter | null;
  pocketId?: IntFilter | null;
  price?: BigIntFilter | null;
  qty?: BigIntFilter | null;
  securitiesCancelordersByTargetId?: SecuritiesOrderToManySecuritiesCancelorderFilter | null;
  securitiesCancelordersByTargetIdExist?: boolean | null;
  securitiesCloseorderByCloseId?: SecuritiesCloseorderFilter | null;
  securitiesCloseorderByCloseIdExists?: boolean | null;
  securitiesMarketByMarketId?: SecuritiesMarketFilter | null;
  securitiesMarketByMarketIdExists?: boolean | null;
  securitiesOrderByParentId?: SecuritiesOrderFilter | null;
  securitiesOrderByParentIdExists?: boolean | null;
  securitiesOrdersByParentId?: SecuritiesOrderToManySecuritiesOrderFilter | null;
  securitiesOrdersByParentIdExist?: boolean | null;
  securitiesPocketByPocketId?: SecuritiesPocketFilter | null;
  securitiesTradesByAskId?: SecuritiesOrderToManySecuritiesTradeFilter | null;
  securitiesTradesByAskIdExist?: boolean | null;
  securitiesTradesByBidId?: SecuritiesOrderToManySecuritiesTradeFilter | null;
  securitiesTradesByBidIdExist?: boolean | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  status?: IntFilter | null;
  stopPrice?: BigIntFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesOrderToManySecuritiesCancelorderFilter = {
  every?: SecuritiesCancelorderFilter | null;
  none?: SecuritiesCancelorderFilter | null;
  some?: SecuritiesCancelorderFilter | null;
};
export type SecuritiesOrderToManySecuritiesOrderFilter = {
  every?: SecuritiesOrderFilter | null;
  none?: SecuritiesOrderFilter | null;
  some?: SecuritiesOrderFilter | null;
};
export type SecuritiesOrderToManySecuritiesTradeFilter = {
  every?: SecuritiesTradeFilter | null;
  none?: SecuritiesTradeFilter | null;
  some?: SecuritiesTradeFilter | null;
};
export type SecuritiesTradeFilter = {
  and?: ReadonlyArray<SecuritiesTradeFilter> | null;
  askId?: IntFilter | null;
  bidId?: IntFilter | null;
  cost?: BigIntFilter | null;
  marketId?: IntFilter | null;
  not?: SecuritiesTradeFilter | null;
  or?: ReadonlyArray<SecuritiesTradeFilter> | null;
  price?: BigIntFilter | null;
  securitiesMarketByMarketId?: SecuritiesMarketFilter | null;
  securitiesMarketByMarketIdExists?: boolean | null;
  securitiesOrderByAskId?: SecuritiesOrderFilter | null;
  securitiesOrderByBidId?: SecuritiesOrderFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  size?: BigIntFilter | null;
  takerIsBuy?: BooleanFilter | null;
  timestamp?: DatetimeFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesMarketFilter = {
  and?: ReadonlyArray<SecuritiesMarketFilter> | null;
  baseAssetId?: IntFilter | null;
  minimumCost?: BigIntFilter | null;
  minimumMove?: BigIntFilter | null;
  minimumSize?: BigIntFilter | null;
  not?: SecuritiesMarketFilter | null;
  or?: ReadonlyArray<SecuritiesMarketFilter> | null;
  quoteAssetId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByBaseAssetId?: SecuritiesAssetFilter | null;
  securitiesAssetByQuoteAssetId?: SecuritiesAssetFilter | null;
  securitiesOrdersByMarketId?: SecuritiesMarketToManySecuritiesOrderFilter | null;
  securitiesOrdersByMarketIdExist?: boolean | null;
  securitiesTradesByMarketId?: SecuritiesMarketToManySecuritiesTradeFilter | null;
  securitiesTradesByMarketIdExist?: boolean | null;
  targetState?: StringFilter | null;
};
export type SecuritiesMarketToManySecuritiesOrderFilter = {
  every?: SecuritiesOrderFilter | null;
  none?: SecuritiesOrderFilter | null;
  some?: SecuritiesOrderFilter | null;
};
export type SecuritiesMarketToManySecuritiesTradeFilter = {
  every?: SecuritiesTradeFilter | null;
  none?: SecuritiesTradeFilter | null;
  some?: SecuritiesTradeFilter | null;
};
export type SecuritiesPocketFilter = {
  and?: ReadonlyArray<SecuritiesPocketFilter> | null;
  gameGamesByPurseId?: SecuritiesPocketToManyGameGameFilter | null;
  gameGamesByPurseIdExist?: boolean | null;
  name?: StringFilter | null;
  not?: SecuritiesPocketFilter | null;
  or?: ReadonlyArray<SecuritiesPocketFilter> | null;
  paypalOrdersByPocketId?: SecuritiesPocketToManyPaypalOrderFilter | null;
  paypalOrdersByPocketIdExist?: boolean | null;
  profileId?: IntFilter | null;
  reportingAccountPocketsByPocketId?: SecuritiesPocketToManyReportingAccountPocketFilter | null;
  reportingAccountPocketsByPocketIdExist?: boolean | null;
  reportingJournalsByPocketId?: SecuritiesPocketToManyReportingJournalFilter | null;
  reportingJournalsByPocketIdExist?: boolean | null;
  rowId?: IntFilter | null;
  securitiesConversiontargetsByPocketId?: SecuritiesPocketToManySecuritiesConversiontargetFilter | null;
  securitiesConversiontargetsByPocketIdExist?: boolean | null;
  securitiesIssuesByProceedsId?: SecuritiesPocketToManySecuritiesIssueFilter | null;
  securitiesIssuesByProceedsIdExist?: boolean | null;
  securitiesOrdersByPocketId?: SecuritiesPocketToManySecuritiesOrderFilter | null;
  securitiesOrdersByPocketIdExist?: boolean | null;
  securitiesPockettransfersByReceiverId?: SecuritiesPocketToManySecuritiesPockettransferFilter | null;
  securitiesPockettransfersByReceiverIdExist?: boolean | null;
  securitiesPockettransfersBySenderId?: SecuritiesPocketToManySecuritiesPockettransferFilter | null;
  securitiesPockettransfersBySenderIdExist?: boolean | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesProfilesByPrimaryPocketId?: SecuritiesPocketToManySecuritiesProfileFilter | null;
  securitiesProfilesByPrimaryPocketIdExist?: boolean | null;
  securitiesVenuesByPocketId?: SecuritiesPocketToManySecuritiesVenueFilter | null;
  securitiesVenuesByPocketIdExist?: boolean | null;
  securitiesWalletsByPocketId?: SecuritiesPocketToManySecuritiesWalletFilter | null;
  securitiesWalletsByPocketIdExist?: boolean | null;
};
export type SecuritiesPocketToManyGameGameFilter = {
  every?: GameGameFilter | null;
  none?: GameGameFilter | null;
  some?: GameGameFilter | null;
};
export type SecuritiesPocketToManyPaypalOrderFilter = {
  every?: PaypalOrderFilter | null;
  none?: PaypalOrderFilter | null;
  some?: PaypalOrderFilter | null;
};
export type PaypalOrderFilter = {
  amount?: BigFloatFilter | null;
  and?: ReadonlyArray<PaypalOrderFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  capture?: BigFloatFilter | null;
  captureCurrencyCode?: StringFilter | null;
  currencyCode?: StringFilter | null;
  error?: StringFilter | null;
  exchangeRate?: StringFilter | null;
  fee?: BigFloatFilter | null;
  feeCurrencyCode?: StringFilter | null;
  gross?: BigFloatFilter | null;
  grossCurrencyCode?: StringFilter | null;
  minted?: BooleanFilter | null;
  net?: BigFloatFilter | null;
  netCurrencyCode?: StringFilter | null;
  not?: PaypalOrderFilter | null;
  or?: ReadonlyArray<PaypalOrderFilter> | null;
  orderId?: StringFilter | null;
  payerCountryCode?: StringFilter | null;
  payerEmail?: StringFilter | null;
  payerId?: IntFilter | null;
  paypalPayerByPayerId?: PaypalPayerFilter | null;
  pocketId?: IntFilter | null;
  profileId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesPocketByPocketId?: SecuritiesPocketFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  status?: StringFilter | null;
  userId?: IntFilter | null;
};
export type PaypalPayerFilter = {
  and?: ReadonlyArray<PaypalPayerFilter> | null;
  not?: PaypalPayerFilter | null;
  or?: ReadonlyArray<PaypalPayerFilter> | null;
  payerId?: StringFilter | null;
  paypalOrdersByPayerId?: PaypalPayerToManyPaypalOrderFilter | null;
  paypalOrdersByPayerIdExist?: boolean | null;
  paypalPaypallinksByPayerId?: PaypalPayerToManyPaypalPaypallinkFilter | null;
  paypalPaypallinksByPayerIdExist?: boolean | null;
  rowId?: IntFilter | null;
};
export type PaypalPayerToManyPaypalOrderFilter = {
  every?: PaypalOrderFilter | null;
  none?: PaypalOrderFilter | null;
  some?: PaypalOrderFilter | null;
};
export type PaypalPayerToManyPaypalPaypallinkFilter = {
  every?: PaypalPaypallinkFilter | null;
  none?: PaypalPaypallinkFilter | null;
  some?: PaypalPaypallinkFilter | null;
};
export type PaypalPaypallinkFilter = {
  and?: ReadonlyArray<PaypalPaypallinkFilter> | null;
  currencyCode?: StringFilter | null;
  linkPtrId?: IntFilter | null;
  not?: PaypalPaypallinkFilter | null;
  or?: ReadonlyArray<PaypalPaypallinkFilter> | null;
  payerId?: IntFilter | null;
  paypalPayerByPayerId?: PaypalPayerFilter | null;
  securitiesLinkByLinkPtrId?: SecuritiesLinkFilter | null;
};
export type SecuritiesLinkFilter = {
  and?: ReadonlyArray<SecuritiesLinkFilter> | null;
  assetId?: IntFilter | null;
  burn?: BooleanFilter | null;
  djangoContentTypeByPolymorphicCtypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByPolymorphicCtypeIdExists?: boolean | null;
  enabled?: BooleanFilter | null;
  mint?: BooleanFilter | null;
  nickname?: StringFilter | null;
  not?: SecuritiesLinkFilter | null;
  or?: ReadonlyArray<SecuritiesLinkFilter> | null;
  paypalPaypallinkByLinkPtrId?: PaypalPaypallinkFilter | null;
  paypalPaypallinkByLinkPtrIdExists?: boolean | null;
  polymorphicCtypeId?: IntFilter | null;
  profileId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  securitiesBankaccountlinkByLinkPtrId?: SecuritiesBankaccountlinkFilter | null;
  securitiesBankaccountlinkByLinkPtrIdExists?: boolean | null;
  securitiesCryptoburnlinkByLinkPtrId?: SecuritiesCryptoburnlinkFilter | null;
  securitiesCryptoburnlinkByLinkPtrIdExists?: boolean | null;
  securitiesCryptomintlinkByLinkPtrId?: SecuritiesCryptomintlinkFilter | null;
  securitiesCryptomintlinkByLinkPtrIdExists?: boolean | null;
  securitiesEftpushmintlinkByLinkPtrId?: SecuritiesEftpushmintlinkFilter | null;
  securitiesEftpushmintlinkByLinkPtrIdExists?: boolean | null;
  securitiesLoadsByLinkId?: SecuritiesLinkToManySecuritiesLoadFilter | null;
  securitiesLoadsByLinkIdExist?: boolean | null;
  securitiesMintburnrequestsByFundingAccountId?: SecuritiesLinkToManySecuritiesMintburnrequestFilter | null;
  securitiesMintburnrequestsByFundingAccountIdExist?: boolean | null;
  securitiesPaymentcardlinkByLinkPtrId?: SecuritiesPaymentcardlinkFilter | null;
  securitiesPaymentcardlinkByLinkPtrIdExists?: boolean | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesStreetadsByLinkId?: SecuritiesLinkToManySecuritiesStreetadFilter | null;
  securitiesStreetadsByLinkIdExist?: boolean | null;
  securitiesStreetticketsByLinkId?: SecuritiesLinkToManySecuritiesStreetticketFilter | null;
  securitiesStreetticketsByLinkIdExist?: boolean | null;
  securitiesUnloadsByLinkId?: SecuritiesLinkToManySecuritiesUnloadFilter | null;
  securitiesUnloadsByLinkIdExist?: boolean | null;
  securitiesWalletByWalletId?: SecuritiesWalletFilter | null;
  securitiesWalletByWalletIdExists?: boolean | null;
  walletId?: IntFilter | null;
};
export type SecuritiesLinkToManySecuritiesMintburnrequestFilter = {
  every?: SecuritiesMintburnrequestFilter | null;
  none?: SecuritiesMintburnrequestFilter | null;
  some?: SecuritiesMintburnrequestFilter | null;
};
export type SecuritiesMintburnrequestFilter = {
  amount?: BigIntFilter | null;
  and?: ReadonlyArray<SecuritiesMintburnrequestFilter> | null;
  dateOfRequest?: DatetimeFilter | null;
  detected?: BooleanFilter | null;
  fundingAccountId?: IntFilter | null;
  institution?: StringFilter | null;
  networkFee?: BigIntFilter | null;
  not?: SecuritiesMintburnrequestFilter | null;
  or?: ReadonlyArray<SecuritiesMintburnrequestFilter> | null;
  reference?: StringFilter | null;
  rowId?: IntFilter | null;
  securitiesLinkByFundingAccountId?: SecuritiesLinkFilter | null;
  securitiesUnloadholdsByRequestId?: SecuritiesMintburnrequestToManySecuritiesUnloadholdFilter | null;
  securitiesUnloadholdsByRequestIdExist?: boolean | null;
  securitiesUnloadunholdsByRequestId?: SecuritiesMintburnrequestToManySecuritiesUnloadunholdFilter | null;
  securitiesUnloadunholdsByRequestIdExist?: boolean | null;
  status?: StringFilter | null;
};
export type SecuritiesMintburnrequestToManySecuritiesUnloadholdFilter = {
  every?: SecuritiesUnloadholdFilter | null;
  none?: SecuritiesUnloadholdFilter | null;
  some?: SecuritiesUnloadholdFilter | null;
};
export type SecuritiesUnloadholdFilter = {
  and?: ReadonlyArray<SecuritiesUnloadholdFilter> | null;
  not?: SecuritiesUnloadholdFilter | null;
  or?: ReadonlyArray<SecuritiesUnloadholdFilter> | null;
  requestId?: IntFilter | null;
  securitiesMintburnrequestByRequestId?: SecuritiesMintburnrequestFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesMintburnrequestToManySecuritiesUnloadunholdFilter = {
  every?: SecuritiesUnloadunholdFilter | null;
  none?: SecuritiesUnloadunholdFilter | null;
  some?: SecuritiesUnloadunholdFilter | null;
};
export type SecuritiesUnloadunholdFilter = {
  and?: ReadonlyArray<SecuritiesUnloadunholdFilter> | null;
  not?: SecuritiesUnloadunholdFilter | null;
  or?: ReadonlyArray<SecuritiesUnloadunholdFilter> | null;
  requestId?: IntFilter | null;
  securitiesMintburnrequestByRequestId?: SecuritiesMintburnrequestFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesBankaccountlinkFilter = {
  accountNumber?: StringFilter | null;
  accountNumberType?: StringFilter | null;
  activeDate?: DateFilter | null;
  agency?: StringFilter | null;
  and?: ReadonlyArray<SecuritiesBankaccountlinkFilter> | null;
  bankAddress?: StringFilter | null;
  bankName?: StringFilter | null;
  bankRoutingNumber?: StringFilter | null;
  beneficiaryName?: StringFilter | null;
  deposit1?: FloatFilter | null;
  deposit2?: FloatFilter | null;
  email?: StringFilter | null;
  isConfirmed?: BooleanFilter | null;
  linkPtrId?: IntFilter | null;
  not?: SecuritiesBankaccountlinkFilter | null;
  or?: ReadonlyArray<SecuritiesBankaccountlinkFilter> | null;
  securitiesLinkByLinkPtrId?: SecuritiesLinkFilter | null;
  taxId?: StringFilter | null;
  typeAccount?: StringFilter | null;
  verificationAttempts?: IntFilter | null;
};
export type SecuritiesCryptoburnlinkFilter = {
  address?: StringFilter | null;
  and?: ReadonlyArray<SecuritiesCryptoburnlinkFilter> | null;
  linkPtrId?: IntFilter | null;
  not?: SecuritiesCryptoburnlinkFilter | null;
  or?: ReadonlyArray<SecuritiesCryptoburnlinkFilter> | null;
  securitiesLinkByLinkPtrId?: SecuritiesLinkFilter | null;
};
export type SecuritiesCryptomintlinkFilter = {
  addressId?: IntFilter | null;
  and?: ReadonlyArray<SecuritiesCryptomintlinkFilter> | null;
  bitcoinAddressByAddressId?: BitcoinAddressFilter | null;
  linkPtrId?: IntFilter | null;
  not?: SecuritiesCryptomintlinkFilter | null;
  or?: ReadonlyArray<SecuritiesCryptomintlinkFilter> | null;
  securitiesLinkByLinkPtrId?: SecuritiesLinkFilter | null;
};
export type BitcoinAddressFilter = {
  and?: ReadonlyArray<BitcoinAddressFilter> | null;
  assetId?: IntFilter | null;
  bitcoinTransactionsByAddressId?: BitcoinAddressToManyBitcoinTransactionFilter | null;
  bitcoinTransactionsByAddressIdExist?: boolean | null;
  not?: BitcoinAddressFilter | null;
  or?: ReadonlyArray<BitcoinAddressFilter> | null;
  profileId?: IntFilter | null;
  pubHash?: StringFilter | null;
  rowId?: IntFilter | null;
  securitiesCryptoassetByAssetId?: SecuritiesCryptoassetFilter | null;
  securitiesCryptomintlinkByAddressId?: SecuritiesCryptomintlinkFilter | null;
  securitiesCryptomintlinkByAddressIdExists?: boolean | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesProfileByProfileIdExists?: boolean | null;
};
export type BitcoinAddressToManyBitcoinTransactionFilter = {
  every?: BitcoinTransactionFilter | null;
  none?: BitcoinTransactionFilter | null;
  some?: BitcoinTransactionFilter | null;
};
export type BitcoinTransactionFilter = {
  addressId?: IntFilter | null;
  amount?: IntFilter | null;
  and?: ReadonlyArray<BitcoinTransactionFilter> | null;
  bitcoinAddressByAddressId?: BitcoinAddressFilter | null;
  confirmations?: IntFilter | null;
  not?: BitcoinTransactionFilter | null;
  or?: ReadonlyArray<BitcoinTransactionFilter> | null;
  rowId?: IntFilter | null;
  securitiesCryptoloadByRefId?: SecuritiesCryptoloadFilter | null;
  securitiesCryptoloadByRefIdExists?: boolean | null;
  time?: IntFilter | null;
  timeReceived?: IntFilter | null;
  txid?: StringFilter | null;
};
export type SecuritiesCryptoloadFilter = {
  and?: ReadonlyArray<SecuritiesCryptoloadFilter> | null;
  bitcoinTransactionByRefId?: BitcoinTransactionFilter | null;
  loadPtrId?: IntFilter | null;
  not?: SecuritiesCryptoloadFilter | null;
  or?: ReadonlyArray<SecuritiesCryptoloadFilter> | null;
  refId?: IntFilter | null;
  securitiesLoadByLoadPtrId?: SecuritiesLoadFilter | null;
};
export type SecuritiesLoadFilter = {
  and?: ReadonlyArray<SecuritiesLoadFilter> | null;
  description?: StringFilter | null;
  fee?: BigFloatFilter | null;
  gross?: BigFloatFilter | null;
  linkId?: IntFilter | null;
  net?: BigFloatFilter | null;
  not?: SecuritiesLoadFilter | null;
  or?: ReadonlyArray<SecuritiesLoadFilter> | null;
  quantity?: BigIntFilter | null;
  reserveDate?: DateFilter | null;
  reserveRef?: StringFilter | null;
  routeId?: IntFilter | null;
  securitiesCryptoloadByLoadPtrId?: SecuritiesCryptoloadFilter | null;
  securitiesCryptoloadByLoadPtrIdExists?: boolean | null;
  securitiesLinkByLinkId?: SecuritiesLinkFilter | null;
  securitiesProfileByRouteId?: SecuritiesProfileFilter | null;
  securitiesProfileByRouteIdExists?: boolean | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  status?: StringFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesProfileFilter = {
  address?: StringFilter | null;
  address2?: StringFilter | null;
  advisorAddress?: StringFilter | null;
  advisorJob?: StringFilter | null;
  advisorName?: StringFilter | null;
  allowFindByUserEmail?: BooleanFilter | null;
  and?: ReadonlyArray<SecuritiesProfileFilter> | null;
  annualIncome?: BigFloatFilter | null;
  apiProfileapikeysByProfileId?: SecuritiesProfileToManyApiProfileapikeyFilter | null;
  apiProfileapikeysByProfileIdExist?: boolean | null;
  authUserByReferralUserId?: AuthUserFilter | null;
  authUserByReferralUserIdExists?: boolean | null;
  authUserByUserId?: AuthUserFilter | null;
  bitcoinAddressesByProfileId?: SecuritiesProfileToManyBitcoinAddressFilter | null;
  bitcoinAddressesByProfileIdExist?: boolean | null;
  citiesLightCountryByCountryId?: CitiesLightCountryFilter | null;
  citiesLightCountryByCountryIdExists?: boolean | null;
  citiesLightRegionByPrevState?: CitiesLightRegionFilter | null;
  citiesLightRegionByPrevStateExists?: boolean | null;
  citiesLightRegionByState?: CitiesLightRegionFilter | null;
  citiesLightRegionByStateExists?: boolean | null;
  citiesLightRegionByStateOfFormation?: CitiesLightRegionFilter | null;
  citiesLightRegionByStateOfFormationExists?: boolean | null;
  citiesLightRegionByTaxFilingState?: CitiesLightRegionFilter | null;
  citiesLightRegionByTaxFilingStateExists?: boolean | null;
  citiesLightRegionByVoterState?: CitiesLightRegionFilter | null;
  citiesLightRegionByVoterStateExists?: boolean | null;
  city?: StringFilter | null;
  civilStatus?: StringFilter | null;
  committedCapital?: StringFilter | null;
  countryId?: IntFilter | null;
  credit?: IntFilter | null;
  creditApprovedDate?: DateFilter | null;
  creditDate?: DateFilter | null;
  currencyCode?: StringFilter | null;
  currentEmployment?: StringFilter | null;
  currentJobTitle?: StringFilter | null;
  discretion?: StringFilter | null;
  displayName?: StringFilter | null;
  dl?: StringFilter | null;
  dob?: DateFilter | null;
  ein?: StringFilter | null;
  employer?: StringFilter | null;
  employerYears?: IntFilter | null;
  entityAuth?: StringFilter | null;
  entityTitle?: StringFilter | null;
  entityType?: StringFilter | null;
  felon?: StringFilter | null;
  filingNo?: StringFilter | null;
  formationDate?: StringFilter | null;
  identityVerified?: StringFilter | null;
  identityVerifiedAt?: DatetimeFilter | null;
  investmentCo?: StringFilter | null;
  investor1?: StringFilter | null;
  investor2A?: StringFilter | null;
  investor2B?: StringFilter | null;
  investor3?: StringFilter | null;
  investor4?: StringFilter | null;
  investor5?: StringFilter | null;
  investor6?: StringFilter | null;
  investor7?: StringFilter | null;
  investor8?: StringFilter | null;
  isVisibleToAnon?: BooleanFilter | null;
  isVisibleToPublic?: BooleanFilter | null;
  landscape?: StringFilter | null;
  legalName?: StringFilter | null;
  membershipType?: StringFilter | null;
  name?: StringFilter | null;
  netWorth?: BigFloatFilter | null;
  not?: SecuritiesProfileFilter | null;
  or?: ReadonlyArray<SecuritiesProfileFilter> | null;
  organizationName?: StringFilter | null;
  organizationType?: StringFilter | null;
  paypalOrdersByProfileId?: SecuritiesProfileToManyPaypalOrderFilter | null;
  paypalOrdersByProfileIdExist?: boolean | null;
  phone?: StringFilter | null;
  photo?: StringFilter | null;
  prevAddress?: StringFilter | null;
  prevCity?: StringFilter | null;
  prevEmployer?: StringFilter | null;
  prevEmployerYears?: IntFilter | null;
  prevEmployment?: StringFilter | null;
  prevJobTitle?: StringFilter | null;
  prevResYears?: StringFilter | null;
  prevState?: IntFilter | null;
  prevZipcode?: StringFilter | null;
  primaryPocketId?: IntFilter | null;
  profileCompleteDate?: DateFilter | null;
  profileType?: StringFilter | null;
  recapitalized?: StringFilter | null;
  referralUserId?: IntFilter | null;
  referrerId?: IntFilter | null;
  reportingCompanyProfilesByProfileId?: SecuritiesProfileToManyReportingCompanyProfileFilter | null;
  reportingCompanyProfilesByProfileIdExist?: boolean | null;
  resYears?: StringFilter | null;
  rowId?: IntFilter | null;
  securitiesAgentbankaccountsByAgentId?: SecuritiesProfileToManySecuritiesAgentbankaccountFilter | null;
  securitiesAgentbankaccountsByAgentIdExist?: boolean | null;
  securitiesAgreementsByProfileId?: SecuritiesProfileToManySecuritiesAgreementFilter | null;
  securitiesAgreementsByProfileIdExist?: boolean | null;
  securitiesAssetsByIssuerId?: SecuritiesProfileToManySecuritiesAssetFilter | null;
  securitiesAssetsByIssuerIdExist?: boolean | null;
  securitiesBoostsBySenderId?: SecuritiesProfileToManySecuritiesBoostFilter | null;
  securitiesBoostsBySenderIdExist?: boolean | null;
  securitiesCompletedquestionnairesByProfileId?: SecuritiesProfileToManySecuritiesCompletedquestionnaireFilter | null;
  securitiesCompletedquestionnairesByProfileIdExist?: boolean | null;
  securitiesDiscretionarycontentsByCreatedById?: SecuritiesProfileToManySecuritiesDiscretionarycontentFilter | null;
  securitiesDiscretionarycontentsByCreatedByIdExist?: boolean | null;
  securitiesDiscretionarygroupsByProfileId?: SecuritiesProfileToManySecuritiesDiscretionarygroupFilter | null;
  securitiesDiscretionarygroupsByProfileIdExist?: boolean | null;
  securitiesErrorresolutionsByApprovedById?: SecuritiesProfileToManySecuritiesErrorresolutionFilter | null;
  securitiesErrorresolutionsByApprovedByIdExist?: boolean | null;
  securitiesErrorresolutionsBySubmittedById?: SecuritiesProfileToManySecuritiesErrorresolutionFilter | null;
  securitiesErrorresolutionsBySubmittedByIdExist?: boolean | null;
  securitiesErrorsByReportedById?: SecuritiesProfileToManySecuritiesErrorFilter | null;
  securitiesErrorsByReportedByIdExist?: boolean | null;
  securitiesEvidencesByProfileId?: SecuritiesProfileToManySecuritiesEvidenceFilter | null;
  securitiesEvidencesByProfileIdExist?: boolean | null;
  securitiesFriendsByFriendId?: SecuritiesProfileToManySecuritiesFriendFilter | null;
  securitiesFriendsByFriendIdExist?: boolean | null;
  securitiesFriendsByProfileId?: SecuritiesProfileToManySecuritiesFriendFilter | null;
  securitiesFriendsByProfileIdExist?: boolean | null;
  securitiesInvoicesByApprovedById?: SecuritiesProfileToManySecuritiesInvoiceFilter | null;
  securitiesInvoicesByApprovedByIdExist?: boolean | null;
  securitiesInvoicesByPayeeId?: SecuritiesProfileToManySecuritiesInvoiceFilter | null;
  securitiesInvoicesByPayeeIdExist?: boolean | null;
  securitiesInvoicesByPayerProfileId?: SecuritiesProfileToManySecuritiesInvoiceFilter | null;
  securitiesInvoicesByPayerProfileIdExist?: boolean | null;
  securitiesInvoicesByRouteId?: SecuritiesProfileToManySecuritiesInvoiceFilter | null;
  securitiesInvoicesByRouteIdExist?: boolean | null;
  securitiesIssuersByProfileId?: SecuritiesProfileToManySecuritiesIssuerFilter | null;
  securitiesIssuersByProfileIdExist?: boolean | null;
  securitiesIssuesByById?: SecuritiesProfileToManySecuritiesIssueFilter | null;
  securitiesIssuesByByIdExist?: boolean | null;
  securitiesIssuesubscriptionsByProfileId?: SecuritiesProfileToManySecuritiesIssuesubscriptionFilter | null;
  securitiesIssuesubscriptionsByProfileIdExist?: boolean | null;
  securitiesLinksByProfileId?: SecuritiesProfileToManySecuritiesLinkFilter | null;
  securitiesLinksByProfileIdExist?: boolean | null;
  securitiesLoadsByRouteId?: SecuritiesProfileToManySecuritiesLoadFilter | null;
  securitiesLoadsByRouteIdExist?: boolean | null;
  securitiesMembershipsByGroupId?: SecuritiesProfileToManySecuritiesMembershipFilter | null;
  securitiesMembershipsByGroupIdExist?: boolean | null;
  securitiesMembershipsByMemberId?: SecuritiesProfileToManySecuritiesMembershipFilter | null;
  securitiesMembershipsByMemberIdExist?: boolean | null;
  securitiesPayeesplitsByPayeeId?: SecuritiesProfileToManySecuritiesPayeesplitFilter | null;
  securitiesPayeesplitsByPayeeIdExist?: boolean | null;
  securitiesPendingsByProfileId?: SecuritiesProfileToManySecuritiesPendingFilter | null;
  securitiesPendingsByProfileIdExist?: boolean | null;
  securitiesPocketByPrimaryPocketId?: SecuritiesPocketFilter | null;
  securitiesPocketByPrimaryPocketIdExists?: boolean | null;
  securitiesPocketsByProfileId?: SecuritiesProfileToManySecuritiesPocketFilter | null;
  securitiesPocketsByProfileIdExist?: boolean | null;
  securitiesProfileByReferrerId?: SecuritiesProfileFilter | null;
  securitiesProfileByReferrerIdExists?: boolean | null;
  securitiesProfileTagsByProfileId?: SecuritiesProfileToManySecuritiesProfileTagFilter | null;
  securitiesProfileTagsByProfileIdExist?: boolean | null;
  securitiesProfilesByReferrerId?: SecuritiesProfileToManySecuritiesProfileFilter | null;
  securitiesProfilesByReferrerIdExist?: boolean | null;
  securitiesPropertyassetsByGrantorId?: SecuritiesProfileToManySecuritiesPropertyassetFilter | null;
  securitiesPropertyassetsByGrantorIdExist?: boolean | null;
  securitiesPropertyassetsByPropertyManagerId?: SecuritiesProfileToManySecuritiesPropertyassetFilter | null;
  securitiesPropertyassetsByPropertyManagerIdExist?: boolean | null;
  securitiesProposalsByTalentId?: SecuritiesProfileToManySecuritiesProposalFilter | null;
  securitiesProposalsByTalentIdExist?: boolean | null;
  securitiesRegionalmanagersByProfileId?: SecuritiesProfileToManySecuritiesRegionalmanagerFilter | null;
  securitiesRegionalmanagersByProfileIdExist?: boolean | null;
  securitiesRequestforproposalsByClientId?: SecuritiesProfileToManySecuritiesRequestforproposalFilter | null;
  securitiesRequestforproposalsByClientIdExist?: boolean | null;
  securitiesStreetadsByProfileId?: SecuritiesProfileToManySecuritiesStreetadFilter | null;
  securitiesStreetadsByProfileIdExist?: boolean | null;
  securitiesStreetticketsByProfileId?: SecuritiesProfileToManySecuritiesStreetticketFilter | null;
  securitiesStreetticketsByProfileIdExist?: boolean | null;
  securitiesTalentgiftByProfileId?: SecuritiesTalentgiftFilter | null;
  securitiesTalentgiftByProfileIdExists?: boolean | null;
  securitiesTransfersByReceiverId?: SecuritiesProfileToManySecuritiesTransferFilter | null;
  securitiesTransfersByReceiverIdExist?: boolean | null;
  securitiesTransfersBySenderId?: SecuritiesProfileToManySecuritiesTransferFilter | null;
  securitiesTransfersBySenderIdExist?: boolean | null;
  securitiesUnloadsByRouteId?: SecuritiesProfileToManySecuritiesUnloadFilter | null;
  securitiesUnloadsByRouteIdExist?: boolean | null;
  securitiesUserpreferenceBySignInProfileId?: SecuritiesUserpreferenceFilter | null;
  securitiesUserpreferenceBySignInProfileIdExists?: boolean | null;
  securitiesVisitsByVisitedId?: SecuritiesProfileToManySecuritiesVisitFilter | null;
  securitiesVisitsByVisitedIdExist?: boolean | null;
  securitiesVisitsByVisitorId?: SecuritiesProfileToManySecuritiesVisitFilter | null;
  securitiesVisitsByVisitorIdExist?: boolean | null;
  ssn?: StringFilter | null;
  state?: IntFilter | null;
  stateOfFormation?: IntFilter | null;
  stateTax?: StringFilter | null;
  taxFilingState?: IntFilter | null;
  transfersCancelsByProfileId?: SecuritiesProfileToManyTransfersCancelFilter | null;
  transfersCancelsByProfileIdExist?: boolean | null;
  transfersHoldsByReceiverId?: SecuritiesProfileToManyTransfersHoldFilter | null;
  transfersHoldsByReceiverIdExist?: boolean | null;
  transfersHoldsBySenderId?: SecuritiesProfileToManyTransfersHoldFilter | null;
  transfersHoldsBySenderIdExist?: boolean | null;
  transfersSendsByReceiverId?: SecuritiesProfileToManyTransfersSendFilter | null;
  transfersSendsByReceiverIdExist?: boolean | null;
  transfersSendsBySenderId?: SecuritiesProfileToManyTransfersSendFilter | null;
  transfersSendsBySenderIdExist?: boolean | null;
  transfersUnholdsByProfileId?: SecuritiesProfileToManyTransfersUnholdFilter | null;
  transfersUnholdsByProfileIdExist?: boolean | null;
  userId?: IntFilter | null;
  voterCountry?: StringFilter | null;
  voterReg?: StringFilter | null;
  voterState?: IntFilter | null;
  wealthVerified?: StringFilter | null;
  wealthVerifiedAt?: DatetimeFilter | null;
  zipcode?: StringFilter | null;
};
export type SecuritiesProfileToManySecuritiesInvoiceFilter = {
  every?: SecuritiesInvoiceFilter | null;
  none?: SecuritiesInvoiceFilter | null;
  some?: SecuritiesInvoiceFilter | null;
};
export type SecuritiesInvoiceFilter = {
  amount?: BigFloatFilter | null;
  and?: ReadonlyArray<SecuritiesInvoiceFilter> | null;
  approvedAt?: DatetimeFilter | null;
  approvedById?: IntFilter | null;
  assetId?: IntFilter | null;
  citiesLightCountryByPayerCountryId?: CitiesLightCountryFilter | null;
  citiesLightCountryByPayerCountryIdExists?: boolean | null;
  createdAt?: DatetimeFilter | null;
  description?: StringFilter | null;
  not?: SecuritiesInvoiceFilter | null;
  or?: ReadonlyArray<SecuritiesInvoiceFilter> | null;
  payeeId?: IntFilter | null;
  payer?: StringFilter | null;
  payerCountryId?: IntFilter | null;
  payerName?: StringFilter | null;
  payerPhone?: StringFilter | null;
  payerProfileId?: IntFilter | null;
  proposalId?: IntFilter | null;
  routeId?: IntFilter | null;
  routeRef?: StringFilter | null;
  rowId?: IntFilter | null;
  securitiesPaymentsByInvoiceId?: SecuritiesInvoiceToManySecuritiesPaymentFilter | null;
  securitiesPaymentsByInvoiceIdExist?: boolean | null;
  securitiesProfileByApprovedById?: SecuritiesProfileFilter | null;
  securitiesProfileByApprovedByIdExists?: boolean | null;
  securitiesProfileByPayeeId?: SecuritiesProfileFilter | null;
  securitiesProfileByPayeeIdExists?: boolean | null;
  securitiesProfileByPayerProfileId?: SecuritiesProfileFilter | null;
  securitiesProfileByPayerProfileIdExists?: boolean | null;
  securitiesProfileByRouteId?: SecuritiesProfileFilter | null;
  securitiesProfileByRouteIdExists?: boolean | null;
  securitiesProposalByProposalId?: SecuritiesProposalFilter | null;
  securitiesProposalByProposalIdExists?: boolean | null;
  securitiesProxyassetByAssetId?: SecuritiesProxyassetFilter | null;
  status?: StringFilter | null;
};
export type SecuritiesInvoiceToManySecuritiesPaymentFilter = {
  every?: SecuritiesPaymentFilter | null;
  none?: SecuritiesPaymentFilter | null;
  some?: SecuritiesPaymentFilter | null;
};
export type SecuritiesPaymentFilter = {
  amount?: BigFloatFilter | null;
  and?: ReadonlyArray<SecuritiesPaymentFilter> | null;
  createdAt?: DatetimeFilter | null;
  fee?: BigFloatFilter | null;
  gross?: BigFloatFilter | null;
  invoiceId?: IntFilter | null;
  method?: StringFilter | null;
  not?: SecuritiesPaymentFilter | null;
  or?: ReadonlyArray<SecuritiesPaymentFilter> | null;
  refnum?: StringFilter | null;
  rowId?: IntFilter | null;
  securitiesInvoiceByInvoiceId?: SecuritiesInvoiceFilter | null;
  status?: StringFilter | null;
  statusNote?: StringFilter | null;
};
export type SecuritiesProxyassetFilter = {
  and?: ReadonlyArray<SecuritiesProxyassetFilter> | null;
  minimumBurn?: BigFloatFilter | null;
  minimumMint?: BigFloatFilter | null;
  networkassetPtrId?: IntFilter | null;
  not?: SecuritiesProxyassetFilter | null;
  or?: ReadonlyArray<SecuritiesProxyassetFilter> | null;
  securitiesCryptoproxyassetByProxyassetPtrId?: SecuritiesCryptoproxyassetFilter | null;
  securitiesCryptoproxyassetByProxyassetPtrIdExists?: boolean | null;
  securitiesFiatproxyassetByProxyassetPtrId?: SecuritiesFiatproxyassetFilter | null;
  securitiesFiatproxyassetByProxyassetPtrIdExists?: boolean | null;
  securitiesInvoicesByAssetId?: SecuritiesProxyassetToManySecuritiesInvoiceFilter | null;
  securitiesInvoicesByAssetIdExist?: boolean | null;
  securitiesIssueofferexchangeratesByBaseAssetId?: SecuritiesProxyassetToManySecuritiesIssueofferexchangerateFilter | null;
  securitiesIssueofferexchangeratesByBaseAssetIdExist?: boolean | null;
  securitiesIssueofferexchangeratesByQuoteAssetId?: SecuritiesProxyassetToManySecuritiesIssueofferexchangerateFilter | null;
  securitiesIssueofferexchangeratesByQuoteAssetIdExist?: boolean | null;
  securitiesIssueoffersByBaseAssetId?: SecuritiesProxyassetToManySecuritiesIssueofferFilter | null;
  securitiesIssueoffersByBaseAssetIdExist?: boolean | null;
  securitiesIssueoffersByQuoteAssetId?: SecuritiesProxyassetToManySecuritiesIssueofferFilter | null;
  securitiesIssueoffersByQuoteAssetIdExist?: boolean | null;
  securitiesNetworkassetByNetworkassetPtrId?: SecuritiesNetworkassetFilter | null;
};
export type SecuritiesProxyassetToManySecuritiesInvoiceFilter = {
  every?: SecuritiesInvoiceFilter | null;
  none?: SecuritiesInvoiceFilter | null;
  some?: SecuritiesInvoiceFilter | null;
};
export type SecuritiesCryptoproxyassetFilter = {
  and?: ReadonlyArray<SecuritiesCryptoproxyassetFilter> | null;
  not?: SecuritiesCryptoproxyassetFilter | null;
  or?: ReadonlyArray<SecuritiesCryptoproxyassetFilter> | null;
  proxyassetPtrId?: IntFilter | null;
  securitiesProxyassetByProxyassetPtrId?: SecuritiesProxyassetFilter | null;
};
export type SecuritiesFiatproxyassetFilter = {
  and?: ReadonlyArray<SecuritiesFiatproxyassetFilter> | null;
  not?: SecuritiesFiatproxyassetFilter | null;
  or?: ReadonlyArray<SecuritiesFiatproxyassetFilter> | null;
  proxyassetPtrId?: IntFilter | null;
  securitiesAgentbankaccountsByAssetId?: SecuritiesFiatproxyassetToManySecuritiesAgentbankaccountFilter | null;
  securitiesAgentbankaccountsByAssetIdExist?: boolean | null;
  securitiesProxyassetByProxyassetPtrId?: SecuritiesProxyassetFilter | null;
};
export type SecuritiesFiatproxyassetToManySecuritiesAgentbankaccountFilter = {
  every?: SecuritiesAgentbankaccountFilter | null;
  none?: SecuritiesAgentbankaccountFilter | null;
  some?: SecuritiesAgentbankaccountFilter | null;
};
export type SecuritiesAgentbankaccountFilter = {
  accountNumber?: StringFilter | null;
  active?: BooleanFilter | null;
  agency?: StringFilter | null;
  agentId?: IntFilter | null;
  and?: ReadonlyArray<SecuritiesAgentbankaccountFilter> | null;
  assetId?: IntFilter | null;
  bankName?: StringFilter | null;
  cbu?: StringFilter | null;
  clabe?: StringFilter | null;
  email?: StringFilter | null;
  entity?: StringFilter | null;
  iban?: StringFilter | null;
  not?: SecuritiesAgentbankaccountFilter | null;
  or?: ReadonlyArray<SecuritiesAgentbankaccountFilter> | null;
  recipientAddress?: StringFilter | null;
  recipientAddressL2?: StringFilter | null;
  routingNumber?: StringFilter | null;
  rowId?: IntFilter | null;
  securitiesFiatproxyassetByAssetId?: SecuritiesFiatproxyassetFilter | null;
  securitiesProfileByAgentId?: SecuritiesProfileFilter | null;
  securitiesProfileByAgentIdExists?: boolean | null;
  swift?: StringFilter | null;
  taxId?: StringFilter | null;
  toa?: StringFilter | null;
};
export type SecuritiesProxyassetToManySecuritiesIssueofferFilter = {
  every?: SecuritiesIssueofferFilter | null;
  none?: SecuritiesIssueofferFilter | null;
  some?: SecuritiesIssueofferFilter | null;
};
export type SecuritiesIssueofferFilter = {
  and?: ReadonlyArray<SecuritiesIssueofferFilter> | null;
  baseAssetId?: IntFilter | null;
  basePrice?: BigIntFilter | null;
  bonusId?: IntFilter | null;
  bonusPercent?: IntFilter | null;
  closeDate?: DateFilter | null;
  feeRate?: BigFloatFilter | null;
  issueId?: IntFilter | null;
  maxUnits?: IntFilter | null;
  minUnits?: IntFilter | null;
  not?: SecuritiesIssueofferFilter | null;
  offerDate?: DateFilter | null;
  offerType?: IntFilter | null;
  or?: ReadonlyArray<SecuritiesIssueofferFilter> | null;
  quoteAssetId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesIssueByBonusId?: SecuritiesIssueFilter | null;
  securitiesIssueByBonusIdExists?: boolean | null;
  securitiesIssueByIssueId?: SecuritiesIssueFilter | null;
  securitiesIssuesubscriptionsByOfferId?: SecuritiesIssueofferToManySecuritiesIssuesubscriptionFilter | null;
  securitiesIssuesubscriptionsByOfferIdExist?: boolean | null;
  securitiesProxyassetByBaseAssetId?: SecuritiesProxyassetFilter | null;
  securitiesProxyassetByQuoteAssetId?: SecuritiesProxyassetFilter | null;
  status?: StringFilter | null;
  units?: IntFilter | null;
};
export type SecuritiesIssueofferToManySecuritiesIssuesubscriptionFilter = {
  every?: SecuritiesIssuesubscriptionFilter | null;
  none?: SecuritiesIssuesubscriptionFilter | null;
  some?: SecuritiesIssuesubscriptionFilter | null;
};
export type SecuritiesIssuesubscriptionFilter = {
  agreementConfirmedAt?: DatetimeFilter | null;
  and?: ReadonlyArray<SecuritiesIssuesubscriptionFilter> | null;
  bonusQuantity?: IntFilter | null;
  cost?: BigIntFilter | null;
  fee?: BigIntFilter | null;
  feeRate?: BigFloatFilter | null;
  memorandumConfirmedAt?: DatetimeFilter | null;
  not?: SecuritiesIssuesubscriptionFilter | null;
  offerId?: IntFilter | null;
  or?: ReadonlyArray<SecuritiesIssuesubscriptionFilter> | null;
  price?: BigIntFilter | null;
  profileId?: IntFilter | null;
  quantity?: IntFilter | null;
  questionnaireConfirmedAt?: DatetimeFilter | null;
  securitiesIssueofferByOfferId?: SecuritiesIssueofferFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  status?: StringFilter | null;
  subtotal?: BigIntFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesProxyassetToManySecuritiesIssueofferexchangerateFilter = {
  every?: SecuritiesIssueofferexchangerateFilter | null;
  none?: SecuritiesIssueofferexchangerateFilter | null;
  some?: SecuritiesIssueofferexchangerateFilter | null;
};
export type SecuritiesIssueofferexchangerateFilter = {
  and?: ReadonlyArray<SecuritiesIssueofferexchangerateFilter> | null;
  baseAssetId?: IntFilter | null;
  date?: DateFilter | null;
  not?: SecuritiesIssueofferexchangerateFilter | null;
  or?: ReadonlyArray<SecuritiesIssueofferexchangerateFilter> | null;
  quoteAssetId?: IntFilter | null;
  rate?: BigFloatFilter | null;
  rowId?: IntFilter | null;
  securitiesProxyassetByBaseAssetId?: SecuritiesProxyassetFilter | null;
  securitiesProxyassetByQuoteAssetId?: SecuritiesProxyassetFilter | null;
};
export type SecuritiesNetworkassetFilter = {
  and?: ReadonlyArray<SecuritiesNetworkassetFilter> | null;
  assetPtrId?: IntFilter | null;
  not?: SecuritiesNetworkassetFilter | null;
  or?: ReadonlyArray<SecuritiesNetworkassetFilter> | null;
  securitiesAssetByAssetPtrId?: SecuritiesAssetFilter | null;
  securitiesDiscretionarygroupsByQuoteAssetId?: SecuritiesNetworkassetToManySecuritiesDiscretionarygroupFilter | null;
  securitiesDiscretionarygroupsByQuoteAssetIdExist?: boolean | null;
  securitiesPockettransfersByAssetId?: SecuritiesNetworkassetToManySecuritiesPockettransferFilter | null;
  securitiesPockettransfersByAssetIdExist?: boolean | null;
  securitiesProxyassetByNetworkassetPtrId?: SecuritiesProxyassetFilter | null;
  securitiesProxyassetByNetworkassetPtrIdExists?: boolean | null;
  securitiesSecurityassetByNetworkassetPtrId?: SecuritiesSecurityassetFilter | null;
  securitiesSecurityassetByNetworkassetPtrIdExists?: boolean | null;
  securitiesTransfersByAssetId?: SecuritiesNetworkassetToManySecuritiesTransferFilter | null;
  securitiesTransfersByAssetIdExist?: boolean | null;
  transfersSendsByAssetId?: SecuritiesNetworkassetToManyTransfersSendFilter | null;
  transfersSendsByAssetIdExist?: boolean | null;
};
export type SecuritiesNetworkassetToManySecuritiesDiscretionarygroupFilter = {
  every?: SecuritiesDiscretionarygroupFilter | null;
  none?: SecuritiesDiscretionarygroupFilter | null;
  some?: SecuritiesDiscretionarygroupFilter | null;
};
export type SecuritiesDiscretionarygroupFilter = {
  and?: ReadonlyArray<SecuritiesDiscretionarygroupFilter> | null;
  assetId?: IntFilter | null;
  description?: StringFilter | null;
  duesAnnual?: BigFloatFilter | null;
  duesDaily?: BigFloatFilter | null;
  duesHourly?: BigFloatFilter | null;
  duesLifetime?: BigFloatFilter | null;
  duesMinute?: BigFloatFilter | null;
  duesMonthly?: BigFloatFilter | null;
  duesWeekly?: BigFloatFilter | null;
  identity?: StringFilter | null;
  image?: StringFilter | null;
  isActive?: BooleanFilter | null;
  isAgentManager?: BooleanFilter | null;
  isFlexibleFee?: BooleanFilter | null;
  isTippable?: BooleanFilter | null;
  membership?: StringFilter | null;
  membershipTermsId?: IntFilter | null;
  name?: StringFilter | null;
  not?: SecuritiesDiscretionarygroupFilter | null;
  or?: ReadonlyArray<SecuritiesDiscretionarygroupFilter> | null;
  perkType?: StringFilter | null;
  profileId?: IntFilter | null;
  quoteAssetId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  securitiesAssetByAssetIdExists?: boolean | null;
  securitiesDiscretionarycontentAudiencesByDiscretionarygroupId?: SecuritiesDiscretionarygroupToManySecuritiesDiscretionarycontentAudienceFilter | null;
  securitiesDiscretionarycontentAudiencesByDiscretionarygroupIdExist?: boolean | null;
  securitiesLegaldocByMembershipTermsId?: SecuritiesLegaldocFilter | null;
  securitiesLegaldocByMembershipTermsIdExists?: boolean | null;
  securitiesMembershipsByPerkId?: SecuritiesDiscretionarygroupToManySecuritiesMembershipFilter | null;
  securitiesMembershipsByPerkIdExist?: boolean | null;
  securitiesNetworkassetByQuoteAssetId?: SecuritiesNetworkassetFilter | null;
  securitiesNetworkassetByQuoteAssetIdExists?: boolean | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesVenueByVenueId?: SecuritiesVenueFilter | null;
  securitiesVenueByVenueIdExists?: boolean | null;
  transparency?: StringFilter | null;
  venueId?: IntFilter | null;
  visibility?: StringFilter | null;
};
export type SecuritiesDiscretionarygroupToManySecuritiesDiscretionarycontentAudienceFilter = {
  every?: SecuritiesDiscretionarycontentAudienceFilter | null;
  none?: SecuritiesDiscretionarycontentAudienceFilter | null;
  some?: SecuritiesDiscretionarycontentAudienceFilter | null;
};
export type SecuritiesDiscretionarycontentAudienceFilter = {
  and?: ReadonlyArray<SecuritiesDiscretionarycontentAudienceFilter> | null;
  discretionarycontentId?: IntFilter | null;
  discretionarygroupId?: IntFilter | null;
  not?: SecuritiesDiscretionarycontentAudienceFilter | null;
  or?: ReadonlyArray<SecuritiesDiscretionarycontentAudienceFilter> | null;
  rowId?: IntFilter | null;
  securitiesDiscretionarycontentByDiscretionarycontentId?: SecuritiesDiscretionarycontentFilter | null;
  securitiesDiscretionarygroupByDiscretionarygroupId?: SecuritiesDiscretionarygroupFilter | null;
};
export type SecuritiesDiscretionarycontentFilter = {
  and?: ReadonlyArray<SecuritiesDiscretionarycontentFilter> | null;
  createdAt?: DatetimeFilter | null;
  createdById?: IntFilter | null;
  djangoContentTypeByPolymorphicCtypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByPolymorphicCtypeIdExists?: boolean | null;
  frozenAt?: DatetimeFilter | null;
  not?: SecuritiesDiscretionarycontentFilter | null;
  onId?: IntFilter | null;
  or?: ReadonlyArray<SecuritiesDiscretionarycontentFilter> | null;
  polymorphicCtypeId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesComponentcontentByDiscretionarycontentPtrId?: SecuritiesComponentcontentFilter | null;
  securitiesComponentcontentByDiscretionarycontentPtrIdExists?: boolean | null;
  securitiesDiscretionarycontentAudiencesByDiscretionarycontentId?: SecuritiesDiscretionarycontentToManySecuritiesDiscretionarycontentAudienceFilter | null;
  securitiesDiscretionarycontentAudiencesByDiscretionarycontentIdExist?: boolean | null;
  securitiesDiscretionarycontentByOnId?: SecuritiesDiscretionarycontentFilter | null;
  securitiesDiscretionarycontentByOnIdExists?: boolean | null;
  securitiesDiscretionarycontentsByOnId?: SecuritiesDiscretionarycontentToManySecuritiesDiscretionarycontentFilter | null;
  securitiesDiscretionarycontentsByOnIdExist?: boolean | null;
  securitiesIframecontentByDiscretionarycontentPtrId?: SecuritiesIframecontentFilter | null;
  securitiesIframecontentByDiscretionarycontentPtrIdExists?: boolean | null;
  securitiesImagecontentByDiscretionarycontentPtrId?: SecuritiesImagecontentFilter | null;
  securitiesImagecontentByDiscretionarycontentPtrIdExists?: boolean | null;
  securitiesProfileByCreatedById?: SecuritiesProfileFilter | null;
  securitiesTextcontentByDiscretionarycontentPtrId?: SecuritiesTextcontentFilter | null;
  securitiesTextcontentByDiscretionarycontentPtrIdExists?: boolean | null;
};
export type SecuritiesComponentcontentFilter = {
  and?: ReadonlyArray<SecuritiesComponentcontentFilter> | null;
  componentUrl?: StringFilter | null;
  discretionarycontentPtrId?: IntFilter | null;
  not?: SecuritiesComponentcontentFilter | null;
  or?: ReadonlyArray<SecuritiesComponentcontentFilter> | null;
  securitiesDiscretionarycontentByDiscretionarycontentPtrId?: SecuritiesDiscretionarycontentFilter | null;
};
export type SecuritiesDiscretionarycontentToManySecuritiesDiscretionarycontentFilter = {
  every?: SecuritiesDiscretionarycontentFilter | null;
  none?: SecuritiesDiscretionarycontentFilter | null;
  some?: SecuritiesDiscretionarycontentFilter | null;
};
export type SecuritiesDiscretionarycontentToManySecuritiesDiscretionarycontentAudienceFilter = {
  every?: SecuritiesDiscretionarycontentAudienceFilter | null;
  none?: SecuritiesDiscretionarycontentAudienceFilter | null;
  some?: SecuritiesDiscretionarycontentAudienceFilter | null;
};
export type SecuritiesIframecontentFilter = {
  and?: ReadonlyArray<SecuritiesIframecontentFilter> | null;
  discretionarycontentPtrId?: IntFilter | null;
  not?: SecuritiesIframecontentFilter | null;
  or?: ReadonlyArray<SecuritiesIframecontentFilter> | null;
  securitiesDiscretionarycontentByDiscretionarycontentPtrId?: SecuritiesDiscretionarycontentFilter | null;
  url?: StringFilter | null;
};
export type SecuritiesImagecontentFilter = {
  and?: ReadonlyArray<SecuritiesImagecontentFilter> | null;
  discretionarycontentPtrId?: IntFilter | null;
  image?: StringFilter | null;
  not?: SecuritiesImagecontentFilter | null;
  or?: ReadonlyArray<SecuritiesImagecontentFilter> | null;
  securitiesDiscretionarycontentByDiscretionarycontentPtrId?: SecuritiesDiscretionarycontentFilter | null;
};
export type SecuritiesTextcontentFilter = {
  and?: ReadonlyArray<SecuritiesTextcontentFilter> | null;
  discretionarycontentPtrId?: IntFilter | null;
  not?: SecuritiesTextcontentFilter | null;
  or?: ReadonlyArray<SecuritiesTextcontentFilter> | null;
  securitiesDiscretionarycontentByDiscretionarycontentPtrId?: SecuritiesDiscretionarycontentFilter | null;
  text?: StringFilter | null;
};
export type SecuritiesDiscretionarygroupToManySecuritiesMembershipFilter = {
  every?: SecuritiesMembershipFilter | null;
  none?: SecuritiesMembershipFilter | null;
  some?: SecuritiesMembershipFilter | null;
};
export type SecuritiesMembershipFilter = {
  and?: ReadonlyArray<SecuritiesMembershipFilter> | null;
  groupId?: IntFilter | null;
  isAdmin?: BooleanFilter | null;
  isVisibleToAssociatedMembers?: BooleanFilter | null;
  isVisibleToMembers?: BooleanFilter | null;
  memberId?: IntFilter | null;
  not?: SecuritiesMembershipFilter | null;
  or?: ReadonlyArray<SecuritiesMembershipFilter> | null;
  perkId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesDiscretionarygroupByPerkId?: SecuritiesDiscretionarygroupFilter | null;
  securitiesDiscretionarygroupByPerkIdExists?: boolean | null;
  securitiesDuespaymentsByMembershipId?: SecuritiesMembershipToManySecuritiesDuespaymentFilter | null;
  securitiesDuespaymentsByMembershipIdExist?: boolean | null;
  securitiesMembershiptransfersByMembershipId?: SecuritiesMembershipToManySecuritiesMembershiptransferFilter | null;
  securitiesMembershiptransfersByMembershipIdExist?: boolean | null;
  securitiesProfileByGroupId?: SecuritiesProfileFilter | null;
  securitiesProfileByMemberId?: SecuritiesProfileFilter | null;
  state?: StringFilter | null;
  until?: DateFilter | null;
};
export type SecuritiesMembershipToManySecuritiesDuespaymentFilter = {
  every?: SecuritiesDuespaymentFilter | null;
  none?: SecuritiesDuespaymentFilter | null;
  some?: SecuritiesDuespaymentFilter | null;
};
export type SecuritiesDuespaymentFilter = {
  and?: ReadonlyArray<SecuritiesDuespaymentFilter> | null;
  membershipId?: IntFilter | null;
  not?: SecuritiesDuespaymentFilter | null;
  or?: ReadonlyArray<SecuritiesDuespaymentFilter> | null;
  securitiesMembershipByMembershipId?: SecuritiesMembershipFilter | null;
  securitiesTransferByTransferPtrId?: SecuritiesTransferFilter | null;
  transferPtrId?: IntFilter | null;
};
export type SecuritiesTransferFilter = {
  and?: ReadonlyArray<SecuritiesTransferFilter> | null;
  assetId?: IntFilter | null;
  not?: SecuritiesTransferFilter | null;
  note?: StringFilter | null;
  or?: ReadonlyArray<SecuritiesTransferFilter> | null;
  quantity?: BigIntFilter | null;
  receiverId?: IntFilter | null;
  securitiesDuespaymentByTransferPtrId?: SecuritiesDuespaymentFilter | null;
  securitiesDuespaymentByTransferPtrIdExists?: boolean | null;
  securitiesNetworkassetByAssetId?: SecuritiesNetworkassetFilter | null;
  securitiesProfileByReceiverId?: SecuritiesProfileFilter | null;
  securitiesProfileByReceiverIdExists?: boolean | null;
  securitiesProfileBySenderId?: SecuritiesProfileFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  senderId?: IntFilter | null;
  status?: StringFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesMembershipToManySecuritiesMembershiptransferFilter = {
  every?: SecuritiesMembershiptransferFilter | null;
  none?: SecuritiesMembershiptransferFilter | null;
  some?: SecuritiesMembershiptransferFilter | null;
};
export type SecuritiesMembershiptransferFilter = {
  and?: ReadonlyArray<SecuritiesMembershiptransferFilter> | null;
  membershipId?: IntFilter | null;
  not?: SecuritiesMembershiptransferFilter | null;
  or?: ReadonlyArray<SecuritiesMembershiptransferFilter> | null;
  pockettransferPtrId?: IntFilter | null;
  securitiesMembershipByMembershipId?: SecuritiesMembershipFilter | null;
  securitiesPockettransferByPockettransferPtrId?: SecuritiesPockettransferFilter | null;
};
export type SecuritiesPockettransferFilter = {
  and?: ReadonlyArray<SecuritiesPockettransferFilter> | null;
  assetId?: IntFilter | null;
  not?: SecuritiesPockettransferFilter | null;
  note?: StringFilter | null;
  or?: ReadonlyArray<SecuritiesPockettransferFilter> | null;
  quantity?: BigIntFilter | null;
  receiverId?: IntFilter | null;
  securitiesMembershiptransferByPockettransferPtrId?: SecuritiesMembershiptransferFilter | null;
  securitiesMembershiptransferByPockettransferPtrIdExists?: boolean | null;
  securitiesNetworkassetByAssetId?: SecuritiesNetworkassetFilter | null;
  securitiesPocketByReceiverId?: SecuritiesPocketFilter | null;
  securitiesPocketByReceiverIdExists?: boolean | null;
  securitiesPocketBySenderId?: SecuritiesPocketFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  senderId?: IntFilter | null;
  status?: StringFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesVenueFilter = {
  and?: ReadonlyArray<SecuritiesVenueFilter> | null;
  assetClassId?: IntFilter | null;
  boost?: BigIntFilter | null;
  createdAt?: DatetimeFilter | null;
  description?: StringFilter | null;
  endsAt?: DatetimeFilter | null;
  hitAt?: DatetimeFilter | null;
  image?: StringFilter | null;
  isAllOrNothing?: BooleanFilter | null;
  name?: StringFilter | null;
  not?: SecuritiesVenueFilter | null;
  or?: ReadonlyArray<SecuritiesVenueFilter> | null;
  pocketId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByTargetQuoteAssetId?: SecuritiesAssetFilter | null;
  securitiesAssetByTargetQuoteAssetIdExists?: boolean | null;
  securitiesAssetclassByAssetClassId?: SecuritiesAssetclassFilter | null;
  securitiesAssetclassByAssetClassIdExists?: boolean | null;
  securitiesAssetsByVenueId?: SecuritiesVenueToManySecuritiesAssetFilter | null;
  securitiesAssetsByVenueIdExist?: boolean | null;
  securitiesBoostsByVenueId?: SecuritiesVenueToManySecuritiesBoostFilter | null;
  securitiesBoostsByVenueIdExist?: boolean | null;
  securitiesDiscretionarygroupsByVenueId?: SecuritiesVenueToManySecuritiesDiscretionarygroupFilter | null;
  securitiesDiscretionarygroupsByVenueIdExist?: boolean | null;
  securitiesPocketByPocketId?: SecuritiesPocketFilter | null;
  securitiesVenueTagsByVenueId?: SecuritiesVenueToManySecuritiesVenueTagFilter | null;
  securitiesVenueTagsByVenueIdExist?: boolean | null;
  target?: BigFloatFilter | null;
  targetQuoteAssetId?: IntFilter | null;
  venueType?: StringFilter | null;
};
export type SecuritiesVenueToManySecuritiesAssetFilter = {
  every?: SecuritiesAssetFilter | null;
  none?: SecuritiesAssetFilter | null;
  some?: SecuritiesAssetFilter | null;
};
export type SecuritiesVenueToManySecuritiesBoostFilter = {
  every?: SecuritiesBoostFilter | null;
  none?: SecuritiesBoostFilter | null;
  some?: SecuritiesBoostFilter | null;
};
export type SecuritiesBoostFilter = {
  activityPtrId?: IntFilter | null;
  amount?: BigIntFilter | null;
  and?: ReadonlyArray<SecuritiesBoostFilter> | null;
  not?: SecuritiesBoostFilter | null;
  or?: ReadonlyArray<SecuritiesBoostFilter> | null;
  securitiesActivityByActivityPtrId?: SecuritiesActivityFilter | null;
  securitiesProfileBySenderId?: SecuritiesProfileFilter | null;
  securitiesVenueByVenueId?: SecuritiesVenueFilter | null;
  senderId?: IntFilter | null;
  venueId?: IntFilter | null;
};
export type SecuritiesActivityFilter = {
  and?: ReadonlyArray<SecuritiesActivityFilter> | null;
  createdAt?: DatetimeFilter | null;
  djangoContentTypeByPolymorphicCtypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByPolymorphicCtypeIdExists?: boolean | null;
  not?: SecuritiesActivityFilter | null;
  or?: ReadonlyArray<SecuritiesActivityFilter> | null;
  polymorphicCtypeId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesBoostByActivityPtrId?: SecuritiesBoostFilter | null;
  securitiesBoostByActivityPtrIdExists?: boolean | null;
  securitiesTransactionsByActivityId?: SecuritiesActivityToManySecuritiesTransactionFilter | null;
  securitiesTransactionsByActivityIdExist?: boolean | null;
};
export type SecuritiesActivityToManySecuritiesTransactionFilter = {
  every?: SecuritiesTransactionFilter | null;
  none?: SecuritiesTransactionFilter | null;
  some?: SecuritiesTransactionFilter | null;
};
export type SecuritiesVenueToManySecuritiesDiscretionarygroupFilter = {
  every?: SecuritiesDiscretionarygroupFilter | null;
  none?: SecuritiesDiscretionarygroupFilter | null;
  some?: SecuritiesDiscretionarygroupFilter | null;
};
export type SecuritiesVenueToManySecuritiesVenueTagFilter = {
  every?: SecuritiesVenueTagFilter | null;
  none?: SecuritiesVenueTagFilter | null;
  some?: SecuritiesVenueTagFilter | null;
};
export type SecuritiesVenueTagFilter = {
  and?: ReadonlyArray<SecuritiesVenueTagFilter> | null;
  keyId?: IntFilter | null;
  not?: SecuritiesVenueTagFilter | null;
  or?: ReadonlyArray<SecuritiesVenueTagFilter> | null;
  rosettaKeyByKeyId?: RosettaKeyFilter | null;
  rowId?: IntFilter | null;
  securitiesVenueByVenueId?: SecuritiesVenueFilter | null;
  venueId?: IntFilter | null;
};
export type RosettaKeyFilter = {
  and?: ReadonlyArray<RosettaKeyFilter> | null;
  classContentId?: IntFilter | null;
  createdAt?: DatetimeFilter | null;
  not?: RosettaKeyFilter | null;
  or?: ReadonlyArray<RosettaKeyFilter> | null;
  rosettaClasscontentByClassContentId?: RosettaClasscontentFilter | null;
  rosettaClasscontentByClassContentIdExists?: boolean | null;
  rosettaTranslationsByKeyId?: RosettaKeyToManyRosettaTranslationFilter | null;
  rosettaTranslationsByKeyIdExist?: boolean | null;
  rowId?: IntFilter | null;
  securitiesBoostdecayByTagId?: SecuritiesBoostdecayFilter | null;
  securitiesBoostdecayByTagIdExists?: boolean | null;
  securitiesProfileTagsByKeyId?: RosettaKeyToManySecuritiesProfileTagFilter | null;
  securitiesProfileTagsByKeyIdExist?: boolean | null;
  securitiesVenueTagsByKeyId?: RosettaKeyToManySecuritiesVenueTagFilter | null;
  securitiesVenueTagsByKeyIdExist?: boolean | null;
  tag?: StringFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type RosettaKeyToManyRosettaTranslationFilter = {
  every?: RosettaTranslationFilter | null;
  none?: RosettaTranslationFilter | null;
  some?: RosettaTranslationFilter | null;
};
export type RosettaTranslationFilter = {
  and?: ReadonlyArray<RosettaTranslationFilter> | null;
  createdAt?: DatetimeFilter | null;
  keyId?: IntFilter | null;
  lang?: StringFilter | null;
  localeId?: IntFilter | null;
  not?: RosettaTranslationFilter | null;
  or?: ReadonlyArray<RosettaTranslationFilter> | null;
  rosettaKeyByKeyId?: RosettaKeyFilter | null;
  rosettaLocaleByLocaleId?: RosettaLocaleFilter | null;
  rowId?: IntFilter | null;
  text?: StringFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type RosettaLocaleFilter = {
  and?: ReadonlyArray<RosettaLocaleFilter> | null;
  code?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  flag?: StringFilter | null;
  isUserSelectable?: BooleanFilter | null;
  name?: StringFilter | null;
  not?: RosettaLocaleFilter | null;
  or?: ReadonlyArray<RosettaLocaleFilter> | null;
  rosettaTranslationsByLocaleId?: RosettaLocaleToManyRosettaTranslationFilter | null;
  rosettaTranslationsByLocaleIdExist?: boolean | null;
  rowId?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type RosettaLocaleToManyRosettaTranslationFilter = {
  every?: RosettaTranslationFilter | null;
  none?: RosettaTranslationFilter | null;
  some?: RosettaTranslationFilter | null;
};
export type SecuritiesBoostdecayFilter = {
  and?: ReadonlyArray<SecuritiesBoostdecayFilter> | null;
  not?: SecuritiesBoostdecayFilter | null;
  or?: ReadonlyArray<SecuritiesBoostdecayFilter> | null;
  rosettaKeyByTagId?: RosettaKeyFilter | null;
  rowId?: IntFilter | null;
  tagId?: IntFilter | null;
  value?: BigFloatFilter | null;
};
export type RosettaKeyToManySecuritiesProfileTagFilter = {
  every?: SecuritiesProfileTagFilter | null;
  none?: SecuritiesProfileTagFilter | null;
  some?: SecuritiesProfileTagFilter | null;
};
export type SecuritiesProfileTagFilter = {
  and?: ReadonlyArray<SecuritiesProfileTagFilter> | null;
  keyId?: IntFilter | null;
  not?: SecuritiesProfileTagFilter | null;
  or?: ReadonlyArray<SecuritiesProfileTagFilter> | null;
  profileId?: IntFilter | null;
  rosettaKeyByKeyId?: RosettaKeyFilter | null;
  rowId?: IntFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
};
export type RosettaKeyToManySecuritiesVenueTagFilter = {
  every?: SecuritiesVenueTagFilter | null;
  none?: SecuritiesVenueTagFilter | null;
  some?: SecuritiesVenueTagFilter | null;
};
export type RosettaClasscontentFilter = {
  and?: ReadonlyArray<RosettaClasscontentFilter> | null;
  classCtypeId?: IntFilter | null;
  classObjId?: IntFilter | null;
  contentName?: StringFilter | null;
  djangoContentTypeByClassCtypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByClassCtypeIdExists?: boolean | null;
  not?: RosettaClasscontentFilter | null;
  or?: ReadonlyArray<RosettaClasscontentFilter> | null;
  rosettaKeysByClassContentId?: RosettaClasscontentToManyRosettaKeyFilter | null;
  rosettaKeysByClassContentIdExist?: boolean | null;
  rowId?: IntFilter | null;
};
export type RosettaClasscontentToManyRosettaKeyFilter = {
  every?: RosettaKeyFilter | null;
  none?: RosettaKeyFilter | null;
  some?: RosettaKeyFilter | null;
};
export type SecuritiesAssetclassFilter = {
  and?: ReadonlyArray<SecuritiesAssetclassFilter> | null;
  filerImageByImageId?: FilerImageFilter | null;
  filerImageByImageIdExists?: boolean | null;
  imageAttribution?: StringFilter | null;
  imageAttributionUrl?: StringFilter | null;
  imageId?: IntFilter | null;
  isOpen?: BooleanFilter | null;
  isVisible?: BooleanFilter | null;
  not?: SecuritiesAssetclassFilter | null;
  or?: ReadonlyArray<SecuritiesAssetclassFilter> | null;
  rowId?: IntFilter | null;
  securitiesAssetclasscontentsByObjId?: SecuritiesAssetclassToManySecuritiesAssetclasscontentFilter | null;
  securitiesAssetclasscontentsByObjIdExist?: boolean | null;
  securitiesAssetsByAssetClassId?: SecuritiesAssetclassToManySecuritiesAssetFilter | null;
  securitiesAssetsByAssetClassIdExist?: boolean | null;
  securitiesVenuesByAssetClassId?: SecuritiesAssetclassToManySecuritiesVenueFilter | null;
  securitiesVenuesByAssetClassIdExist?: boolean | null;
  slug?: StringFilter | null;
  sortTerm?: StringFilter | null;
};
export type SecuritiesAssetclassToManySecuritiesAssetFilter = {
  every?: SecuritiesAssetFilter | null;
  none?: SecuritiesAssetFilter | null;
  some?: SecuritiesAssetFilter | null;
};
export type SecuritiesAssetclassToManySecuritiesAssetclasscontentFilter = {
  every?: SecuritiesAssetclasscontentFilter | null;
  none?: SecuritiesAssetclasscontentFilter | null;
  some?: SecuritiesAssetclasscontentFilter | null;
};
export type SecuritiesAssetclasscontentFilter = {
  and?: ReadonlyArray<SecuritiesAssetclasscontentFilter> | null;
  cta?: StringFilter | null;
  description?: StringFilter | null;
  language?: StringFilter | null;
  name?: StringFilter | null;
  not?: SecuritiesAssetclasscontentFilter | null;
  objId?: IntFilter | null;
  or?: ReadonlyArray<SecuritiesAssetclasscontentFilter> | null;
  rowId?: IntFilter | null;
  securitiesAssetclassByObjId?: SecuritiesAssetclassFilter | null;
};
export type SecuritiesAssetclassToManySecuritiesVenueFilter = {
  every?: SecuritiesVenueFilter | null;
  none?: SecuritiesVenueFilter | null;
  some?: SecuritiesVenueFilter | null;
};
export type FilerImageFilter = {
  _height?: FloatFilter | null;
  _width?: FloatFilter | null;
  and?: ReadonlyArray<FilerImageFilter> | null;
  author?: StringFilter | null;
  dateTaken?: DatetimeFilter | null;
  defaultAltText?: StringFilter | null;
  defaultCaption?: StringFilter | null;
  djangocmsGooglemapGooglemapmarkersByIconId?: FilerImageToManyDjangocmsGooglemapGooglemapmarkerFilter | null;
  djangocmsGooglemapGooglemapmarkersByIconIdExist?: boolean | null;
  djangocmsPicturePicturesByPictureId?: FilerImageToManyDjangocmsPicturePictureFilter | null;
  djangocmsPicturePicturesByPictureIdExist?: boolean | null;
  djangocmsVideoVideoplayersByPosterId?: FilerImageToManyDjangocmsVideoVideoplayerFilter | null;
  djangocmsVideoVideoplayersByPosterIdExist?: boolean | null;
  filePtrId?: IntFilter | null;
  filerFileByFilePtrId?: FilerFileFilter | null;
  mustAlwaysPublishAuthorCredit?: BooleanFilter | null;
  mustAlwaysPublishCopyright?: BooleanFilter | null;
  not?: FilerImageFilter | null;
  or?: ReadonlyArray<FilerImageFilter> | null;
  securitiesAssetclassesByImageId?: FilerImageToManySecuritiesAssetclassFilter | null;
  securitiesAssetclassesByImageIdExist?: boolean | null;
  securitiesAssetimagesByImageId?: FilerImageToManySecuritiesAssetimageFilter | null;
  securitiesAssetimagesByImageIdExist?: boolean | null;
  securitiesAssetsByFeaturedArtId?: FilerImageToManySecuritiesAssetFilter | null;
  securitiesAssetsByFeaturedArtIdExist?: boolean | null;
  subjectLocation?: StringFilter | null;
};
export type FilerImageToManySecuritiesAssetFilter = {
  every?: SecuritiesAssetFilter | null;
  none?: SecuritiesAssetFilter | null;
  some?: SecuritiesAssetFilter | null;
};
export type FilerImageToManySecuritiesAssetclassFilter = {
  every?: SecuritiesAssetclassFilter | null;
  none?: SecuritiesAssetclassFilter | null;
  some?: SecuritiesAssetclassFilter | null;
};
export type FilerImageToManyDjangocmsGooglemapGooglemapmarkerFilter = {
  every?: DjangocmsGooglemapGooglemapmarkerFilter | null;
  none?: DjangocmsGooglemapGooglemapmarkerFilter | null;
  some?: DjangocmsGooglemapGooglemapmarkerFilter | null;
};
export type DjangocmsGooglemapGooglemapmarkerFilter = {
  address?: StringFilter | null;
  and?: ReadonlyArray<DjangocmsGooglemapGooglemapmarkerFilter> | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmspluginPtrId?: IntFilter | null;
  filerImageByIconId?: FilerImageFilter | null;
  filerImageByIconIdExists?: boolean | null;
  iconId?: IntFilter | null;
  infoContent?: StringFilter | null;
  lat?: FloatFilter | null;
  lng?: FloatFilter | null;
  not?: DjangocmsGooglemapGooglemapmarkerFilter | null;
  or?: ReadonlyArray<DjangocmsGooglemapGooglemapmarkerFilter> | null;
  showContent?: BooleanFilter | null;
  title?: StringFilter | null;
};
export type CmsCmspluginFilter = {
  and?: ReadonlyArray<CmsCmspluginFilter> | null;
  changedDate?: DatetimeFilter | null;
  cmsAliaspluginmodelByCmspluginPtrId?: CmsAliaspluginmodelFilter | null;
  cmsAliaspluginmodelByCmspluginPtrIdExists?: boolean | null;
  cmsAliaspluginmodelsByPluginId?: CmsCmspluginToManyCmsAliaspluginmodelFilter | null;
  cmsAliaspluginmodelsByPluginIdExist?: boolean | null;
  cmsCmspluginByParentId?: CmsCmspluginFilter | null;
  cmsCmspluginByParentIdExists?: boolean | null;
  cmsCmspluginsByParentId?: CmsCmspluginToManyCmsCmspluginFilter | null;
  cmsCmspluginsByParentIdExist?: boolean | null;
  cmsPlaceholderByPlaceholderId?: CmsPlaceholderFilter | null;
  cmsPlaceholderByPlaceholderIdExists?: boolean | null;
  cmsPlaceholderreferenceByCmspluginPtrId?: CmsPlaceholderreferenceFilter | null;
  cmsPlaceholderreferenceByCmspluginPtrIdExists?: boolean | null;
  creationDate?: DatetimeFilter | null;
  depth?: IntFilter | null;
  djangocmsFileFileByCmspluginPtrId?: DjangocmsFileFileFilter | null;
  djangocmsFileFileByCmspluginPtrIdExists?: boolean | null;
  djangocmsFileFolderByCmspluginPtrId?: DjangocmsFileFolderFilter | null;
  djangocmsFileFolderByCmspluginPtrIdExists?: boolean | null;
  djangocmsFormsFormdefinitionByCmspluginPtrId?: DjangocmsFormsFormdefinitionFilter | null;
  djangocmsFormsFormdefinitionByCmspluginPtrIdExists?: boolean | null;
  djangocmsGooglemapGooglemapByCmspluginPtrId?: DjangocmsGooglemapGooglemapFilter | null;
  djangocmsGooglemapGooglemapByCmspluginPtrIdExists?: boolean | null;
  djangocmsGooglemapGooglemapmarkerByCmspluginPtrId?: DjangocmsGooglemapGooglemapmarkerFilter | null;
  djangocmsGooglemapGooglemapmarkerByCmspluginPtrIdExists?: boolean | null;
  djangocmsGooglemapGooglemaprouteByCmspluginPtrId?: DjangocmsGooglemapGooglemaprouteFilter | null;
  djangocmsGooglemapGooglemaprouteByCmspluginPtrIdExists?: boolean | null;
  djangocmsLinkLinkByCmspluginPtrId?: DjangocmsLinkLinkFilter | null;
  djangocmsLinkLinkByCmspluginPtrIdExists?: boolean | null;
  djangocmsPicturePictureByCmspluginPtrId?: DjangocmsPicturePictureFilter | null;
  djangocmsPicturePictureByCmspluginPtrIdExists?: boolean | null;
  djangocmsSnippetSnippetptrByCmspluginPtrId?: DjangocmsSnippetSnippetptrFilter | null;
  djangocmsSnippetSnippetptrByCmspluginPtrIdExists?: boolean | null;
  djangocmsStyleStyleByCmspluginPtrId?: DjangocmsStyleStyleFilter | null;
  djangocmsStyleStyleByCmspluginPtrIdExists?: boolean | null;
  djangocmsTextCkeditorTextByCmspluginPtrId?: DjangocmsTextCkeditorTextFilter | null;
  djangocmsTextCkeditorTextByCmspluginPtrIdExists?: boolean | null;
  djangocmsVideoVideoplayerByCmspluginPtrId?: DjangocmsVideoVideoplayerFilter | null;
  djangocmsVideoVideoplayerByCmspluginPtrIdExists?: boolean | null;
  djangocmsVideoVideosourceByCmspluginPtrId?: DjangocmsVideoVideosourceFilter | null;
  djangocmsVideoVideosourceByCmspluginPtrIdExists?: boolean | null;
  djangocmsVideoVideotrackByCmspluginPtrId?: DjangocmsVideoVideotrackFilter | null;
  djangocmsVideoVideotrackByCmspluginPtrIdExists?: boolean | null;
  language?: StringFilter | null;
  not?: CmsCmspluginFilter | null;
  numchild?: IntFilter | null;
  or?: ReadonlyArray<CmsCmspluginFilter> | null;
  parentId?: IntFilter | null;
  path?: StringFilter | null;
  placeholderId?: IntFilter | null;
  pluginType?: StringFilter | null;
  position?: IntFilter | null;
  rowId?: IntFilter | null;
};
export type CmsAliaspluginmodelFilter = {
  aliasPlaceholderId?: IntFilter | null;
  and?: ReadonlyArray<CmsAliaspluginmodelFilter> | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmsCmspluginByPluginId?: CmsCmspluginFilter | null;
  cmsCmspluginByPluginIdExists?: boolean | null;
  cmsPlaceholderByAliasPlaceholderId?: CmsPlaceholderFilter | null;
  cmsPlaceholderByAliasPlaceholderIdExists?: boolean | null;
  cmspluginPtrId?: IntFilter | null;
  not?: CmsAliaspluginmodelFilter | null;
  or?: ReadonlyArray<CmsAliaspluginmodelFilter> | null;
  pluginId?: IntFilter | null;
};
export type CmsPlaceholderFilter = {
  and?: ReadonlyArray<CmsPlaceholderFilter> | null;
  cmsAliaspluginmodelsByAliasPlaceholderId?: CmsPlaceholderToManyCmsAliaspluginmodelFilter | null;
  cmsAliaspluginmodelsByAliasPlaceholderIdExist?: boolean | null;
  cmsCmspluginsByPlaceholderId?: CmsPlaceholderToManyCmsCmspluginFilter | null;
  cmsCmspluginsByPlaceholderIdExist?: boolean | null;
  cmsPagePlaceholdersByPlaceholderId?: CmsPlaceholderToManyCmsPagePlaceholderFilter | null;
  cmsPagePlaceholdersByPlaceholderIdExist?: boolean | null;
  cmsPlaceholderreferencesByPlaceholderRefId?: CmsPlaceholderToManyCmsPlaceholderreferenceFilter | null;
  cmsPlaceholderreferencesByPlaceholderRefIdExist?: boolean | null;
  cmsStaticplaceholdersByDraftId?: CmsPlaceholderToManyCmsStaticplaceholderFilter | null;
  cmsStaticplaceholdersByDraftIdExist?: boolean | null;
  cmsStaticplaceholdersByPublicId?: CmsPlaceholderToManyCmsStaticplaceholderFilter | null;
  cmsStaticplaceholdersByPublicIdExist?: boolean | null;
  cmsUsersettingsByClipboardId?: CmsPlaceholderToManyCmsUsersettingFilter | null;
  cmsUsersettingsByClipboardIdExist?: boolean | null;
  defaultWidth?: IntFilter | null;
  not?: CmsPlaceholderFilter | null;
  or?: ReadonlyArray<CmsPlaceholderFilter> | null;
  rowId?: IntFilter | null;
  slot?: StringFilter | null;
};
export type CmsPlaceholderToManyCmsAliaspluginmodelFilter = {
  every?: CmsAliaspluginmodelFilter | null;
  none?: CmsAliaspluginmodelFilter | null;
  some?: CmsAliaspluginmodelFilter | null;
};
export type CmsPlaceholderToManyCmsCmspluginFilter = {
  every?: CmsCmspluginFilter | null;
  none?: CmsCmspluginFilter | null;
  some?: CmsCmspluginFilter | null;
};
export type CmsPlaceholderToManyCmsPagePlaceholderFilter = {
  every?: CmsPagePlaceholderFilter | null;
  none?: CmsPagePlaceholderFilter | null;
  some?: CmsPagePlaceholderFilter | null;
};
export type CmsPagePlaceholderFilter = {
  and?: ReadonlyArray<CmsPagePlaceholderFilter> | null;
  cmsPageByPageId?: CmsPageFilter | null;
  cmsPlaceholderByPlaceholderId?: CmsPlaceholderFilter | null;
  not?: CmsPagePlaceholderFilter | null;
  or?: ReadonlyArray<CmsPagePlaceholderFilter> | null;
  pageId?: IntFilter | null;
  placeholderId?: IntFilter | null;
  rowId?: IntFilter | null;
};
export type CmsPageFilter = {
  and?: ReadonlyArray<CmsPageFilter> | null;
  applicationNamespace?: StringFilter | null;
  applicationUrls?: StringFilter | null;
  changedBy?: StringFilter | null;
  changedDate?: DatetimeFilter | null;
  cmsPageByPublisherPublicId?: CmsPageFilter | null;
  cmsPageByPublisherPublicIdExists?: boolean | null;
  cmsPageNodeId?: IntFilter | null;
  cmsPagePlaceholdersByPageId?: CmsPageToManyCmsPagePlaceholderFilter | null;
  cmsPagePlaceholdersByPageIdExist?: boolean | null;
  cmsPagepermissionsByPageId?: CmsPageToManyCmsPagepermissionFilter | null;
  cmsPagepermissionsByPageIdExist?: boolean | null;
  cmsTitlesByPageId?: CmsPageToManyCmsTitleFilter | null;
  cmsTitlesByPageIdExist?: boolean | null;
  cmsTreenodeByCmsPageNodeId?: CmsTreenodeFilter | null;
  cms_page_publisher_fk_cms_page_id?: CmsPageFilter | null;
  cms_page_publisher_fk_cms_page_idExists?: boolean | null;
  createdBy?: StringFilter | null;
  creationDate?: DatetimeFilter | null;
  djangocmsFormsFormdefinitionsByPageRedirectId?: CmsPageToManyDjangocmsFormsFormdefinitionFilter | null;
  djangocmsFormsFormdefinitionsByPageRedirectIdExist?: boolean | null;
  djangocmsLinkLinksByInternalLinkId?: CmsPageToManyDjangocmsLinkLinkFilter | null;
  djangocmsLinkLinksByInternalLinkIdExist?: boolean | null;
  djangocmsPicturePicturesByLinkPageId?: CmsPageToManyDjangocmsPicturePictureFilter | null;
  djangocmsPicturePicturesByLinkPageIdExist?: boolean | null;
  inNavigation?: BooleanFilter | null;
  isHome?: BooleanFilter | null;
  isPageType?: BooleanFilter | null;
  languages?: StringFilter | null;
  limitVisibilityInMenu?: IntFilter | null;
  loginRequired?: BooleanFilter | null;
  navigationExtenders?: StringFilter | null;
  not?: CmsPageFilter | null;
  or?: ReadonlyArray<CmsPageFilter> | null;
  publicationDate?: DatetimeFilter | null;
  publicationEndDate?: DatetimeFilter | null;
  publisherIsDraft?: BooleanFilter | null;
  publisherPublicId?: IntFilter | null;
  reverseId?: StringFilter | null;
  rowId?: IntFilter | null;
  softRoot?: BooleanFilter | null;
  template?: StringFilter | null;
  xframeOptions?: IntFilter | null;
};
export type CmsPageToManyCmsPagePlaceholderFilter = {
  every?: CmsPagePlaceholderFilter | null;
  none?: CmsPagePlaceholderFilter | null;
  some?: CmsPagePlaceholderFilter | null;
};
export type CmsPageToManyCmsPagepermissionFilter = {
  every?: CmsPagepermissionFilter | null;
  none?: CmsPagepermissionFilter | null;
  some?: CmsPagepermissionFilter | null;
};
export type CmsPagepermissionFilter = {
  and?: ReadonlyArray<CmsPagepermissionFilter> | null;
  authGroupByGroupId?: AuthGroupFilter | null;
  authGroupByGroupIdExists?: boolean | null;
  authUserByUserId?: AuthUserFilter | null;
  authUserByUserIdExists?: boolean | null;
  canAdd?: BooleanFilter | null;
  canChange?: BooleanFilter | null;
  canChangeAdvancedSettings?: BooleanFilter | null;
  canChangePermissions?: BooleanFilter | null;
  canDelete?: BooleanFilter | null;
  canMovePage?: BooleanFilter | null;
  canPublish?: BooleanFilter | null;
  canView?: BooleanFilter | null;
  cmsPageByPageId?: CmsPageFilter | null;
  cmsPageByPageIdExists?: boolean | null;
  grantOn?: IntFilter | null;
  groupId?: IntFilter | null;
  not?: CmsPagepermissionFilter | null;
  or?: ReadonlyArray<CmsPagepermissionFilter> | null;
  pageId?: IntFilter | null;
  rowId?: IntFilter | null;
  userId?: IntFilter | null;
};
export type CmsPageToManyCmsTitleFilter = {
  every?: CmsTitleFilter | null;
  none?: CmsTitleFilter | null;
  some?: CmsTitleFilter | null;
};
export type CmsTitleFilter = {
  and?: ReadonlyArray<CmsTitleFilter> | null;
  cmsPageByPageId?: CmsPageFilter | null;
  cmsTitleByPublisherPublicId?: CmsTitleFilter | null;
  cmsTitleByPublisherPublicIdExists?: boolean | null;
  cms_title_publisher_fk_cms_title_id?: CmsTitleFilter | null;
  cms_title_publisher_fk_cms_title_idExists?: boolean | null;
  creationDate?: DatetimeFilter | null;
  hasUrlOverwrite?: BooleanFilter | null;
  language?: StringFilter | null;
  menuTitle?: StringFilter | null;
  metaDescription?: StringFilter | null;
  not?: CmsTitleFilter | null;
  or?: ReadonlyArray<CmsTitleFilter> | null;
  pageId?: IntFilter | null;
  pageTitle?: StringFilter | null;
  path?: StringFilter | null;
  published?: BooleanFilter | null;
  publisherIsDraft?: BooleanFilter | null;
  publisherPublicId?: IntFilter | null;
  publisherState?: IntFilter | null;
  redirect?: StringFilter | null;
  rowId?: IntFilter | null;
  slug?: StringFilter | null;
  title?: StringFilter | null;
};
export type CmsPageToManyDjangocmsFormsFormdefinitionFilter = {
  every?: DjangocmsFormsFormdefinitionFilter | null;
  none?: DjangocmsFormsFormdefinitionFilter | null;
  some?: DjangocmsFormsFormdefinitionFilter | null;
};
export type DjangocmsFormsFormdefinitionFilter = {
  and?: ReadonlyArray<DjangocmsFormsFormdefinitionFilter> | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmsPageByPageRedirectId?: CmsPageFilter | null;
  cmsPageByPageRedirectIdExists?: boolean | null;
  cmspluginPtrId?: IntFilter | null;
  description?: StringFilter | null;
  djangocmsFormsFormByPluginReferenceId?: DjangocmsFormsFormFilter | null;
  djangocmsFormsFormByPluginReferenceIdExists?: boolean | null;
  djangocmsFormsFormfieldsByFormId?: DjangocmsFormsFormdefinitionToManyDjangocmsFormsFormfieldFilter | null;
  djangocmsFormsFormfieldsByFormIdExist?: boolean | null;
  emailFrom?: StringFilter | null;
  emailSubject?: StringFilter | null;
  emailTo?: StringFilter | null;
  emailUploadedFiles?: BooleanFilter | null;
  externalRedirect?: StringFilter | null;
  formTemplate?: StringFilter | null;
  name?: StringFilter | null;
  not?: DjangocmsFormsFormdefinitionFilter | null;
  or?: ReadonlyArray<DjangocmsFormsFormdefinitionFilter> | null;
  pageRedirectId?: IntFilter | null;
  pluginReferenceId?: IntFilter | null;
  postSubmitMsg?: StringFilter | null;
  redirectDelay?: IntFilter | null;
  saveData?: BooleanFilter | null;
  spamProtection?: IntFilter | null;
  submitBtnTxt?: StringFilter | null;
  successRedirect?: BooleanFilter | null;
  title?: StringFilter | null;
};
export type DjangocmsFormsFormdefinitionToManyDjangocmsFormsFormfieldFilter = {
  every?: DjangocmsFormsFormfieldFilter | null;
  none?: DjangocmsFormsFormfieldFilter | null;
  some?: DjangocmsFormsFormfieldFilter | null;
};
export type DjangocmsFormsFormfieldFilter = {
  and?: ReadonlyArray<DjangocmsFormsFormfieldFilter> | null;
  choiceValues?: StringFilter | null;
  djangocmsFormsFormdefinitionByFormId?: DjangocmsFormsFormdefinitionFilter | null;
  fieldName?: StringFilter | null;
  fieldType?: StringFilter | null;
  formId?: IntFilter | null;
  helpText?: StringFilter | null;
  initial?: StringFilter | null;
  label?: StringFilter | null;
  not?: DjangocmsFormsFormfieldFilter | null;
  or?: ReadonlyArray<DjangocmsFormsFormfieldFilter> | null;
  placeholderText?: StringFilter | null;
  position?: IntFilter | null;
  required?: BooleanFilter | null;
  rowId?: IntFilter | null;
};
export type DjangocmsFormsFormFilter = {
  and?: ReadonlyArray<DjangocmsFormsFormFilter> | null;
  djangocmsFormsFormdefinitionsByPluginReferenceId?: DjangocmsFormsFormToManyDjangocmsFormsFormdefinitionFilter | null;
  djangocmsFormsFormdefinitionsByPluginReferenceIdExist?: boolean | null;
  djangocmsFormsFormsubmissionsByPluginId?: DjangocmsFormsFormToManyDjangocmsFormsFormsubmissionFilter | null;
  djangocmsFormsFormsubmissionsByPluginIdExist?: boolean | null;
  name?: StringFilter | null;
  not?: DjangocmsFormsFormFilter | null;
  or?: ReadonlyArray<DjangocmsFormsFormFilter> | null;
  rowId?: IntFilter | null;
};
export type DjangocmsFormsFormToManyDjangocmsFormsFormdefinitionFilter = {
  every?: DjangocmsFormsFormdefinitionFilter | null;
  none?: DjangocmsFormsFormdefinitionFilter | null;
  some?: DjangocmsFormsFormdefinitionFilter | null;
};
export type DjangocmsFormsFormToManyDjangocmsFormsFormsubmissionFilter = {
  every?: DjangocmsFormsFormsubmissionFilter | null;
  none?: DjangocmsFormsFormsubmissionFilter | null;
  some?: DjangocmsFormsFormsubmissionFilter | null;
};
export type DjangocmsFormsFormsubmissionFilter = {
  and?: ReadonlyArray<DjangocmsFormsFormsubmissionFilter> | null;
  authUserByCreatedById?: AuthUserFilter | null;
  authUserByCreatedByIdExists?: boolean | null;
  createdById?: IntFilter | null;
  creationDate?: DatetimeFilter | null;
  djangocmsFormsFormByPluginId?: DjangocmsFormsFormFilter | null;
  formData?: StringFilter | null;
  ip?: InternetAddressFilter | null;
  not?: DjangocmsFormsFormsubmissionFilter | null;
  or?: ReadonlyArray<DjangocmsFormsFormsubmissionFilter> | null;
  pluginId?: IntFilter | null;
  referrer?: StringFilter | null;
  rowId?: IntFilter | null;
};
export type InternetAddressFilter = {
  containedBy?: any | null;
  containedByOrEqualTo?: any | null;
  contains?: any | null;
  containsOrContainedBy?: any | null;
  containsOrEqualTo?: any | null;
  distinctFrom?: any | null;
  equalTo?: any | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any> | null;
  isNull?: boolean | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notDistinctFrom?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any> | null;
};
export type CmsPageToManyDjangocmsLinkLinkFilter = {
  every?: DjangocmsLinkLinkFilter | null;
  none?: DjangocmsLinkLinkFilter | null;
  some?: DjangocmsLinkLinkFilter | null;
};
export type DjangocmsLinkLinkFilter = {
  anchor?: StringFilter | null;
  and?: ReadonlyArray<DjangocmsLinkLinkFilter> | null;
  attributes?: StringFilter | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmsPageByInternalLinkId?: CmsPageFilter | null;
  cmsPageByInternalLinkIdExists?: boolean | null;
  cmspluginPtrId?: IntFilter | null;
  externalLink?: StringFilter | null;
  fileLinkId?: IntFilter | null;
  filerFileByFileLinkId?: FilerFileFilter | null;
  filerFileByFileLinkIdExists?: boolean | null;
  internalLinkId?: IntFilter | null;
  mailto?: StringFilter | null;
  name?: StringFilter | null;
  not?: DjangocmsLinkLinkFilter | null;
  or?: ReadonlyArray<DjangocmsLinkLinkFilter> | null;
  phone?: StringFilter | null;
  target?: StringFilter | null;
  template?: StringFilter | null;
};
export type FilerFileFilter = {
  _fileSize?: BigIntFilter | null;
  and?: ReadonlyArray<FilerFileFilter> | null;
  authUserByOwnerId?: AuthUserFilter | null;
  authUserByOwnerIdExists?: boolean | null;
  description?: StringFilter | null;
  djangoContentTypeByPolymorphicCtypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByPolymorphicCtypeIdExists?: boolean | null;
  djangocmsFileFilesByFileSrcId?: FilerFileToManyDjangocmsFileFileFilter | null;
  djangocmsFileFilesByFileSrcIdExist?: boolean | null;
  djangocmsLinkLinksByFileLinkId?: FilerFileToManyDjangocmsLinkLinkFilter | null;
  djangocmsLinkLinksByFileLinkIdExist?: boolean | null;
  djangocmsVideoVideosourcesBySourceFileId?: FilerFileToManyDjangocmsVideoVideosourceFilter | null;
  djangocmsVideoVideosourcesBySourceFileIdExist?: boolean | null;
  djangocmsVideoVideotracksBySrcId?: FilerFileToManyDjangocmsVideoVideotrackFilter | null;
  djangocmsVideoVideotracksBySrcIdExist?: boolean | null;
  file?: StringFilter | null;
  filerClipboarditemsByFileId?: FilerFileToManyFilerClipboarditemFilter | null;
  filerClipboarditemsByFileIdExist?: boolean | null;
  filerFolderByFolderId?: FilerFolderFilter | null;
  filerFolderByFolderIdExists?: boolean | null;
  filerImageByFilePtrId?: FilerImageFilter | null;
  filerImageByFilePtrIdExists?: boolean | null;
  folderId?: IntFilter | null;
  hasAllMandatoryData?: BooleanFilter | null;
  isPublic?: BooleanFilter | null;
  mimeType?: StringFilter | null;
  modifiedAt?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: FilerFileFilter | null;
  or?: ReadonlyArray<FilerFileFilter> | null;
  originalFilename?: StringFilter | null;
  ownerId?: IntFilter | null;
  polymorphicCtypeId?: IntFilter | null;
  rowId?: IntFilter | null;
  sha1?: StringFilter | null;
  uploadedAt?: DatetimeFilter | null;
};
export type FilerFileToManyDjangocmsFileFileFilter = {
  every?: DjangocmsFileFileFilter | null;
  none?: DjangocmsFileFileFilter | null;
  some?: DjangocmsFileFileFilter | null;
};
export type DjangocmsFileFileFilter = {
  and?: ReadonlyArray<DjangocmsFileFileFilter> | null;
  attributes?: StringFilter | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmspluginPtrId?: IntFilter | null;
  fileName?: StringFilter | null;
  fileSrcId?: IntFilter | null;
  filerFileByFileSrcId?: FilerFileFilter | null;
  filerFileByFileSrcIdExists?: boolean | null;
  linkTarget?: StringFilter | null;
  linkTitle?: StringFilter | null;
  not?: DjangocmsFileFileFilter | null;
  or?: ReadonlyArray<DjangocmsFileFileFilter> | null;
  showFileSize?: BooleanFilter | null;
  template?: StringFilter | null;
};
export type FilerFileToManyDjangocmsLinkLinkFilter = {
  every?: DjangocmsLinkLinkFilter | null;
  none?: DjangocmsLinkLinkFilter | null;
  some?: DjangocmsLinkLinkFilter | null;
};
export type FilerFileToManyDjangocmsVideoVideosourceFilter = {
  every?: DjangocmsVideoVideosourceFilter | null;
  none?: DjangocmsVideoVideosourceFilter | null;
  some?: DjangocmsVideoVideosourceFilter | null;
};
export type DjangocmsVideoVideosourceFilter = {
  and?: ReadonlyArray<DjangocmsVideoVideosourceFilter> | null;
  attributes?: StringFilter | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmspluginPtrId?: IntFilter | null;
  filerFileBySourceFileId?: FilerFileFilter | null;
  filerFileBySourceFileIdExists?: boolean | null;
  not?: DjangocmsVideoVideosourceFilter | null;
  or?: ReadonlyArray<DjangocmsVideoVideosourceFilter> | null;
  sourceFileId?: IntFilter | null;
  textDescription?: StringFilter | null;
  textTitle?: StringFilter | null;
};
export type FilerFileToManyDjangocmsVideoVideotrackFilter = {
  every?: DjangocmsVideoVideotrackFilter | null;
  none?: DjangocmsVideoVideotrackFilter | null;
  some?: DjangocmsVideoVideotrackFilter | null;
};
export type DjangocmsVideoVideotrackFilter = {
  and?: ReadonlyArray<DjangocmsVideoVideotrackFilter> | null;
  attributes?: StringFilter | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmspluginPtrId?: IntFilter | null;
  filerFileBySrcId?: FilerFileFilter | null;
  filerFileBySrcIdExists?: boolean | null;
  kind?: StringFilter | null;
  label?: StringFilter | null;
  not?: DjangocmsVideoVideotrackFilter | null;
  or?: ReadonlyArray<DjangocmsVideoVideotrackFilter> | null;
  srcId?: IntFilter | null;
  srclang?: StringFilter | null;
};
export type FilerFileToManyFilerClipboarditemFilter = {
  every?: FilerClipboarditemFilter | null;
  none?: FilerClipboarditemFilter | null;
  some?: FilerClipboarditemFilter | null;
};
export type FilerClipboarditemFilter = {
  and?: ReadonlyArray<FilerClipboarditemFilter> | null;
  clipboardId?: IntFilter | null;
  fileId?: IntFilter | null;
  filerClipboardByClipboardId?: FilerClipboardFilter | null;
  filerFileByFileId?: FilerFileFilter | null;
  not?: FilerClipboarditemFilter | null;
  or?: ReadonlyArray<FilerClipboarditemFilter> | null;
  rowId?: IntFilter | null;
};
export type FilerClipboardFilter = {
  and?: ReadonlyArray<FilerClipboardFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  filerClipboarditemsByClipboardId?: FilerClipboardToManyFilerClipboarditemFilter | null;
  filerClipboarditemsByClipboardIdExist?: boolean | null;
  not?: FilerClipboardFilter | null;
  or?: ReadonlyArray<FilerClipboardFilter> | null;
  rowId?: IntFilter | null;
  userId?: IntFilter | null;
};
export type FilerClipboardToManyFilerClipboarditemFilter = {
  every?: FilerClipboarditemFilter | null;
  none?: FilerClipboarditemFilter | null;
  some?: FilerClipboarditemFilter | null;
};
export type FilerFolderFilter = {
  and?: ReadonlyArray<FilerFolderFilter> | null;
  authUserByOwnerId?: AuthUserFilter | null;
  authUserByOwnerIdExists?: boolean | null;
  createdAt?: DatetimeFilter | null;
  djangocmsFileFoldersByFolderSrcId?: FilerFolderToManyDjangocmsFileFolderFilter | null;
  djangocmsFileFoldersByFolderSrcIdExist?: boolean | null;
  filerFilesByFolderId?: FilerFolderToManyFilerFileFilter | null;
  filerFilesByFolderIdExist?: boolean | null;
  filerFolderByParentId?: FilerFolderFilter | null;
  filerFolderByParentIdExists?: boolean | null;
  filerFolderpermissionsByFolderId?: FilerFolderToManyFilerFolderpermissionFilter | null;
  filerFolderpermissionsByFolderIdExist?: boolean | null;
  filerFoldersByParentId?: FilerFolderToManyFilerFolderFilter | null;
  filerFoldersByParentIdExist?: boolean | null;
  level?: IntFilter | null;
  lft?: IntFilter | null;
  modifiedAt?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: FilerFolderFilter | null;
  or?: ReadonlyArray<FilerFolderFilter> | null;
  ownerId?: IntFilter | null;
  parentId?: IntFilter | null;
  rght?: IntFilter | null;
  rowId?: IntFilter | null;
  treeId?: IntFilter | null;
  uploadedAt?: DatetimeFilter | null;
};
export type FilerFolderToManyDjangocmsFileFolderFilter = {
  every?: DjangocmsFileFolderFilter | null;
  none?: DjangocmsFileFolderFilter | null;
  some?: DjangocmsFileFolderFilter | null;
};
export type DjangocmsFileFolderFilter = {
  and?: ReadonlyArray<DjangocmsFileFolderFilter> | null;
  attributes?: StringFilter | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmspluginPtrId?: IntFilter | null;
  filerFolderByFolderSrcId?: FilerFolderFilter | null;
  filerFolderByFolderSrcIdExists?: boolean | null;
  folderSrcId?: IntFilter | null;
  linkTarget?: StringFilter | null;
  not?: DjangocmsFileFolderFilter | null;
  or?: ReadonlyArray<DjangocmsFileFolderFilter> | null;
  showFileSize?: BooleanFilter | null;
  template?: StringFilter | null;
};
export type FilerFolderToManyFilerFileFilter = {
  every?: FilerFileFilter | null;
  none?: FilerFileFilter | null;
  some?: FilerFileFilter | null;
};
export type FilerFolderToManyFilerFolderFilter = {
  every?: FilerFolderFilter | null;
  none?: FilerFolderFilter | null;
  some?: FilerFolderFilter | null;
};
export type FilerFolderToManyFilerFolderpermissionFilter = {
  every?: FilerFolderpermissionFilter | null;
  none?: FilerFolderpermissionFilter | null;
  some?: FilerFolderpermissionFilter | null;
};
export type FilerFolderpermissionFilter = {
  and?: ReadonlyArray<FilerFolderpermissionFilter> | null;
  authGroupByGroupId?: AuthGroupFilter | null;
  authGroupByGroupIdExists?: boolean | null;
  authUserByUserId?: AuthUserFilter | null;
  authUserByUserIdExists?: boolean | null;
  canAddChildren?: IntFilter | null;
  canEdit?: IntFilter | null;
  canRead?: IntFilter | null;
  everybody?: BooleanFilter | null;
  filerFolderByFolderId?: FilerFolderFilter | null;
  filerFolderByFolderIdExists?: boolean | null;
  folderId?: IntFilter | null;
  groupId?: IntFilter | null;
  not?: FilerFolderpermissionFilter | null;
  or?: ReadonlyArray<FilerFolderpermissionFilter> | null;
  rowId?: IntFilter | null;
  type?: IntFilter | null;
  userId?: IntFilter | null;
};
export type CmsPageToManyDjangocmsPicturePictureFilter = {
  every?: DjangocmsPicturePictureFilter | null;
  none?: DjangocmsPicturePictureFilter | null;
  some?: DjangocmsPicturePictureFilter | null;
};
export type DjangocmsPicturePictureFilter = {
  alignment?: StringFilter | null;
  and?: ReadonlyArray<DjangocmsPicturePictureFilter> | null;
  attributes?: StringFilter | null;
  captionText?: StringFilter | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmsPageByLinkPageId?: CmsPageFilter | null;
  cmsPageByLinkPageIdExists?: boolean | null;
  cmspluginPtrId?: IntFilter | null;
  externalPicture?: StringFilter | null;
  filerImageByPictureId?: FilerImageFilter | null;
  filerImageByPictureIdExists?: boolean | null;
  filerThumbnailoptionByThumbnailOptionsId?: FilerThumbnailoptionFilter | null;
  filerThumbnailoptionByThumbnailOptionsIdExists?: boolean | null;
  height?: IntFilter | null;
  linkAttributes?: StringFilter | null;
  linkPageId?: IntFilter | null;
  linkTarget?: StringFilter | null;
  linkUrl?: StringFilter | null;
  not?: DjangocmsPicturePictureFilter | null;
  or?: ReadonlyArray<DjangocmsPicturePictureFilter> | null;
  pictureId?: IntFilter | null;
  template?: StringFilter | null;
  thumbnailOptionsId?: IntFilter | null;
  useAutomaticScaling?: BooleanFilter | null;
  useCrop?: BooleanFilter | null;
  useNoCropping?: BooleanFilter | null;
  useResponsiveImage?: StringFilter | null;
  useUpscale?: BooleanFilter | null;
  width?: IntFilter | null;
};
export type FilerThumbnailoptionFilter = {
  and?: ReadonlyArray<FilerThumbnailoptionFilter> | null;
  crop?: BooleanFilter | null;
  djangocmsPicturePicturesByThumbnailOptionsId?: FilerThumbnailoptionToManyDjangocmsPicturePictureFilter | null;
  djangocmsPicturePicturesByThumbnailOptionsIdExist?: boolean | null;
  height?: IntFilter | null;
  name?: StringFilter | null;
  not?: FilerThumbnailoptionFilter | null;
  or?: ReadonlyArray<FilerThumbnailoptionFilter> | null;
  rowId?: IntFilter | null;
  upscale?: BooleanFilter | null;
  width?: IntFilter | null;
};
export type FilerThumbnailoptionToManyDjangocmsPicturePictureFilter = {
  every?: DjangocmsPicturePictureFilter | null;
  none?: DjangocmsPicturePictureFilter | null;
  some?: DjangocmsPicturePictureFilter | null;
};
export type CmsTreenodeFilter = {
  and?: ReadonlyArray<CmsTreenodeFilter> | null;
  cmsPagesByCmsPageNodeId?: CmsTreenodeToManyCmsPageFilter | null;
  cmsPagesByCmsPageNodeIdExist?: boolean | null;
  cmsTreenodeByParentId?: CmsTreenodeFilter | null;
  cmsTreenodeByParentIdExists?: boolean | null;
  cmsTreenodesByParentId?: CmsTreenodeToManyCmsTreenodeFilter | null;
  cmsTreenodesByParentIdExist?: boolean | null;
  depth?: IntFilter | null;
  djangoSiteBySiteId?: DjangoSiteFilter | null;
  not?: CmsTreenodeFilter | null;
  numchild?: IntFilter | null;
  or?: ReadonlyArray<CmsTreenodeFilter> | null;
  parentId?: IntFilter | null;
  path?: StringFilter | null;
  rowId?: IntFilter | null;
  siteId?: IntFilter | null;
};
export type CmsTreenodeToManyCmsPageFilter = {
  every?: CmsPageFilter | null;
  none?: CmsPageFilter | null;
  some?: CmsPageFilter | null;
};
export type CmsTreenodeToManyCmsTreenodeFilter = {
  every?: CmsTreenodeFilter | null;
  none?: CmsTreenodeFilter | null;
  some?: CmsTreenodeFilter | null;
};
export type DjangoSiteFilter = {
  and?: ReadonlyArray<DjangoSiteFilter> | null;
  cmsGlobalpagepermissionSitesBySiteId?: DjangoSiteToManyCmsGlobalpagepermissionSiteFilter | null;
  cmsGlobalpagepermissionSitesBySiteIdExist?: boolean | null;
  cmsStaticplaceholdersBySiteId?: DjangoSiteToManyCmsStaticplaceholderFilter | null;
  cmsStaticplaceholdersBySiteIdExist?: boolean | null;
  cmsTreenodesBySiteId?: DjangoSiteToManyCmsTreenodeFilter | null;
  cmsTreenodesBySiteIdExist?: boolean | null;
  domain?: StringFilter | null;
  name?: StringFilter | null;
  not?: DjangoSiteFilter | null;
  or?: ReadonlyArray<DjangoSiteFilter> | null;
  rowId?: IntFilter | null;
};
export type DjangoSiteToManyCmsGlobalpagepermissionSiteFilter = {
  every?: CmsGlobalpagepermissionSiteFilter | null;
  none?: CmsGlobalpagepermissionSiteFilter | null;
  some?: CmsGlobalpagepermissionSiteFilter | null;
};
export type CmsGlobalpagepermissionSiteFilter = {
  and?: ReadonlyArray<CmsGlobalpagepermissionSiteFilter> | null;
  cmsGlobalpagepermissionByGlobalpagepermissionId?: CmsGlobalpagepermissionFilter | null;
  djangoSiteBySiteId?: DjangoSiteFilter | null;
  globalpagepermissionId?: IntFilter | null;
  not?: CmsGlobalpagepermissionSiteFilter | null;
  or?: ReadonlyArray<CmsGlobalpagepermissionSiteFilter> | null;
  rowId?: IntFilter | null;
  siteId?: IntFilter | null;
};
export type CmsGlobalpagepermissionFilter = {
  and?: ReadonlyArray<CmsGlobalpagepermissionFilter> | null;
  authGroupByGroupId?: AuthGroupFilter | null;
  authGroupByGroupIdExists?: boolean | null;
  authUserByUserId?: AuthUserFilter | null;
  authUserByUserIdExists?: boolean | null;
  canAdd?: BooleanFilter | null;
  canChange?: BooleanFilter | null;
  canChangeAdvancedSettings?: BooleanFilter | null;
  canChangePermissions?: BooleanFilter | null;
  canDelete?: BooleanFilter | null;
  canMovePage?: BooleanFilter | null;
  canPublish?: BooleanFilter | null;
  canRecoverPage?: BooleanFilter | null;
  canView?: BooleanFilter | null;
  cmsGlobalpagepermissionSitesByGlobalpagepermissionId?: CmsGlobalpagepermissionToManyCmsGlobalpagepermissionSiteFilter | null;
  cmsGlobalpagepermissionSitesByGlobalpagepermissionIdExist?: boolean | null;
  groupId?: IntFilter | null;
  not?: CmsGlobalpagepermissionFilter | null;
  or?: ReadonlyArray<CmsGlobalpagepermissionFilter> | null;
  rowId?: IntFilter | null;
  userId?: IntFilter | null;
};
export type CmsGlobalpagepermissionToManyCmsGlobalpagepermissionSiteFilter = {
  every?: CmsGlobalpagepermissionSiteFilter | null;
  none?: CmsGlobalpagepermissionSiteFilter | null;
  some?: CmsGlobalpagepermissionSiteFilter | null;
};
export type DjangoSiteToManyCmsStaticplaceholderFilter = {
  every?: CmsStaticplaceholderFilter | null;
  none?: CmsStaticplaceholderFilter | null;
  some?: CmsStaticplaceholderFilter | null;
};
export type CmsStaticplaceholderFilter = {
  and?: ReadonlyArray<CmsStaticplaceholderFilter> | null;
  cmsPlaceholderByDraftId?: CmsPlaceholderFilter | null;
  cmsPlaceholderByDraftIdExists?: boolean | null;
  cmsPlaceholderByPublicId?: CmsPlaceholderFilter | null;
  cmsPlaceholderByPublicIdExists?: boolean | null;
  code?: StringFilter | null;
  creationMethod?: StringFilter | null;
  dirty?: BooleanFilter | null;
  djangoSiteBySiteId?: DjangoSiteFilter | null;
  djangoSiteBySiteIdExists?: boolean | null;
  draftId?: IntFilter | null;
  name?: StringFilter | null;
  not?: CmsStaticplaceholderFilter | null;
  or?: ReadonlyArray<CmsStaticplaceholderFilter> | null;
  publicId?: IntFilter | null;
  rowId?: IntFilter | null;
  siteId?: IntFilter | null;
};
export type DjangoSiteToManyCmsTreenodeFilter = {
  every?: CmsTreenodeFilter | null;
  none?: CmsTreenodeFilter | null;
  some?: CmsTreenodeFilter | null;
};
export type CmsPlaceholderToManyCmsPlaceholderreferenceFilter = {
  every?: CmsPlaceholderreferenceFilter | null;
  none?: CmsPlaceholderreferenceFilter | null;
  some?: CmsPlaceholderreferenceFilter | null;
};
export type CmsPlaceholderreferenceFilter = {
  and?: ReadonlyArray<CmsPlaceholderreferenceFilter> | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmsPlaceholderByPlaceholderRefId?: CmsPlaceholderFilter | null;
  cmsPlaceholderByPlaceholderRefIdExists?: boolean | null;
  cmspluginPtrId?: IntFilter | null;
  name?: StringFilter | null;
  not?: CmsPlaceholderreferenceFilter | null;
  or?: ReadonlyArray<CmsPlaceholderreferenceFilter> | null;
  placeholderRefId?: IntFilter | null;
};
export type CmsPlaceholderToManyCmsStaticplaceholderFilter = {
  every?: CmsStaticplaceholderFilter | null;
  none?: CmsStaticplaceholderFilter | null;
  some?: CmsStaticplaceholderFilter | null;
};
export type CmsPlaceholderToManyCmsUsersettingFilter = {
  every?: CmsUsersettingFilter | null;
  none?: CmsUsersettingFilter | null;
  some?: CmsUsersettingFilter | null;
};
export type CmsUsersettingFilter = {
  and?: ReadonlyArray<CmsUsersettingFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  clipboardId?: IntFilter | null;
  cmsPlaceholderByClipboardId?: CmsPlaceholderFilter | null;
  cmsPlaceholderByClipboardIdExists?: boolean | null;
  language?: StringFilter | null;
  not?: CmsUsersettingFilter | null;
  or?: ReadonlyArray<CmsUsersettingFilter> | null;
  rowId?: IntFilter | null;
  userId?: IntFilter | null;
};
export type CmsCmspluginToManyCmsAliaspluginmodelFilter = {
  every?: CmsAliaspluginmodelFilter | null;
  none?: CmsAliaspluginmodelFilter | null;
  some?: CmsAliaspluginmodelFilter | null;
};
export type CmsCmspluginToManyCmsCmspluginFilter = {
  every?: CmsCmspluginFilter | null;
  none?: CmsCmspluginFilter | null;
  some?: CmsCmspluginFilter | null;
};
export type DjangocmsGooglemapGooglemapFilter = {
  and?: ReadonlyArray<DjangocmsGooglemapGooglemapFilter> | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmspluginPtrId?: IntFilter | null;
  doubleClickZoom?: BooleanFilter | null;
  draggable?: BooleanFilter | null;
  fullscreenControl?: BooleanFilter | null;
  height?: StringFilter | null;
  keyboardShortcuts?: BooleanFilter | null;
  lat?: FloatFilter | null;
  lng?: FloatFilter | null;
  mapTypeControl?: StringFilter | null;
  not?: DjangocmsGooglemapGooglemapFilter | null;
  or?: ReadonlyArray<DjangocmsGooglemapGooglemapFilter> | null;
  panControl?: BooleanFilter | null;
  rotateControl?: BooleanFilter | null;
  scaleControl?: BooleanFilter | null;
  scrollwheel?: BooleanFilter | null;
  streetViewControl?: BooleanFilter | null;
  style?: StringFilter | null;
  template?: StringFilter | null;
  title?: StringFilter | null;
  width?: StringFilter | null;
  zoom?: IntFilter | null;
  zoomControl?: BooleanFilter | null;
};
export type DjangocmsGooglemapGooglemaprouteFilter = {
  and?: ReadonlyArray<DjangocmsGooglemapGooglemaprouteFilter> | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmspluginPtrId?: IntFilter | null;
  destination?: StringFilter | null;
  not?: DjangocmsGooglemapGooglemaprouteFilter | null;
  or?: ReadonlyArray<DjangocmsGooglemapGooglemaprouteFilter> | null;
  origin?: StringFilter | null;
  title?: StringFilter | null;
  travelMode?: StringFilter | null;
};
export type DjangocmsSnippetSnippetptrFilter = {
  and?: ReadonlyArray<DjangocmsSnippetSnippetptrFilter> | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmspluginPtrId?: IntFilter | null;
  djangocmsSnippetSnippetBySnippetId?: DjangocmsSnippetSnippetFilter | null;
  not?: DjangocmsSnippetSnippetptrFilter | null;
  or?: ReadonlyArray<DjangocmsSnippetSnippetptrFilter> | null;
  snippetId?: IntFilter | null;
};
export type DjangocmsSnippetSnippetFilter = {
  and?: ReadonlyArray<DjangocmsSnippetSnippetFilter> | null;
  djangocmsSnippetSnippetptrsBySnippetId?: DjangocmsSnippetSnippetToManyDjangocmsSnippetSnippetptrFilter | null;
  djangocmsSnippetSnippetptrsBySnippetIdExist?: boolean | null;
  html?: StringFilter | null;
  name?: StringFilter | null;
  not?: DjangocmsSnippetSnippetFilter | null;
  or?: ReadonlyArray<DjangocmsSnippetSnippetFilter> | null;
  rowId?: IntFilter | null;
  slug?: StringFilter | null;
  template?: StringFilter | null;
};
export type DjangocmsSnippetSnippetToManyDjangocmsSnippetSnippetptrFilter = {
  every?: DjangocmsSnippetSnippetptrFilter | null;
  none?: DjangocmsSnippetSnippetptrFilter | null;
  some?: DjangocmsSnippetSnippetptrFilter | null;
};
export type DjangocmsStyleStyleFilter = {
  additionalClasses?: StringFilter | null;
  and?: ReadonlyArray<DjangocmsStyleStyleFilter> | null;
  attributes?: StringFilter | null;
  className?: StringFilter | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmspluginPtrId?: IntFilter | null;
  idName?: StringFilter | null;
  label?: StringFilter | null;
  marginBottom?: IntFilter | null;
  marginLeft?: IntFilter | null;
  marginRight?: IntFilter | null;
  marginTop?: IntFilter | null;
  not?: DjangocmsStyleStyleFilter | null;
  or?: ReadonlyArray<DjangocmsStyleStyleFilter> | null;
  paddingBottom?: IntFilter | null;
  paddingLeft?: IntFilter | null;
  paddingRight?: IntFilter | null;
  paddingTop?: IntFilter | null;
  tagType?: StringFilter | null;
  template?: StringFilter | null;
};
export type DjangocmsTextCkeditorTextFilter = {
  and?: ReadonlyArray<DjangocmsTextCkeditorTextFilter> | null;
  body?: StringFilter | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmspluginPtrId?: IntFilter | null;
  not?: DjangocmsTextCkeditorTextFilter | null;
  or?: ReadonlyArray<DjangocmsTextCkeditorTextFilter> | null;
};
export type DjangocmsVideoVideoplayerFilter = {
  and?: ReadonlyArray<DjangocmsVideoVideoplayerFilter> | null;
  attributes?: StringFilter | null;
  cmsCmspluginByCmspluginPtrId?: CmsCmspluginFilter | null;
  cmspluginPtrId?: IntFilter | null;
  embedLink?: StringFilter | null;
  filerImageByPosterId?: FilerImageFilter | null;
  filerImageByPosterIdExists?: boolean | null;
  label?: StringFilter | null;
  not?: DjangocmsVideoVideoplayerFilter | null;
  or?: ReadonlyArray<DjangocmsVideoVideoplayerFilter> | null;
  parameters?: StringFilter | null;
  posterId?: IntFilter | null;
  template?: StringFilter | null;
};
export type FilerImageToManyDjangocmsPicturePictureFilter = {
  every?: DjangocmsPicturePictureFilter | null;
  none?: DjangocmsPicturePictureFilter | null;
  some?: DjangocmsPicturePictureFilter | null;
};
export type FilerImageToManyDjangocmsVideoVideoplayerFilter = {
  every?: DjangocmsVideoVideoplayerFilter | null;
  none?: DjangocmsVideoVideoplayerFilter | null;
  some?: DjangocmsVideoVideoplayerFilter | null;
};
export type FilerImageToManySecuritiesAssetimageFilter = {
  every?: SecuritiesAssetimageFilter | null;
  none?: SecuritiesAssetimageFilter | null;
  some?: SecuritiesAssetimageFilter | null;
};
export type SecuritiesAssetimageFilter = {
  and?: ReadonlyArray<SecuritiesAssetimageFilter> | null;
  assetId?: IntFilter | null;
  filerImageByImageId?: FilerImageFilter | null;
  imageId?: IntFilter | null;
  not?: SecuritiesAssetimageFilter | null;
  or?: ReadonlyArray<SecuritiesAssetimageFilter> | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  sortTerm?: StringFilter | null;
};
export type SecuritiesNetworkassetToManySecuritiesPockettransferFilter = {
  every?: SecuritiesPockettransferFilter | null;
  none?: SecuritiesPockettransferFilter | null;
  some?: SecuritiesPockettransferFilter | null;
};
export type SecuritiesSecurityassetFilter = {
  and?: ReadonlyArray<SecuritiesSecurityassetFilter> | null;
  networkassetPtrId?: IntFilter | null;
  not?: SecuritiesSecurityassetFilter | null;
  or?: ReadonlyArray<SecuritiesSecurityassetFilter> | null;
  securitiesEquityassetBySecurityassetPtrId?: SecuritiesEquityassetFilter | null;
  securitiesEquityassetBySecurityassetPtrIdExists?: boolean | null;
  securitiesNetworkassetByNetworkassetPtrId?: SecuritiesNetworkassetFilter | null;
  securitiesNoteassetBySecurityassetPtrId?: SecuritiesNoteassetFilter | null;
  securitiesNoteassetBySecurityassetPtrIdExists?: boolean | null;
  securitiesOptionassetBySecurityassetPtrId?: SecuritiesOptionassetFilter | null;
  securitiesOptionassetBySecurityassetPtrIdExists?: boolean | null;
};
export type SecuritiesEquityassetFilter = {
  and?: ReadonlyArray<SecuritiesEquityassetFilter> | null;
  not?: SecuritiesEquityassetFilter | null;
  or?: ReadonlyArray<SecuritiesEquityassetFilter> | null;
  securitiesSecurityassetBySecurityassetPtrId?: SecuritiesSecurityassetFilter | null;
  securityassetPtrId?: IntFilter | null;
};
export type SecuritiesNoteassetFilter = {
  and?: ReadonlyArray<SecuritiesNoteassetFilter> | null;
  coupon?: BigFloatFilter | null;
  maturityDate?: DateFilter | null;
  not?: SecuritiesNoteassetFilter | null;
  or?: ReadonlyArray<SecuritiesNoteassetFilter> | null;
  securitiesSecurityassetBySecurityassetPtrId?: SecuritiesSecurityassetFilter | null;
  securityassetPtrId?: IntFilter | null;
  term?: StringFilter | null;
};
export type SecuritiesOptionassetFilter = {
  and?: ReadonlyArray<SecuritiesOptionassetFilter> | null;
  not?: SecuritiesOptionassetFilter | null;
  or?: ReadonlyArray<SecuritiesOptionassetFilter> | null;
  securitiesSecurityassetBySecurityassetPtrId?: SecuritiesSecurityassetFilter | null;
  securityassetPtrId?: IntFilter | null;
};
export type SecuritiesNetworkassetToManySecuritiesTransferFilter = {
  every?: SecuritiesTransferFilter | null;
  none?: SecuritiesTransferFilter | null;
  some?: SecuritiesTransferFilter | null;
};
export type SecuritiesNetworkassetToManyTransfersSendFilter = {
  every?: TransfersSendFilter | null;
  none?: TransfersSendFilter | null;
  some?: TransfersSendFilter | null;
};
export type TransfersSendFilter = {
  and?: ReadonlyArray<TransfersSendFilter> | null;
  assetId?: IntFilter | null;
  not?: TransfersSendFilter | null;
  note?: StringFilter | null;
  or?: ReadonlyArray<TransfersSendFilter> | null;
  quantity?: BigIntFilter | null;
  receiverId?: IntFilter | null;
  securitiesNetworkassetByAssetId?: SecuritiesNetworkassetFilter | null;
  securitiesProfileByReceiverId?: SecuritiesProfileFilter | null;
  securitiesProfileByReceiverIdExists?: boolean | null;
  securitiesProfileBySenderId?: SecuritiesProfileFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  senderId?: IntFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesProposalFilter = {
  acceptedAt?: DatetimeFilter | null;
  and?: ReadonlyArray<SecuritiesProposalFilter> | null;
  bid?: BigFloatFilter | null;
  bidUnit?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  not?: SecuritiesProposalFilter | null;
  or?: ReadonlyArray<SecuritiesProposalFilter> | null;
  rfpId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesInvoicesByProposalId?: SecuritiesProposalToManySecuritiesInvoiceFilter | null;
  securitiesInvoicesByProposalIdExist?: boolean | null;
  securitiesPayeesplitsByProposalId?: SecuritiesProposalToManySecuritiesPayeesplitFilter | null;
  securitiesPayeesplitsByProposalIdExist?: boolean | null;
  securitiesProfileByTalentId?: SecuritiesProfileFilter | null;
  securitiesProfileByTalentIdExists?: boolean | null;
  securitiesRequestforproposalByRfpId?: SecuritiesRequestforproposalFilter | null;
  securitiesRequestforproposalByRfpIdExists?: boolean | null;
  sow?: StringFilter | null;
  status?: StringFilter | null;
  statusReason?: StringFilter | null;
  talentId?: IntFilter | null;
};
export type SecuritiesProposalToManySecuritiesInvoiceFilter = {
  every?: SecuritiesInvoiceFilter | null;
  none?: SecuritiesInvoiceFilter | null;
  some?: SecuritiesInvoiceFilter | null;
};
export type SecuritiesProposalToManySecuritiesPayeesplitFilter = {
  every?: SecuritiesPayeesplitFilter | null;
  none?: SecuritiesPayeesplitFilter | null;
  some?: SecuritiesPayeesplitFilter | null;
};
export type SecuritiesPayeesplitFilter = {
  and?: ReadonlyArray<SecuritiesPayeesplitFilter> | null;
  not?: SecuritiesPayeesplitFilter | null;
  or?: ReadonlyArray<SecuritiesPayeesplitFilter> | null;
  payeeId?: IntFilter | null;
  proposalId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesProfileByPayeeId?: SecuritiesProfileFilter | null;
  securitiesProposalByProposalId?: SecuritiesProposalFilter | null;
  share?: BigFloatFilter | null;
};
export type SecuritiesRequestforproposalFilter = {
  and?: ReadonlyArray<SecuritiesRequestforproposalFilter> | null;
  citiesLightCountryByPayerCountryId?: CitiesLightCountryFilter | null;
  citiesLightCountryByPayerCountryIdExists?: boolean | null;
  clientId?: IntFilter | null;
  createdAt?: DatetimeFilter | null;
  description?: StringFilter | null;
  invoice?: StringFilter | null;
  maxBudget?: BigFloatFilter | null;
  not?: SecuritiesRequestforproposalFilter | null;
  or?: ReadonlyArray<SecuritiesRequestforproposalFilter> | null;
  payerCountryId?: IntFilter | null;
  payerEmail?: StringFilter | null;
  payerName?: StringFilter | null;
  payerPhone?: StringFilter | null;
  rowId?: IntFilter | null;
  securitiesProfileByClientId?: SecuritiesProfileFilter | null;
  securitiesProposalsByRfpId?: SecuritiesRequestforproposalToManySecuritiesProposalFilter | null;
  securitiesProposalsByRfpIdExist?: boolean | null;
  status?: StringFilter | null;
  title?: StringFilter | null;
};
export type SecuritiesRequestforproposalToManySecuritiesProposalFilter = {
  every?: SecuritiesProposalFilter | null;
  none?: SecuritiesProposalFilter | null;
  some?: SecuritiesProposalFilter | null;
};
export type CitiesLightCountryFilter = {
  alternateNames?: StringFilter | null;
  and?: ReadonlyArray<CitiesLightCountryFilter> | null;
  citiesLightCitiesByCountryId?: CitiesLightCountryToManyCitiesLightCityFilter | null;
  citiesLightCitiesByCountryIdExist?: boolean | null;
  citiesLightRegionsByCountryId?: CitiesLightCountryToManyCitiesLightRegionFilter | null;
  citiesLightRegionsByCountryIdExist?: boolean | null;
  citiesLightSubregionsByCountryId?: CitiesLightCountryToManyCitiesLightSubregionFilter | null;
  citiesLightSubregionsByCountryIdExist?: boolean | null;
  code2?: StringFilter | null;
  code3?: StringFilter | null;
  continent?: StringFilter | null;
  geonameId?: IntFilter | null;
  name?: StringFilter | null;
  nameAscii?: StringFilter | null;
  not?: CitiesLightCountryFilter | null;
  or?: ReadonlyArray<CitiesLightCountryFilter> | null;
  phone?: StringFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetsByDomicileId?: CitiesLightCountryToManySecuritiesAssetFilter | null;
  securitiesAssetsByDomicileIdExist?: boolean | null;
  securitiesEmailcampaignsByCountryId?: CitiesLightCountryToManySecuritiesEmailcampaignFilter | null;
  securitiesEmailcampaignsByCountryIdExist?: boolean | null;
  securitiesInvoicesByPayerCountryId?: CitiesLightCountryToManySecuritiesInvoiceFilter | null;
  securitiesInvoicesByPayerCountryIdExist?: boolean | null;
  securitiesOriginationfeesByCountryId?: CitiesLightCountryToManySecuritiesOriginationfeeFilter | null;
  securitiesOriginationfeesByCountryIdExist?: boolean | null;
  securitiesProfilesByCountryId?: CitiesLightCountryToManySecuritiesProfileFilter | null;
  securitiesProfilesByCountryIdExist?: boolean | null;
  securitiesRegionalmanagersByCountryId?: CitiesLightCountryToManySecuritiesRegionalmanagerFilter | null;
  securitiesRegionalmanagersByCountryIdExist?: boolean | null;
  securitiesRequestforproposalsByPayerCountryId?: CitiesLightCountryToManySecuritiesRequestforproposalFilter | null;
  securitiesRequestforproposalsByPayerCountryIdExist?: boolean | null;
  slug?: StringFilter | null;
  tld?: StringFilter | null;
};
export type CitiesLightCountryToManySecuritiesInvoiceFilter = {
  every?: SecuritiesInvoiceFilter | null;
  none?: SecuritiesInvoiceFilter | null;
  some?: SecuritiesInvoiceFilter | null;
};
export type CitiesLightCountryToManySecuritiesRequestforproposalFilter = {
  every?: SecuritiesRequestforproposalFilter | null;
  none?: SecuritiesRequestforproposalFilter | null;
  some?: SecuritiesRequestforproposalFilter | null;
};
export type CitiesLightCountryToManySecuritiesAssetFilter = {
  every?: SecuritiesAssetFilter | null;
  none?: SecuritiesAssetFilter | null;
  some?: SecuritiesAssetFilter | null;
};
export type CitiesLightCountryToManyCitiesLightCityFilter = {
  every?: CitiesLightCityFilter | null;
  none?: CitiesLightCityFilter | null;
  some?: CitiesLightCityFilter | null;
};
export type CitiesLightCityFilter = {
  alternateNames?: StringFilter | null;
  and?: ReadonlyArray<CitiesLightCityFilter> | null;
  citiesLightCountryByCountryId?: CitiesLightCountryFilter | null;
  citiesLightRegionByRegionId?: CitiesLightRegionFilter | null;
  citiesLightRegionByRegionIdExists?: boolean | null;
  citiesLightSubregionBySubregionId?: CitiesLightSubregionFilter | null;
  citiesLightSubregionBySubregionIdExists?: boolean | null;
  countryId?: IntFilter | null;
  displayName?: StringFilter | null;
  featureCode?: StringFilter | null;
  geonameId?: IntFilter | null;
  latitude?: BigFloatFilter | null;
  longitude?: BigFloatFilter | null;
  name?: StringFilter | null;
  nameAscii?: StringFilter | null;
  not?: CitiesLightCityFilter | null;
  or?: ReadonlyArray<CitiesLightCityFilter> | null;
  population?: BigIntFilter | null;
  regionId?: IntFilter | null;
  rowId?: IntFilter | null;
  searchNames?: StringFilter | null;
  slug?: StringFilter | null;
  subregionId?: IntFilter | null;
  timezone?: StringFilter | null;
};
export type CitiesLightRegionFilter = {
  alternateNames?: StringFilter | null;
  and?: ReadonlyArray<CitiesLightRegionFilter> | null;
  citiesLightCitiesByRegionId?: CitiesLightRegionToManyCitiesLightCityFilter | null;
  citiesLightCitiesByRegionIdExist?: boolean | null;
  citiesLightCountryByCountryId?: CitiesLightCountryFilter | null;
  citiesLightSubregionsByRegionId?: CitiesLightRegionToManyCitiesLightSubregionFilter | null;
  citiesLightSubregionsByRegionIdExist?: boolean | null;
  countryId?: IntFilter | null;
  displayName?: StringFilter | null;
  geonameCode?: StringFilter | null;
  geonameId?: IntFilter | null;
  name?: StringFilter | null;
  nameAscii?: StringFilter | null;
  not?: CitiesLightRegionFilter | null;
  or?: ReadonlyArray<CitiesLightRegionFilter> | null;
  rowId?: IntFilter | null;
  securitiesProfilesByPrevState?: CitiesLightRegionToManySecuritiesProfileFilter | null;
  securitiesProfilesByPrevStateExist?: boolean | null;
  securitiesProfilesByState?: CitiesLightRegionToManySecuritiesProfileFilter | null;
  securitiesProfilesByStateExist?: boolean | null;
  securitiesProfilesByStateOfFormation?: CitiesLightRegionToManySecuritiesProfileFilter | null;
  securitiesProfilesByStateOfFormationExist?: boolean | null;
  securitiesProfilesByTaxFilingState?: CitiesLightRegionToManySecuritiesProfileFilter | null;
  securitiesProfilesByTaxFilingStateExist?: boolean | null;
  securitiesProfilesByVoterState?: CitiesLightRegionToManySecuritiesProfileFilter | null;
  securitiesProfilesByVoterStateExist?: boolean | null;
  slug?: StringFilter | null;
};
export type CitiesLightRegionToManyCitiesLightCityFilter = {
  every?: CitiesLightCityFilter | null;
  none?: CitiesLightCityFilter | null;
  some?: CitiesLightCityFilter | null;
};
export type CitiesLightRegionToManyCitiesLightSubregionFilter = {
  every?: CitiesLightSubregionFilter | null;
  none?: CitiesLightSubregionFilter | null;
  some?: CitiesLightSubregionFilter | null;
};
export type CitiesLightSubregionFilter = {
  alternateNames?: StringFilter | null;
  and?: ReadonlyArray<CitiesLightSubregionFilter> | null;
  citiesLightCitiesBySubregionId?: CitiesLightSubregionToManyCitiesLightCityFilter | null;
  citiesLightCitiesBySubregionIdExist?: boolean | null;
  citiesLightCountryByCountryId?: CitiesLightCountryFilter | null;
  citiesLightRegionByRegionId?: CitiesLightRegionFilter | null;
  citiesLightRegionByRegionIdExists?: boolean | null;
  countryId?: IntFilter | null;
  displayName?: StringFilter | null;
  geonameCode?: StringFilter | null;
  geonameId?: IntFilter | null;
  name?: StringFilter | null;
  nameAscii?: StringFilter | null;
  not?: CitiesLightSubregionFilter | null;
  or?: ReadonlyArray<CitiesLightSubregionFilter> | null;
  regionId?: IntFilter | null;
  rowId?: IntFilter | null;
  slug?: StringFilter | null;
};
export type CitiesLightSubregionToManyCitiesLightCityFilter = {
  every?: CitiesLightCityFilter | null;
  none?: CitiesLightCityFilter | null;
  some?: CitiesLightCityFilter | null;
};
export type CitiesLightRegionToManySecuritiesProfileFilter = {
  every?: SecuritiesProfileFilter | null;
  none?: SecuritiesProfileFilter | null;
  some?: SecuritiesProfileFilter | null;
};
export type CitiesLightCountryToManyCitiesLightRegionFilter = {
  every?: CitiesLightRegionFilter | null;
  none?: CitiesLightRegionFilter | null;
  some?: CitiesLightRegionFilter | null;
};
export type CitiesLightCountryToManyCitiesLightSubregionFilter = {
  every?: CitiesLightSubregionFilter | null;
  none?: CitiesLightSubregionFilter | null;
  some?: CitiesLightSubregionFilter | null;
};
export type CitiesLightCountryToManySecuritiesEmailcampaignFilter = {
  every?: SecuritiesEmailcampaignFilter | null;
  none?: SecuritiesEmailcampaignFilter | null;
  some?: SecuritiesEmailcampaignFilter | null;
};
export type SecuritiesEmailcampaignFilter = {
  and?: ReadonlyArray<SecuritiesEmailcampaignFilter> | null;
  beginAt?: DatetimeFilter | null;
  citiesLightCountryByCountryId?: CitiesLightCountryFilter | null;
  citiesLightCountryByCountryIdExists?: boolean | null;
  countryId?: IntFilter | null;
  cursor?: IntFilter | null;
  endAt?: DatetimeFilter | null;
  locale?: StringFilter | null;
  name?: StringFilter | null;
  not?: SecuritiesEmailcampaignFilter | null;
  or?: ReadonlyArray<SecuritiesEmailcampaignFilter> | null;
  pause?: BooleanFilter | null;
  rowId?: IntFilter | null;
};
export type CitiesLightCountryToManySecuritiesOriginationfeeFilter = {
  every?: SecuritiesOriginationfeeFilter | null;
  none?: SecuritiesOriginationfeeFilter | null;
  some?: SecuritiesOriginationfeeFilter | null;
};
export type SecuritiesOriginationfeeFilter = {
  and?: ReadonlyArray<SecuritiesOriginationfeeFilter> | null;
  assetId?: IntFilter | null;
  authUserByUserId?: AuthUserFilter | null;
  authUserByUserIdExists?: boolean | null;
  citiesLightCountryByCountryId?: CitiesLightCountryFilter | null;
  citiesLightCountryByCountryIdExists?: boolean | null;
  countryId?: IntFilter | null;
  description?: StringFilter | null;
  fixed?: BigFloatFilter | null;
  not?: SecuritiesOriginationfeeFilter | null;
  or?: ReadonlyArray<SecuritiesOriginationfeeFilter> | null;
  percent?: BigFloatFilter | null;
  priority?: IntFilter | null;
  promoCode?: StringFilter | null;
  referalDepth?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  securitiesAssetByAssetIdExists?: boolean | null;
  userId?: IntFilter | null;
};
export type CitiesLightCountryToManySecuritiesProfileFilter = {
  every?: SecuritiesProfileFilter | null;
  none?: SecuritiesProfileFilter | null;
  some?: SecuritiesProfileFilter | null;
};
export type CitiesLightCountryToManySecuritiesRegionalmanagerFilter = {
  every?: SecuritiesRegionalmanagerFilter | null;
  none?: SecuritiesRegionalmanagerFilter | null;
  some?: SecuritiesRegionalmanagerFilter | null;
};
export type SecuritiesRegionalmanagerFilter = {
  and?: ReadonlyArray<SecuritiesRegionalmanagerFilter> | null;
  citiesLightCountryByCountryId?: CitiesLightCountryFilter | null;
  countryId?: IntFilter | null;
  not?: SecuritiesRegionalmanagerFilter | null;
  or?: ReadonlyArray<SecuritiesRegionalmanagerFilter> | null;
  profileId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
};
export type SecuritiesProfileToManySecuritiesProposalFilter = {
  every?: SecuritiesProposalFilter | null;
  none?: SecuritiesProposalFilter | null;
  some?: SecuritiesProposalFilter | null;
};
export type SecuritiesProfileToManySecuritiesRequestforproposalFilter = {
  every?: SecuritiesRequestforproposalFilter | null;
  none?: SecuritiesRequestforproposalFilter | null;
  some?: SecuritiesRequestforproposalFilter | null;
};
export type SecuritiesProfileToManySecuritiesAssetFilter = {
  every?: SecuritiesAssetFilter | null;
  none?: SecuritiesAssetFilter | null;
  some?: SecuritiesAssetFilter | null;
};
export type SecuritiesProfileToManyApiProfileapikeyFilter = {
  every?: ApiProfileapikeyFilter | null;
  none?: ApiProfileapikeyFilter | null;
  some?: ApiProfileapikeyFilter | null;
};
export type ApiProfileapikeyFilter = {
  and?: ReadonlyArray<ApiProfileapikeyFilter> | null;
  created?: DatetimeFilter | null;
  expiryDate?: DatetimeFilter | null;
  hashedKey?: StringFilter | null;
  name?: StringFilter | null;
  not?: ApiProfileapikeyFilter | null;
  or?: ReadonlyArray<ApiProfileapikeyFilter> | null;
  prefix?: StringFilter | null;
  profileId?: IntFilter | null;
  revoked?: BooleanFilter | null;
  rowId?: StringFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
};
export type SecuritiesProfileToManyBitcoinAddressFilter = {
  every?: BitcoinAddressFilter | null;
  none?: BitcoinAddressFilter | null;
  some?: BitcoinAddressFilter | null;
};
export type SecuritiesProfileToManyPaypalOrderFilter = {
  every?: PaypalOrderFilter | null;
  none?: PaypalOrderFilter | null;
  some?: PaypalOrderFilter | null;
};
export type SecuritiesProfileToManyReportingCompanyProfileFilter = {
  every?: ReportingCompanyProfileFilter | null;
  none?: ReportingCompanyProfileFilter | null;
  some?: ReportingCompanyProfileFilter | null;
};
export type ReportingCompanyProfileFilter = {
  and?: ReadonlyArray<ReportingCompanyProfileFilter> | null;
  companyId?: IntFilter | null;
  not?: ReportingCompanyProfileFilter | null;
  or?: ReadonlyArray<ReportingCompanyProfileFilter> | null;
  profileId?: IntFilter | null;
  reportingCompanyByCompanyId?: ReportingCompanyFilter | null;
  rowId?: IntFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
};
export type ReportingCompanyFilter = {
  and?: ReadonlyArray<ReportingCompanyFilter> | null;
  code?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: ReportingCompanyFilter | null;
  or?: ReadonlyArray<ReportingCompanyFilter> | null;
  reportingAccountsByCompanyId?: ReportingCompanyToManyReportingAccountFilter | null;
  reportingAccountsByCompanyIdExist?: boolean | null;
  reportingCompanyProfilesByCompanyId?: ReportingCompanyToManyReportingCompanyProfileFilter | null;
  reportingCompanyProfilesByCompanyIdExist?: boolean | null;
  reportingDocumentsByCompanyId?: ReportingCompanyToManyReportingDocumentFilter | null;
  reportingDocumentsByCompanyIdExist?: boolean | null;
  rowId?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type ReportingCompanyToManyReportingAccountFilter = {
  every?: ReportingAccountFilter | null;
  none?: ReportingAccountFilter | null;
  some?: ReportingAccountFilter | null;
};
export type ReportingAccountFilter = {
  accountType?: IntFilter | null;
  and?: ReadonlyArray<ReportingAccountFilter> | null;
  code?: StringFilter | null;
  companyId?: IntFilter | null;
  createdAt?: DatetimeFilter | null;
  indentLevel?: IntFilter | null;
  ledgerSign?: IntFilter | null;
  name?: StringFilter | null;
  not?: ReportingAccountFilter | null;
  or?: ReadonlyArray<ReportingAccountFilter> | null;
  parentAccountId?: IntFilter | null;
  reportSign?: IntFilter | null;
  reportingAccountAssetGroupsByAccountId?: ReportingAccountToManyReportingAccountAssetGroupFilter | null;
  reportingAccountAssetGroupsByAccountIdExist?: boolean | null;
  reportingAccountByParentAccountId?: ReportingAccountFilter | null;
  reportingAccountByParentAccountIdExists?: boolean | null;
  reportingAccountPocketsByAccountId?: ReportingAccountToManyReportingAccountPocketFilter | null;
  reportingAccountPocketsByAccountIdExist?: boolean | null;
  reportingAccountsByParentAccountId?: ReportingAccountToManyReportingAccountFilter | null;
  reportingAccountsByParentAccountIdExist?: boolean | null;
  reportingCompanyByCompanyId?: ReportingCompanyFilter | null;
  reportingCompanyByCompanyIdExists?: boolean | null;
  reportingStatementByStatementId?: ReportingStatementFilter | null;
  reportingStatementByStatementIdExists?: boolean | null;
  rowId?: IntFilter | null;
  showDetail?: BooleanFilter | null;
  showSummary?: BooleanFilter | null;
  showVariance?: BooleanFilter | null;
  sortTerm?: StringFilter | null;
  statementId?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type ReportingAccountToManyReportingAccountFilter = {
  every?: ReportingAccountFilter | null;
  none?: ReportingAccountFilter | null;
  some?: ReportingAccountFilter | null;
};
export type ReportingAccountToManyReportingAccountAssetGroupFilter = {
  every?: ReportingAccountAssetGroupFilter | null;
  none?: ReportingAccountAssetGroupFilter | null;
  some?: ReportingAccountAssetGroupFilter | null;
};
export type ReportingAccountAssetGroupFilter = {
  accountId?: IntFilter | null;
  and?: ReadonlyArray<ReportingAccountAssetGroupFilter> | null;
  assetgroupId?: IntFilter | null;
  not?: ReportingAccountAssetGroupFilter | null;
  or?: ReadonlyArray<ReportingAccountAssetGroupFilter> | null;
  reportingAccountByAccountId?: ReportingAccountFilter | null;
  reportingAssetgroupByAssetgroupId?: ReportingAssetgroupFilter | null;
  rowId?: IntFilter | null;
};
export type ReportingAssetgroupFilter = {
  and?: ReadonlyArray<ReportingAssetgroupFilter> | null;
  code?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: ReportingAssetgroupFilter | null;
  or?: ReadonlyArray<ReportingAssetgroupFilter> | null;
  reportingAccountAssetGroupsByAssetgroupId?: ReportingAssetgroupToManyReportingAccountAssetGroupFilter | null;
  reportingAccountAssetGroupsByAssetgroupIdExist?: boolean | null;
  reportingAssetgroupAssetsByAssetgroupId?: ReportingAssetgroupToManyReportingAssetgroupAssetFilter | null;
  reportingAssetgroupAssetsByAssetgroupIdExist?: boolean | null;
  rowId?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type ReportingAssetgroupToManyReportingAccountAssetGroupFilter = {
  every?: ReportingAccountAssetGroupFilter | null;
  none?: ReportingAccountAssetGroupFilter | null;
  some?: ReportingAccountAssetGroupFilter | null;
};
export type ReportingAssetgroupToManyReportingAssetgroupAssetFilter = {
  every?: ReportingAssetgroupAssetFilter | null;
  none?: ReportingAssetgroupAssetFilter | null;
  some?: ReportingAssetgroupAssetFilter | null;
};
export type ReportingAssetgroupAssetFilter = {
  and?: ReadonlyArray<ReportingAssetgroupAssetFilter> | null;
  assetId?: IntFilter | null;
  assetgroupId?: IntFilter | null;
  not?: ReportingAssetgroupAssetFilter | null;
  or?: ReadonlyArray<ReportingAssetgroupAssetFilter> | null;
  reportingAssetgroupByAssetgroupId?: ReportingAssetgroupFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
};
export type ReportingAccountToManyReportingAccountPocketFilter = {
  every?: ReportingAccountPocketFilter | null;
  none?: ReportingAccountPocketFilter | null;
  some?: ReportingAccountPocketFilter | null;
};
export type ReportingAccountPocketFilter = {
  accountId?: IntFilter | null;
  and?: ReadonlyArray<ReportingAccountPocketFilter> | null;
  not?: ReportingAccountPocketFilter | null;
  or?: ReadonlyArray<ReportingAccountPocketFilter> | null;
  pocketId?: IntFilter | null;
  reportingAccountByAccountId?: ReportingAccountFilter | null;
  rowId?: IntFilter | null;
  securitiesPocketByPocketId?: SecuritiesPocketFilter | null;
};
export type ReportingStatementFilter = {
  and?: ReadonlyArray<ReportingStatementFilter> | null;
  code?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: ReportingStatementFilter | null;
  or?: ReadonlyArray<ReportingStatementFilter> | null;
  reportingAccountsByStatementId?: ReportingStatementToManyReportingAccountFilter | null;
  reportingAccountsByStatementIdExist?: boolean | null;
  reportingDocumentsByDoctypeId?: ReportingStatementToManyReportingDocumentFilter | null;
  reportingDocumentsByDoctypeIdExist?: boolean | null;
  rowId?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type ReportingStatementToManyReportingAccountFilter = {
  every?: ReportingAccountFilter | null;
  none?: ReportingAccountFilter | null;
  some?: ReportingAccountFilter | null;
};
export type ReportingStatementToManyReportingDocumentFilter = {
  every?: ReportingDocumentFilter | null;
  none?: ReportingDocumentFilter | null;
  some?: ReportingDocumentFilter | null;
};
export type ReportingDocumentFilter = {
  and?: ReadonlyArray<ReportingDocumentFilter> | null;
  companyId?: IntFilter | null;
  createdAt?: DatetimeFilter | null;
  doctitle?: StringFilter | null;
  doctypeId?: IntFilter | null;
  endDate?: DateFilter | null;
  not?: ReportingDocumentFilter | null;
  or?: ReadonlyArray<ReportingDocumentFilter> | null;
  reportingCompanyByCompanyId?: ReportingCompanyFilter | null;
  reportingStatementByDoctypeId?: ReportingStatementFilter | null;
  rowId?: IntFilter | null;
  startDate?: DateFilter | null;
  status?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
  visibility?: IntFilter | null;
};
export type ReportingCompanyToManyReportingCompanyProfileFilter = {
  every?: ReportingCompanyProfileFilter | null;
  none?: ReportingCompanyProfileFilter | null;
  some?: ReportingCompanyProfileFilter | null;
};
export type ReportingCompanyToManyReportingDocumentFilter = {
  every?: ReportingDocumentFilter | null;
  none?: ReportingDocumentFilter | null;
  some?: ReportingDocumentFilter | null;
};
export type SecuritiesProfileToManySecuritiesAgentbankaccountFilter = {
  every?: SecuritiesAgentbankaccountFilter | null;
  none?: SecuritiesAgentbankaccountFilter | null;
  some?: SecuritiesAgentbankaccountFilter | null;
};
export type SecuritiesProfileToManySecuritiesAgreementFilter = {
  every?: SecuritiesAgreementFilter | null;
  none?: SecuritiesAgreementFilter | null;
  some?: SecuritiesAgreementFilter | null;
};
export type SecuritiesProfileToManySecuritiesBoostFilter = {
  every?: SecuritiesBoostFilter | null;
  none?: SecuritiesBoostFilter | null;
  some?: SecuritiesBoostFilter | null;
};
export type SecuritiesProfileToManySecuritiesCompletedquestionnaireFilter = {
  every?: SecuritiesCompletedquestionnaireFilter | null;
  none?: SecuritiesCompletedquestionnaireFilter | null;
  some?: SecuritiesCompletedquestionnaireFilter | null;
};
export type SecuritiesCompletedquestionnaireFilter = {
  and?: ReadonlyArray<SecuritiesCompletedquestionnaireFilter> | null;
  assetId?: IntFilter | null;
  completedAt?: DatetimeFilter | null;
  not?: SecuritiesCompletedquestionnaireFilter | null;
  or?: ReadonlyArray<SecuritiesCompletedquestionnaireFilter> | null;
  profileId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
};
export type SecuritiesProfileToManySecuritiesDiscretionarycontentFilter = {
  every?: SecuritiesDiscretionarycontentFilter | null;
  none?: SecuritiesDiscretionarycontentFilter | null;
  some?: SecuritiesDiscretionarycontentFilter | null;
};
export type SecuritiesProfileToManySecuritiesDiscretionarygroupFilter = {
  every?: SecuritiesDiscretionarygroupFilter | null;
  none?: SecuritiesDiscretionarygroupFilter | null;
  some?: SecuritiesDiscretionarygroupFilter | null;
};
export type SecuritiesProfileToManySecuritiesErrorFilter = {
  every?: SecuritiesErrorFilter | null;
  none?: SecuritiesErrorFilter | null;
  some?: SecuritiesErrorFilter | null;
};
export type SecuritiesErrorFilter = {
  and?: ReadonlyArray<SecuritiesErrorFilter> | null;
  description?: StringFilter | null;
  errorClassId?: IntFilter | null;
  not?: SecuritiesErrorFilter | null;
  or?: ReadonlyArray<SecuritiesErrorFilter> | null;
  reportedAt?: DatetimeFilter | null;
  reportedById?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesErrorclassByErrorClassId?: SecuritiesErrorclassFilter | null;
  securitiesErrorresolutionsByErrorId?: SecuritiesErrorToManySecuritiesErrorresolutionFilter | null;
  securitiesErrorresolutionsByErrorIdExist?: boolean | null;
  securitiesProfileByReportedById?: SecuritiesProfileFilter | null;
};
export type SecuritiesErrorToManySecuritiesErrorresolutionFilter = {
  every?: SecuritiesErrorresolutionFilter | null;
  none?: SecuritiesErrorresolutionFilter | null;
  some?: SecuritiesErrorresolutionFilter | null;
};
export type SecuritiesErrorresolutionFilter = {
  amount?: BigIntFilter | null;
  and?: ReadonlyArray<SecuritiesErrorresolutionFilter> | null;
  approvedAt?: DatetimeFilter | null;
  approvedById?: IntFilter | null;
  description?: StringFilter | null;
  errorId?: IntFilter | null;
  not?: SecuritiesErrorresolutionFilter | null;
  or?: ReadonlyArray<SecuritiesErrorresolutionFilter> | null;
  securitiesErrorByErrorId?: SecuritiesErrorFilter | null;
  securitiesProfileByApprovedById?: SecuritiesProfileFilter | null;
  securitiesProfileByApprovedByIdExists?: boolean | null;
  securitiesProfileBySubmittedById?: SecuritiesProfileFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  securitiesWalletByWalletFromId?: SecuritiesWalletFilter | null;
  securitiesWalletByWalletToId?: SecuritiesWalletFilter | null;
  status?: StringFilter | null;
  submittedById?: IntFilter | null;
  transactionPtrId?: IntFilter | null;
  walletFromId?: IntFilter | null;
  walletToId?: IntFilter | null;
};
export type SecuritiesWalletFilter = {
  and?: ReadonlyArray<SecuritiesWalletFilter> | null;
  assetId?: IntFilter | null;
  balance?: BigIntFilter | null;
  balanceType?: IntFilter | null;
  not?: SecuritiesWalletFilter | null;
  openOrders?: BigIntFilter | null;
  or?: ReadonlyArray<SecuritiesWalletFilter> | null;
  pocketId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  securitiesErrorresolutionsByWalletFromId?: SecuritiesWalletToManySecuritiesErrorresolutionFilter | null;
  securitiesErrorresolutionsByWalletFromIdExist?: boolean | null;
  securitiesErrorresolutionsByWalletToId?: SecuritiesWalletToManySecuritiesErrorresolutionFilter | null;
  securitiesErrorresolutionsByWalletToIdExist?: boolean | null;
  securitiesIncentivesByWalletId?: SecuritiesWalletToManySecuritiesIncentiveFilter | null;
  securitiesIncentivesByWalletIdExist?: boolean | null;
  securitiesLedgersByWalletId?: SecuritiesWalletToManySecuritiesLedgerFilter | null;
  securitiesLedgersByWalletIdExist?: boolean | null;
  securitiesLinksByWalletId?: SecuritiesWalletToManySecuritiesLinkFilter | null;
  securitiesLinksByWalletIdExist?: boolean | null;
  securitiesPocketByPocketId?: SecuritiesPocketFilter | null;
  securitiesPocketByPocketIdExists?: boolean | null;
  securitiesRetiresByWalletId?: SecuritiesWalletToManySecuritiesRetireFilter | null;
  securitiesRetiresByWalletIdExist?: boolean | null;
};
export type SecuritiesWalletToManySecuritiesErrorresolutionFilter = {
  every?: SecuritiesErrorresolutionFilter | null;
  none?: SecuritiesErrorresolutionFilter | null;
  some?: SecuritiesErrorresolutionFilter | null;
};
export type SecuritiesWalletToManySecuritiesIncentiveFilter = {
  every?: SecuritiesIncentiveFilter | null;
  none?: SecuritiesIncentiveFilter | null;
  some?: SecuritiesIncentiveFilter | null;
};
export type SecuritiesIncentiveFilter = {
  action?: StringFilter | null;
  actionAr?: StringFilter | null;
  actionArYe?: StringFilter | null;
  actionEn?: StringFilter | null;
  actionEs?: StringFilter | null;
  actionEsAr?: StringFilter | null;
  actionEsVe?: StringFilter | null;
  actionPt?: StringFilter | null;
  actionPtBr?: StringFilter | null;
  amount?: BigIntFilter | null;
  and?: ReadonlyArray<SecuritiesIncentiveFilter> | null;
  countryCode?: StringFilter | null;
  grantType?: StringFilter | null;
  not?: SecuritiesIncentiveFilter | null;
  or?: ReadonlyArray<SecuritiesIncentiveFilter> | null;
  rowId?: IntFilter | null;
  securitiesGrantsByIncentiveId?: SecuritiesIncentiveToManySecuritiesGrantFilter | null;
  securitiesGrantsByIncentiveIdExist?: boolean | null;
  securitiesWalletByWalletId?: SecuritiesWalletFilter | null;
  slug?: StringFilter | null;
  vestingSchedule?: StringFilter | null;
  walletId?: IntFilter | null;
};
export type SecuritiesIncentiveToManySecuritiesGrantFilter = {
  every?: SecuritiesGrantFilter | null;
  none?: SecuritiesGrantFilter | null;
  some?: SecuritiesGrantFilter | null;
};
export type SecuritiesGrantFilter = {
  amount?: BigIntFilter | null;
  and?: ReadonlyArray<SecuritiesGrantFilter> | null;
  authUserByInstigatorId?: AuthUserFilter | null;
  authUserByInstigatorIdExists?: boolean | null;
  authUserByUserId?: AuthUserFilter | null;
  incentiveId?: IntFilter | null;
  instigatorId?: IntFilter | null;
  isVested?: BooleanFilter | null;
  not?: SecuritiesGrantFilter | null;
  or?: ReadonlyArray<SecuritiesGrantFilter> | null;
  securitiesGrantvestingByOfId?: SecuritiesGrantvestingFilter | null;
  securitiesGrantvestingByOfIdExists?: boolean | null;
  securitiesIncentiveByIncentiveId?: SecuritiesIncentiveFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
  userId?: IntFilter | null;
  vestingDate?: DateFilter | null;
};
export type SecuritiesGrantvestingFilter = {
  and?: ReadonlyArray<SecuritiesGrantvestingFilter> | null;
  not?: SecuritiesGrantvestingFilter | null;
  ofId?: IntFilter | null;
  or?: ReadonlyArray<SecuritiesGrantvestingFilter> | null;
  securitiesGrantByOfId?: SecuritiesGrantFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesWalletToManySecuritiesLedgerFilter = {
  every?: SecuritiesLedgerFilter | null;
  none?: SecuritiesLedgerFilter | null;
  some?: SecuritiesLedgerFilter | null;
};
export type SecuritiesLedgerFilter = {
  and?: ReadonlyArray<SecuritiesLedgerFilter> | null;
  creditDebit?: BigIntFilter | null;
  not?: SecuritiesLedgerFilter | null;
  or?: ReadonlyArray<SecuritiesLedgerFilter> | null;
  rowId?: IntFilter | null;
  securitiesTransactionByTransactionId?: SecuritiesTransactionFilter | null;
  securitiesWalletByWalletId?: SecuritiesWalletFilter | null;
  transactionId?: IntFilter | null;
  walletId?: IntFilter | null;
};
export type SecuritiesWalletToManySecuritiesLinkFilter = {
  every?: SecuritiesLinkFilter | null;
  none?: SecuritiesLinkFilter | null;
  some?: SecuritiesLinkFilter | null;
};
export type SecuritiesWalletToManySecuritiesRetireFilter = {
  every?: SecuritiesRetireFilter | null;
  none?: SecuritiesRetireFilter | null;
  some?: SecuritiesRetireFilter | null;
};
export type SecuritiesRetireFilter = {
  and?: ReadonlyArray<SecuritiesRetireFilter> | null;
  not?: SecuritiesRetireFilter | null;
  or?: ReadonlyArray<SecuritiesRetireFilter> | null;
  quantity?: BigIntFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  securitiesWalletByWalletId?: SecuritiesWalletFilter | null;
  transactionPtrId?: IntFilter | null;
  walletId?: IntFilter | null;
};
export type SecuritiesErrorclassFilter = {
  and?: ReadonlyArray<SecuritiesErrorclassFilter> | null;
  description?: StringFilter | null;
  name?: StringFilter | null;
  not?: SecuritiesErrorclassFilter | null;
  or?: ReadonlyArray<SecuritiesErrorclassFilter> | null;
  rowId?: IntFilter | null;
  securitiesErrorsByErrorClassId?: SecuritiesErrorclassToManySecuritiesErrorFilter | null;
  securitiesErrorsByErrorClassIdExist?: boolean | null;
};
export type SecuritiesErrorclassToManySecuritiesErrorFilter = {
  every?: SecuritiesErrorFilter | null;
  none?: SecuritiesErrorFilter | null;
  some?: SecuritiesErrorFilter | null;
};
export type SecuritiesProfileToManySecuritiesErrorresolutionFilter = {
  every?: SecuritiesErrorresolutionFilter | null;
  none?: SecuritiesErrorresolutionFilter | null;
  some?: SecuritiesErrorresolutionFilter | null;
};
export type SecuritiesProfileToManySecuritiesEvidenceFilter = {
  every?: SecuritiesEvidenceFilter | null;
  none?: SecuritiesEvidenceFilter | null;
  some?: SecuritiesEvidenceFilter | null;
};
export type SecuritiesEvidenceFilter = {
  and?: ReadonlyArray<SecuritiesEvidenceFilter> | null;
  document?: StringFilter | null;
  evidenceType?: StringFilter | null;
  not?: SecuritiesEvidenceFilter | null;
  or?: ReadonlyArray<SecuritiesEvidenceFilter> | null;
  originalName?: StringFilter | null;
  profileId?: IntFilter | null;
  reasonId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesRejectreasonByReasonId?: SecuritiesRejectreasonFilter | null;
  securitiesRejectreasonByReasonIdExists?: boolean | null;
  status?: StringFilter | null;
};
export type SecuritiesRejectreasonFilter = {
  and?: ReadonlyArray<SecuritiesRejectreasonFilter> | null;
  description?: StringFilter | null;
  descriptionAr?: StringFilter | null;
  descriptionArYe?: StringFilter | null;
  descriptionEn?: StringFilter | null;
  descriptionEs?: StringFilter | null;
  descriptionEsAr?: StringFilter | null;
  descriptionEsVe?: StringFilter | null;
  descriptionPt?: StringFilter | null;
  descriptionPtBr?: StringFilter | null;
  name?: StringFilter | null;
  nameAr?: StringFilter | null;
  nameArYe?: StringFilter | null;
  nameEn?: StringFilter | null;
  nameEs?: StringFilter | null;
  nameEsAr?: StringFilter | null;
  nameEsVe?: StringFilter | null;
  namePt?: StringFilter | null;
  namePtBr?: StringFilter | null;
  not?: SecuritiesRejectreasonFilter | null;
  or?: ReadonlyArray<SecuritiesRejectreasonFilter> | null;
  rowId?: IntFilter | null;
  securitiesEvidencesByReasonId?: SecuritiesRejectreasonToManySecuritiesEvidenceFilter | null;
  securitiesEvidencesByReasonIdExist?: boolean | null;
};
export type SecuritiesRejectreasonToManySecuritiesEvidenceFilter = {
  every?: SecuritiesEvidenceFilter | null;
  none?: SecuritiesEvidenceFilter | null;
  some?: SecuritiesEvidenceFilter | null;
};
export type SecuritiesProfileToManySecuritiesFriendFilter = {
  every?: SecuritiesFriendFilter | null;
  none?: SecuritiesFriendFilter | null;
  some?: SecuritiesFriendFilter | null;
};
export type SecuritiesFriendFilter = {
  and?: ReadonlyArray<SecuritiesFriendFilter> | null;
  friendId?: IntFilter | null;
  not?: SecuritiesFriendFilter | null;
  or?: ReadonlyArray<SecuritiesFriendFilter> | null;
  profileId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesProfileByFriendId?: SecuritiesProfileFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
};
export type SecuritiesProfileToManySecuritiesIssueFilter = {
  every?: SecuritiesIssueFilter | null;
  none?: SecuritiesIssueFilter | null;
  some?: SecuritiesIssueFilter | null;
};
export type SecuritiesProfileToManySecuritiesIssuerFilter = {
  every?: SecuritiesIssuerFilter | null;
  none?: SecuritiesIssuerFilter | null;
  some?: SecuritiesIssuerFilter | null;
};
export type SecuritiesIssuerFilter = {
  address?: StringFilter | null;
  and?: ReadonlyArray<SecuritiesIssuerFilter> | null;
  city?: StringFilter | null;
  creditMin?: StringFilter | null;
  email?: StringFilter | null;
  entityMinWealth?: StringFilter | null;
  entityType?: StringFilter | null;
  escrowAddress?: StringFilter | null;
  escrowEmail?: StringFilter | null;
  escrowFirm?: StringFilter | null;
  escrowPhone?: StringFilter | null;
  escrowSignorName?: StringFilter | null;
  escrowSignorTitle?: StringFilter | null;
  individualMinIncome?: StringFilter | null;
  individualMinWealth?: StringFilter | null;
  issuerAttorneyAddress?: StringFilter | null;
  issuerAttorneyEmail?: StringFilter | null;
  issuerAttorneyFirm?: StringFilter | null;
  issuerAttorneyPhone?: StringFilter | null;
  issuerAttorneySignorName?: StringFilter | null;
  issuerAttorneySignorTitle?: StringFilter | null;
  issuerId?: StringFilter | null;
  issuerState?: IntFilter | null;
  name?: StringFilter | null;
  not?: SecuritiesIssuerFilter | null;
  or?: ReadonlyArray<SecuritiesIssuerFilter> | null;
  profileId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesProfileByProfileIdExists?: boolean | null;
  securitiesStateByIssuerState?: SecuritiesStateFilter | null;
  securitiesStateByIssuerStateExists?: boolean | null;
  securitiesStateByStateOfFormationId?: SecuritiesStateFilter | null;
  securitiesStateByStateOfFormationIdExists?: boolean | null;
  signor?: StringFilter | null;
  signorCo?: StringFilter | null;
  signorCoTitle?: StringFilter | null;
  signorTitle?: StringFilter | null;
  sponsorAttorneyAddress?: StringFilter | null;
  sponsorAttorneyEmail?: StringFilter | null;
  sponsorAttorneyFirm?: StringFilter | null;
  sponsorAttorneyPhone?: StringFilter | null;
  sponsorAttorneySignorName?: StringFilter | null;
  sponsorAttorneySignorTitle?: StringFilter | null;
  stateOfFormationId?: IntFilter | null;
  trusteeAddress?: StringFilter | null;
  trusteeAttorneyAddress?: StringFilter | null;
  trusteeAttorneyEmail?: StringFilter | null;
  trusteeAttorneyFirm?: StringFilter | null;
  trusteeAttorneyPhone?: StringFilter | null;
  trusteeAttorneySignorName?: StringFilter | null;
  trusteeAttorneySignorTitle?: StringFilter | null;
  trusteeEmail?: StringFilter | null;
  trusteeFirm?: StringFilter | null;
  trusteePhone?: StringFilter | null;
  trusteeSignorName?: StringFilter | null;
  trusteeSignorTitle?: StringFilter | null;
  type?: StringFilter | null;
  zip?: StringFilter | null;
};
export type SecuritiesStateFilter = {
  and?: ReadonlyArray<SecuritiesStateFilter> | null;
  name?: StringFilter | null;
  not?: SecuritiesStateFilter | null;
  or?: ReadonlyArray<SecuritiesStateFilter> | null;
  rowId?: IntFilter | null;
  securitiesIssuersByIssuerState?: SecuritiesStateToManySecuritiesIssuerFilter | null;
  securitiesIssuersByIssuerStateExist?: boolean | null;
  securitiesIssuersByStateOfFormationId?: SecuritiesStateToManySecuritiesIssuerFilter | null;
  securitiesIssuersByStateOfFormationIdExist?: boolean | null;
};
export type SecuritiesStateToManySecuritiesIssuerFilter = {
  every?: SecuritiesIssuerFilter | null;
  none?: SecuritiesIssuerFilter | null;
  some?: SecuritiesIssuerFilter | null;
};
export type SecuritiesProfileToManySecuritiesIssuesubscriptionFilter = {
  every?: SecuritiesIssuesubscriptionFilter | null;
  none?: SecuritiesIssuesubscriptionFilter | null;
  some?: SecuritiesIssuesubscriptionFilter | null;
};
export type SecuritiesProfileToManySecuritiesLinkFilter = {
  every?: SecuritiesLinkFilter | null;
  none?: SecuritiesLinkFilter | null;
  some?: SecuritiesLinkFilter | null;
};
export type SecuritiesProfileToManySecuritiesLoadFilter = {
  every?: SecuritiesLoadFilter | null;
  none?: SecuritiesLoadFilter | null;
  some?: SecuritiesLoadFilter | null;
};
export type SecuritiesProfileToManySecuritiesMembershipFilter = {
  every?: SecuritiesMembershipFilter | null;
  none?: SecuritiesMembershipFilter | null;
  some?: SecuritiesMembershipFilter | null;
};
export type SecuritiesProfileToManySecuritiesPendingFilter = {
  every?: SecuritiesPendingFilter | null;
  none?: SecuritiesPendingFilter | null;
  some?: SecuritiesPendingFilter | null;
};
export type SecuritiesPendingFilter = {
  amount?: BigIntFilter | null;
  and?: ReadonlyArray<SecuritiesPendingFilter> | null;
  assetId?: IntFilter | null;
  not?: SecuritiesPendingFilter | null;
  or?: ReadonlyArray<SecuritiesPendingFilter> | null;
  profileId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
};
export type SecuritiesProfileToManySecuritiesPocketFilter = {
  every?: SecuritiesPocketFilter | null;
  none?: SecuritiesPocketFilter | null;
  some?: SecuritiesPocketFilter | null;
};
export type SecuritiesProfileToManySecuritiesProfileFilter = {
  every?: SecuritiesProfileFilter | null;
  none?: SecuritiesProfileFilter | null;
  some?: SecuritiesProfileFilter | null;
};
export type SecuritiesProfileToManySecuritiesProfileTagFilter = {
  every?: SecuritiesProfileTagFilter | null;
  none?: SecuritiesProfileTagFilter | null;
  some?: SecuritiesProfileTagFilter | null;
};
export type SecuritiesProfileToManySecuritiesPropertyassetFilter = {
  every?: SecuritiesPropertyassetFilter | null;
  none?: SecuritiesPropertyassetFilter | null;
  some?: SecuritiesPropertyassetFilter | null;
};
export type SecuritiesPropertyassetFilter = {
  and?: ReadonlyArray<SecuritiesPropertyassetFilter> | null;
  assetPtrId?: IntFilter | null;
  faceValue?: IntFilter | null;
  faceValueQuoteAssetId?: IntFilter | null;
  grantorId?: IntFilter | null;
  not?: SecuritiesPropertyassetFilter | null;
  noteDate?: DateFilter | null;
  or?: ReadonlyArray<SecuritiesPropertyassetFilter> | null;
  project?: StringFilter | null;
  propertyManagerId?: IntFilter | null;
  securitiesAssetByAssetPtrId?: SecuritiesAssetFilter | null;
  securitiesCapitalassetByPropertyassetPtrId?: SecuritiesCapitalassetFilter | null;
  securitiesCapitalassetByPropertyassetPtrIdExists?: boolean | null;
  securitiesFiatassetByFaceValueQuoteAssetId?: SecuritiesFiatassetFilter | null;
  securitiesProfileByGrantorId?: SecuritiesProfileFilter | null;
  securitiesProfileByPropertyManagerId?: SecuritiesProfileFilter | null;
  securitiesRealestateByPropertyassetPtrId?: SecuritiesRealestateFilter | null;
  securitiesRealestateByPropertyassetPtrIdExists?: boolean | null;
};
export type SecuritiesCapitalassetFilter = {
  and?: ReadonlyArray<SecuritiesCapitalassetFilter> | null;
  not?: SecuritiesCapitalassetFilter | null;
  or?: ReadonlyArray<SecuritiesCapitalassetFilter> | null;
  propertyassetPtrId?: IntFilter | null;
  securitiesPersonalautoassetByCapitalassetPtrId?: SecuritiesPersonalautoassetFilter | null;
  securitiesPersonalautoassetByCapitalassetPtrIdExists?: boolean | null;
  securitiesPropertyassetByPropertyassetPtrId?: SecuritiesPropertyassetFilter | null;
};
export type SecuritiesPersonalautoassetFilter = {
  and?: ReadonlyArray<SecuritiesPersonalautoassetFilter> | null;
  capitalassetPtrId?: IntFilter | null;
  not?: SecuritiesPersonalautoassetFilter | null;
  or?: ReadonlyArray<SecuritiesPersonalautoassetFilter> | null;
  securitiesCapitalassetByCapitalassetPtrId?: SecuritiesCapitalassetFilter | null;
};
export type SecuritiesRealestateFilter = {
  and?: ReadonlyArray<SecuritiesRealestateFilter> | null;
  appraisalValue?: IntFilter | null;
  category?: StringFilter | null;
  livableUnit?: IntFilter | null;
  noi?: IntFilter | null;
  not?: SecuritiesRealestateFilter | null;
  or?: ReadonlyArray<SecuritiesRealestateFilter> | null;
  propertyassetPtrId?: IntFilter | null;
  region?: StringFilter | null;
  securitiesPropertyassetByPropertyassetPtrId?: SecuritiesPropertyassetFilter | null;
  strategy?: StringFilter | null;
  taxExempt?: BooleanFilter | null;
  totalSft?: IntFilter | null;
  totalValue?: BigIntFilter | null;
};
export type SecuritiesFiatassetFilter = {
  and?: ReadonlyArray<SecuritiesFiatassetFilter> | null;
  externalassetPtrId?: IntFilter | null;
  not?: SecuritiesFiatassetFilter | null;
  or?: ReadonlyArray<SecuritiesFiatassetFilter> | null;
  paypalAgentsByAssetId?: SecuritiesFiatassetToManyPaypalAgentFilter | null;
  paypalAgentsByAssetIdExist?: boolean | null;
  securitiesExternalassetByExternalassetPtrId?: SecuritiesExternalassetFilter | null;
  securitiesPropertyassetsByFaceValueQuoteAssetId?: SecuritiesFiatassetToManySecuritiesPropertyassetFilter | null;
  securitiesPropertyassetsByFaceValueQuoteAssetIdExist?: boolean | null;
};
export type SecuritiesFiatassetToManyPaypalAgentFilter = {
  every?: PaypalAgentFilter | null;
  none?: PaypalAgentFilter | null;
  some?: PaypalAgentFilter | null;
};
export type PaypalAgentFilter = {
  and?: ReadonlyArray<PaypalAgentFilter> | null;
  assetId?: IntFilter | null;
  enabled?: BooleanFilter | null;
  feeFixed?: FloatFilter | null;
  feeForeignFixed?: FloatFilter | null;
  feeForeignPercent?: FloatFilter | null;
  feePercent?: FloatFilter | null;
  liveClientId?: StringFilter | null;
  liveSecret?: StringFilter | null;
  not?: PaypalAgentFilter | null;
  or?: ReadonlyArray<PaypalAgentFilter> | null;
  rowId?: IntFilter | null;
  sandboxClientId?: StringFilter | null;
  sandboxSecret?: StringFilter | null;
  securitiesFiatassetByAssetId?: SecuritiesFiatassetFilter | null;
};
export type SecuritiesFiatassetToManySecuritiesPropertyassetFilter = {
  every?: SecuritiesPropertyassetFilter | null;
  none?: SecuritiesPropertyassetFilter | null;
  some?: SecuritiesPropertyassetFilter | null;
};
export type SecuritiesExternalassetFilter = {
  and?: ReadonlyArray<SecuritiesExternalassetFilter> | null;
  assetPtrId?: IntFilter | null;
  not?: SecuritiesExternalassetFilter | null;
  or?: ReadonlyArray<SecuritiesExternalassetFilter> | null;
  securitiesAssetByAssetPtrId?: SecuritiesAssetFilter | null;
  securitiesCryptoassetByExternalassetPtrId?: SecuritiesCryptoassetFilter | null;
  securitiesCryptoassetByExternalassetPtrIdExists?: boolean | null;
  securitiesFiatassetByExternalassetPtrId?: SecuritiesFiatassetFilter | null;
  securitiesFiatassetByExternalassetPtrIdExists?: boolean | null;
  securitiesNetworkfeesByAssetId?: SecuritiesExternalassetToManySecuritiesNetworkfeeFilter | null;
  securitiesNetworkfeesByAssetIdExist?: boolean | null;
  securitiesStreetmethodsByAssetId?: SecuritiesExternalassetToManySecuritiesStreetmethodFilter | null;
  securitiesStreetmethodsByAssetIdExist?: boolean | null;
};
export type SecuritiesCryptoassetFilter = {
  and?: ReadonlyArray<SecuritiesCryptoassetFilter> | null;
  bitcoinAddressesByAssetId?: SecuritiesCryptoassetToManyBitcoinAddressFilter | null;
  bitcoinAddressesByAssetIdExist?: boolean | null;
  bitcoinChainByAssetId?: BitcoinChainFilter | null;
  bitcoinChainByAssetIdExists?: boolean | null;
  externalassetPtrId?: IntFilter | null;
  not?: SecuritiesCryptoassetFilter | null;
  or?: ReadonlyArray<SecuritiesCryptoassetFilter> | null;
  securitiesExternalassetByExternalassetPtrId?: SecuritiesExternalassetFilter | null;
};
export type SecuritiesCryptoassetToManyBitcoinAddressFilter = {
  every?: BitcoinAddressFilter | null;
  none?: BitcoinAddressFilter | null;
  some?: BitcoinAddressFilter | null;
};
export type BitcoinChainFilter = {
  and?: ReadonlyArray<BitcoinChainFilter> | null;
  assetId?: IntFilter | null;
  confirmationQuorom?: IntFilter | null;
  currentBlockNumber?: IntFilter | null;
  not?: BitcoinChainFilter | null;
  or?: ReadonlyArray<BitcoinChainFilter> | null;
  rowId?: IntFilter | null;
  securitiesCryptoassetByAssetId?: SecuritiesCryptoassetFilter | null;
  securitiesCryptoassetByAssetIdExists?: boolean | null;
};
export type SecuritiesExternalassetToManySecuritiesNetworkfeeFilter = {
  every?: SecuritiesNetworkfeeFilter | null;
  none?: SecuritiesNetworkfeeFilter | null;
  some?: SecuritiesNetworkfeeFilter | null;
};
export type SecuritiesNetworkfeeFilter = {
  and?: ReadonlyArray<SecuritiesNetworkfeeFilter> | null;
  assetId?: IntFilter | null;
  fee?: BigIntFilter | null;
  not?: SecuritiesNetworkfeeFilter | null;
  or?: ReadonlyArray<SecuritiesNetworkfeeFilter> | null;
  rowId?: IntFilter | null;
  securitiesExternalassetByAssetId?: SecuritiesExternalassetFilter | null;
};
export type SecuritiesExternalassetToManySecuritiesStreetmethodFilter = {
  every?: SecuritiesStreetmethodFilter | null;
  none?: SecuritiesStreetmethodFilter | null;
  some?: SecuritiesStreetmethodFilter | null;
};
export type SecuritiesStreetmethodFilter = {
  and?: ReadonlyArray<SecuritiesStreetmethodFilter> | null;
  assetId?: IntFilter | null;
  isEnabled?: BooleanFilter | null;
  name?: StringFilter | null;
  not?: SecuritiesStreetmethodFilter | null;
  or?: ReadonlyArray<SecuritiesStreetmethodFilter> | null;
  rowId?: IntFilter | null;
  securitiesExternalassetByAssetId?: SecuritiesExternalassetFilter | null;
  securitiesExternalassetByAssetIdExists?: boolean | null;
  securitiesStreetadMethodsByStreetmethodId?: SecuritiesStreetmethodToManySecuritiesStreetadMethodFilter | null;
  securitiesStreetadMethodsByStreetmethodIdExist?: boolean | null;
  securitiesStreetticketsByMethodId?: SecuritiesStreetmethodToManySecuritiesStreetticketFilter | null;
  securitiesStreetticketsByMethodIdExist?: boolean | null;
  variant?: StringFilter | null;
};
export type SecuritiesStreetmethodToManySecuritiesStreetadMethodFilter = {
  every?: SecuritiesStreetadMethodFilter | null;
  none?: SecuritiesStreetadMethodFilter | null;
  some?: SecuritiesStreetadMethodFilter | null;
};
export type SecuritiesStreetadMethodFilter = {
  and?: ReadonlyArray<SecuritiesStreetadMethodFilter> | null;
  not?: SecuritiesStreetadMethodFilter | null;
  or?: ReadonlyArray<SecuritiesStreetadMethodFilter> | null;
  rowId?: IntFilter | null;
  securitiesStreetadByStreetadId?: SecuritiesStreetadFilter | null;
  securitiesStreetmethodByStreetmethodId?: SecuritiesStreetmethodFilter | null;
  streetadId?: IntFilter | null;
  streetmethodId?: IntFilter | null;
};
export type SecuritiesStreetadFilter = {
  and?: ReadonlyArray<SecuritiesStreetadFilter> | null;
  assetId?: IntFilter | null;
  createdAt?: DatetimeFilter | null;
  enabled?: BooleanFilter | null;
  info?: StringFilter | null;
  linkId?: IntFilter | null;
  maximumQuantity?: BigFloatFilter | null;
  minimumQuantity?: BigFloatFilter | null;
  not?: SecuritiesStreetadFilter | null;
  or?: ReadonlyArray<SecuritiesStreetadFilter> | null;
  price?: BigFloatFilter | null;
  profileId?: IntFilter | null;
  quoteAssetId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  securitiesAssetByQuoteAssetId?: SecuritiesAssetFilter | null;
  securitiesLinkByLinkId?: SecuritiesLinkFilter | null;
  securitiesLinkByLinkIdExists?: boolean | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesStreetadMethodsByStreetadId?: SecuritiesStreetadToManySecuritiesStreetadMethodFilter | null;
  securitiesStreetadMethodsByStreetadIdExist?: boolean | null;
  securitiesStreetticketsByAdId?: SecuritiesStreetadToManySecuritiesStreetticketFilter | null;
  securitiesStreetticketsByAdIdExist?: boolean | null;
  side?: StringFilter | null;
};
export type SecuritiesStreetadToManySecuritiesStreetadMethodFilter = {
  every?: SecuritiesStreetadMethodFilter | null;
  none?: SecuritiesStreetadMethodFilter | null;
  some?: SecuritiesStreetadMethodFilter | null;
};
export type SecuritiesStreetadToManySecuritiesStreetticketFilter = {
  every?: SecuritiesStreetticketFilter | null;
  none?: SecuritiesStreetticketFilter | null;
  some?: SecuritiesStreetticketFilter | null;
};
export type SecuritiesStreetticketFilter = {
  adId?: IntFilter | null;
  and?: ReadonlyArray<SecuritiesStreetticketFilter> | null;
  handling?: StringFilter | null;
  linkId?: IntFilter | null;
  methodId?: IntFilter | null;
  not?: SecuritiesStreetticketFilter | null;
  or?: ReadonlyArray<SecuritiesStreetticketFilter> | null;
  profileId?: IntFilter | null;
  quantity?: BigIntFilter | null;
  securitiesLinkByLinkId?: SecuritiesLinkFilter | null;
  securitiesLinkByLinkIdExists?: boolean | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesStreetadByAdId?: SecuritiesStreetadFilter | null;
  securitiesStreetmethodByMethodId?: SecuritiesStreetmethodFilter | null;
  securitiesStreetmethodByMethodIdExists?: boolean | null;
  securitiesStreetsettlementByTicketId?: SecuritiesStreetsettlementFilter | null;
  securitiesStreetsettlementByTicketIdExists?: boolean | null;
  securitiesStreetticketacceptByTicketId?: SecuritiesStreetticketacceptFilter | null;
  securitiesStreetticketacceptByTicketIdExists?: boolean | null;
  securitiesStreetticketcancelByTicketId?: SecuritiesStreetticketcancelFilter | null;
  securitiesStreetticketcancelByTicketIdExists?: boolean | null;
  securitiesStreetticketrejectByTicketId?: SecuritiesStreetticketrejectFilter | null;
  securitiesStreetticketrejectByTicketIdExists?: boolean | null;
  securitiesStreetticketreturnByTicketId?: SecuritiesStreetticketreturnFilter | null;
  securitiesStreetticketreturnByTicketIdExists?: boolean | null;
  securitiesStreetticketshipmentByTicketId?: SecuritiesStreetticketshipmentFilter | null;
  securitiesStreetticketshipmentByTicketIdExists?: boolean | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  status?: StringFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesStreetsettlementFilter = {
  and?: ReadonlyArray<SecuritiesStreetsettlementFilter> | null;
  fee?: BigFloatFilter | null;
  gross?: BigFloatFilter | null;
  message?: StringFilter | null;
  method?: StringFilter | null;
  net?: BigFloatFilter | null;
  not?: SecuritiesStreetsettlementFilter | null;
  or?: ReadonlyArray<SecuritiesStreetsettlementFilter> | null;
  refnum?: StringFilter | null;
  securitiesStreetticketByTicketId?: SecuritiesStreetticketFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  ticketId?: IntFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesStreetticketacceptFilter = {
  and?: ReadonlyArray<SecuritiesStreetticketacceptFilter> | null;
  message?: StringFilter | null;
  not?: SecuritiesStreetticketacceptFilter | null;
  or?: ReadonlyArray<SecuritiesStreetticketacceptFilter> | null;
  securitiesStreetticketByTicketId?: SecuritiesStreetticketFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  ticketId?: IntFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesStreetticketcancelFilter = {
  and?: ReadonlyArray<SecuritiesStreetticketcancelFilter> | null;
  message?: StringFilter | null;
  not?: SecuritiesStreetticketcancelFilter | null;
  or?: ReadonlyArray<SecuritiesStreetticketcancelFilter> | null;
  securitiesStreetticketByTicketId?: SecuritiesStreetticketFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  ticketId?: IntFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesStreetticketrejectFilter = {
  and?: ReadonlyArray<SecuritiesStreetticketrejectFilter> | null;
  message?: StringFilter | null;
  not?: SecuritiesStreetticketrejectFilter | null;
  or?: ReadonlyArray<SecuritiesStreetticketrejectFilter> | null;
  securitiesStreetticketByTicketId?: SecuritiesStreetticketFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  ticketId?: IntFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesStreetticketreturnFilter = {
  and?: ReadonlyArray<SecuritiesStreetticketreturnFilter> | null;
  message?: StringFilter | null;
  not?: SecuritiesStreetticketreturnFilter | null;
  or?: ReadonlyArray<SecuritiesStreetticketreturnFilter> | null;
  securitiesStreetticketByTicketId?: SecuritiesStreetticketFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  ticketId?: IntFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesStreetticketshipmentFilter = {
  and?: ReadonlyArray<SecuritiesStreetticketshipmentFilter> | null;
  message?: StringFilter | null;
  not?: SecuritiesStreetticketshipmentFilter | null;
  or?: ReadonlyArray<SecuritiesStreetticketshipmentFilter> | null;
  ref?: StringFilter | null;
  securitiesStreetticketByTicketId?: SecuritiesStreetticketFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  ticketId?: IntFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesStreetmethodToManySecuritiesStreetticketFilter = {
  every?: SecuritiesStreetticketFilter | null;
  none?: SecuritiesStreetticketFilter | null;
  some?: SecuritiesStreetticketFilter | null;
};
export type SecuritiesProfileToManySecuritiesRegionalmanagerFilter = {
  every?: SecuritiesRegionalmanagerFilter | null;
  none?: SecuritiesRegionalmanagerFilter | null;
  some?: SecuritiesRegionalmanagerFilter | null;
};
export type SecuritiesProfileToManySecuritiesStreetadFilter = {
  every?: SecuritiesStreetadFilter | null;
  none?: SecuritiesStreetadFilter | null;
  some?: SecuritiesStreetadFilter | null;
};
export type SecuritiesProfileToManySecuritiesStreetticketFilter = {
  every?: SecuritiesStreetticketFilter | null;
  none?: SecuritiesStreetticketFilter | null;
  some?: SecuritiesStreetticketFilter | null;
};
export type SecuritiesTalentgiftFilter = {
  and?: ReadonlyArray<SecuritiesTalentgiftFilter> | null;
  not?: SecuritiesTalentgiftFilter | null;
  or?: ReadonlyArray<SecuritiesTalentgiftFilter> | null;
  profileId?: IntFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesProfileToManySecuritiesTransferFilter = {
  every?: SecuritiesTransferFilter | null;
  none?: SecuritiesTransferFilter | null;
  some?: SecuritiesTransferFilter | null;
};
export type SecuritiesProfileToManySecuritiesUnloadFilter = {
  every?: SecuritiesUnloadFilter | null;
  none?: SecuritiesUnloadFilter | null;
  some?: SecuritiesUnloadFilter | null;
};
export type SecuritiesUnloadFilter = {
  and?: ReadonlyArray<SecuritiesUnloadFilter> | null;
  linkId?: IntFilter | null;
  not?: SecuritiesUnloadFilter | null;
  or?: ReadonlyArray<SecuritiesUnloadFilter> | null;
  quantity?: BigIntFilter | null;
  routeId?: IntFilter | null;
  securitiesLinkByLinkId?: SecuritiesLinkFilter | null;
  securitiesProfileByRouteId?: SecuritiesProfileFilter | null;
  securitiesProfileByRouteIdExists?: boolean | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesUserpreferenceFilter = {
  and?: ReadonlyArray<SecuritiesUserpreferenceFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  autoLogoutDnx?: BooleanFilter | null;
  dashLayout?: JSONFilter | null;
  dismissPocketPopup?: BooleanFilter | null;
  dnxLayout?: JSONFilter | null;
  emailActivationKey?: StringFilter | null;
  language?: StringFilter | null;
  menuTheme?: StringFilter | null;
  newEmail?: StringFilter | null;
  not?: SecuritiesUserpreferenceFilter | null;
  or?: ReadonlyArray<SecuritiesUserpreferenceFilter> | null;
  rowId?: IntFilter | null;
  securitiesProfileBySignInProfileId?: SecuritiesProfileFilter | null;
  signInProfileId?: IntFilter | null;
  signInWithMostRecentActiveProfile?: BooleanFilter | null;
  userId?: IntFilter | null;
};
export type JSONFilter = {
  containedBy?: any | null;
  contains?: any | null;
  containsAllKeys?: ReadonlyArray<string> | null;
  containsAnyKeys?: ReadonlyArray<string> | null;
  containsKey?: string | null;
  distinctFrom?: any | null;
  equalTo?: any | null;
  greaterThan?: any | null;
  greaterThanOrEqualTo?: any | null;
  in?: ReadonlyArray<any> | null;
  isNull?: boolean | null;
  lessThan?: any | null;
  lessThanOrEqualTo?: any | null;
  notDistinctFrom?: any | null;
  notEqualTo?: any | null;
  notIn?: ReadonlyArray<any> | null;
};
export type SecuritiesProfileToManySecuritiesVisitFilter = {
  every?: SecuritiesVisitFilter | null;
  none?: SecuritiesVisitFilter | null;
  some?: SecuritiesVisitFilter | null;
};
export type SecuritiesVisitFilter = {
  and?: ReadonlyArray<SecuritiesVisitFilter> | null;
  at?: DatetimeFilter | null;
  last?: BooleanFilter | null;
  not?: SecuritiesVisitFilter | null;
  or?: ReadonlyArray<SecuritiesVisitFilter> | null;
  rowId?: IntFilter | null;
  securitiesProfileByVisitedId?: SecuritiesProfileFilter | null;
  securitiesProfileByVisitorId?: SecuritiesProfileFilter | null;
  visitedId?: IntFilter | null;
  visitorId?: IntFilter | null;
};
export type SecuritiesProfileToManyTransfersCancelFilter = {
  every?: TransfersCancelFilter | null;
  none?: TransfersCancelFilter | null;
  some?: TransfersCancelFilter | null;
};
export type TransfersCancelFilter = {
  and?: ReadonlyArray<TransfersCancelFilter> | null;
  date?: DatetimeFilter | null;
  not?: TransfersCancelFilter | null;
  note?: StringFilter | null;
  or?: ReadonlyArray<TransfersCancelFilter> | null;
  originId?: IntFilter | null;
  profileId?: IntFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
  transfersHoldByOriginId?: TransfersHoldFilter | null;
};
export type TransfersHoldFilter = {
  and?: ReadonlyArray<TransfersHoldFilter> | null;
  assetId?: IntFilter | null;
  date?: DatetimeFilter | null;
  exAssetId?: IntFilter | null;
  exQuantity?: BigIntFilter | null;
  not?: TransfersHoldFilter | null;
  note?: StringFilter | null;
  or?: ReadonlyArray<TransfersHoldFilter> | null;
  quantity?: BigIntFilter | null;
  receiverId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  securitiesAssetByExAssetId?: SecuritiesAssetFilter | null;
  securitiesAssetByExAssetIdExists?: boolean | null;
  securitiesProfileByReceiverId?: SecuritiesProfileFilter | null;
  securitiesProfileByReceiverIdExists?: boolean | null;
  securitiesProfileBySenderId?: SecuritiesProfileFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  senderId?: IntFilter | null;
  status?: StringFilter | null;
  transactionPtrId?: IntFilter | null;
  transfersCancelsByOriginId?: TransfersHoldToManyTransfersCancelFilter | null;
  transfersCancelsByOriginIdExist?: boolean | null;
  transfersUnholdsByOriginId?: TransfersHoldToManyTransfersUnholdFilter | null;
  transfersUnholdsByOriginIdExist?: boolean | null;
  typeOf?: StringFilter | null;
};
export type TransfersHoldToManyTransfersCancelFilter = {
  every?: TransfersCancelFilter | null;
  none?: TransfersCancelFilter | null;
  some?: TransfersCancelFilter | null;
};
export type TransfersHoldToManyTransfersUnholdFilter = {
  every?: TransfersUnholdFilter | null;
  none?: TransfersUnholdFilter | null;
  some?: TransfersUnholdFilter | null;
};
export type TransfersUnholdFilter = {
  and?: ReadonlyArray<TransfersUnholdFilter> | null;
  date?: DatetimeFilter | null;
  not?: TransfersUnholdFilter | null;
  note?: StringFilter | null;
  or?: ReadonlyArray<TransfersUnholdFilter> | null;
  originId?: IntFilter | null;
  profileId?: IntFilter | null;
  securitiesProfileByProfileId?: SecuritiesProfileFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
  transfersHoldByOriginId?: TransfersHoldFilter | null;
};
export type SecuritiesProfileToManyTransfersHoldFilter = {
  every?: TransfersHoldFilter | null;
  none?: TransfersHoldFilter | null;
  some?: TransfersHoldFilter | null;
};
export type SecuritiesProfileToManyTransfersSendFilter = {
  every?: TransfersSendFilter | null;
  none?: TransfersSendFilter | null;
  some?: TransfersSendFilter | null;
};
export type SecuritiesProfileToManyTransfersUnholdFilter = {
  every?: TransfersUnholdFilter | null;
  none?: TransfersUnholdFilter | null;
  some?: TransfersUnholdFilter | null;
};
export type SecuritiesProfileToManySecuritiesPayeesplitFilter = {
  every?: SecuritiesPayeesplitFilter | null;
  none?: SecuritiesPayeesplitFilter | null;
  some?: SecuritiesPayeesplitFilter | null;
};
export type SecuritiesEftpushmintlinkFilter = {
  and?: ReadonlyArray<SecuritiesEftpushmintlinkFilter> | null;
  identity?: StringFilter | null;
  institution?: StringFilter | null;
  linkPtrId?: IntFilter | null;
  name?: StringFilter | null;
  not?: SecuritiesEftpushmintlinkFilter | null;
  or?: ReadonlyArray<SecuritiesEftpushmintlinkFilter> | null;
  securitiesLinkByLinkPtrId?: SecuritiesLinkFilter | null;
};
export type SecuritiesLinkToManySecuritiesLoadFilter = {
  every?: SecuritiesLoadFilter | null;
  none?: SecuritiesLoadFilter | null;
  some?: SecuritiesLoadFilter | null;
};
export type SecuritiesPaymentcardlinkFilter = {
  and?: ReadonlyArray<SecuritiesPaymentcardlinkFilter> | null;
  cardNumber?: StringFilter | null;
  ccv?: StringFilter | null;
  deposit1?: FloatFilter | null;
  deposit2?: FloatFilter | null;
  expMonth?: StringFilter | null;
  expYear?: StringFilter | null;
  isConfirmed?: BooleanFilter | null;
  linkPtrId?: IntFilter | null;
  nameInCard?: StringFilter | null;
  not?: SecuritiesPaymentcardlinkFilter | null;
  or?: ReadonlyArray<SecuritiesPaymentcardlinkFilter> | null;
  securitiesLinkByLinkPtrId?: SecuritiesLinkFilter | null;
  verificationAttempts?: IntFilter | null;
  zipCode?: StringFilter | null;
};
export type SecuritiesLinkToManySecuritiesStreetadFilter = {
  every?: SecuritiesStreetadFilter | null;
  none?: SecuritiesStreetadFilter | null;
  some?: SecuritiesStreetadFilter | null;
};
export type SecuritiesLinkToManySecuritiesStreetticketFilter = {
  every?: SecuritiesStreetticketFilter | null;
  none?: SecuritiesStreetticketFilter | null;
  some?: SecuritiesStreetticketFilter | null;
};
export type SecuritiesLinkToManySecuritiesUnloadFilter = {
  every?: SecuritiesUnloadFilter | null;
  none?: SecuritiesUnloadFilter | null;
  some?: SecuritiesUnloadFilter | null;
};
export type SecuritiesPocketToManyReportingAccountPocketFilter = {
  every?: ReportingAccountPocketFilter | null;
  none?: ReportingAccountPocketFilter | null;
  some?: ReportingAccountPocketFilter | null;
};
export type SecuritiesPocketToManyReportingJournalFilter = {
  every?: ReportingJournalFilter | null;
  none?: ReportingJournalFilter | null;
  some?: ReportingJournalFilter | null;
};
export type ReportingJournalFilter = {
  and?: ReadonlyArray<ReportingJournalFilter> | null;
  assetId?: IntFilter | null;
  comment?: StringFilter | null;
  crDr?: BigIntFilter | null;
  createdAt?: DatetimeFilter | null;
  entryDate?: DatetimeFilter | null;
  entryType?: StringFilter | null;
  not?: ReportingJournalFilter | null;
  or?: ReadonlyArray<ReportingJournalFilter> | null;
  pocketId?: IntFilter | null;
  reportingScenarioByScenarioId?: ReportingScenarioFilter | null;
  rowId?: IntFilter | null;
  scenarioId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  securitiesPocketByPocketId?: SecuritiesPocketFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type ReportingScenarioFilter = {
  and?: ReadonlyArray<ReportingScenarioFilter> | null;
  code?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: ReportingScenarioFilter | null;
  or?: ReadonlyArray<ReportingScenarioFilter> | null;
  reportingJournalsByScenarioId?: ReportingScenarioToManyReportingJournalFilter | null;
  reportingJournalsByScenarioIdExist?: boolean | null;
  rowId?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type ReportingScenarioToManyReportingJournalFilter = {
  every?: ReportingJournalFilter | null;
  none?: ReportingJournalFilter | null;
  some?: ReportingJournalFilter | null;
};
export type SecuritiesPocketToManySecuritiesConversiontargetFilter = {
  every?: SecuritiesConversiontargetFilter | null;
  none?: SecuritiesConversiontargetFilter | null;
  some?: SecuritiesConversiontargetFilter | null;
};
export type SecuritiesConversiontargetFilter = {
  and?: ReadonlyArray<SecuritiesConversiontargetFilter> | null;
  assetId?: IntFilter | null;
  not?: SecuritiesConversiontargetFilter | null;
  or?: ReadonlyArray<SecuritiesConversiontargetFilter> | null;
  pocketId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  securitiesPocketByPocketId?: SecuritiesPocketFilter | null;
  units?: IntFilter | null;
};
export type SecuritiesPocketToManySecuritiesVenueFilter = {
  every?: SecuritiesVenueFilter | null;
  none?: SecuritiesVenueFilter | null;
  some?: SecuritiesVenueFilter | null;
};
export type SecuritiesPocketToManySecuritiesIssueFilter = {
  every?: SecuritiesIssueFilter | null;
  none?: SecuritiesIssueFilter | null;
  some?: SecuritiesIssueFilter | null;
};
export type SecuritiesPocketToManySecuritiesOrderFilter = {
  every?: SecuritiesOrderFilter | null;
  none?: SecuritiesOrderFilter | null;
  some?: SecuritiesOrderFilter | null;
};
export type SecuritiesPocketToManySecuritiesPockettransferFilter = {
  every?: SecuritiesPockettransferFilter | null;
  none?: SecuritiesPockettransferFilter | null;
  some?: SecuritiesPockettransferFilter | null;
};
export type SecuritiesPocketToManySecuritiesProfileFilter = {
  every?: SecuritiesProfileFilter | null;
  none?: SecuritiesProfileFilter | null;
  some?: SecuritiesProfileFilter | null;
};
export type SecuritiesPocketToManySecuritiesWalletFilter = {
  every?: SecuritiesWalletFilter | null;
  none?: SecuritiesWalletFilter | null;
  some?: SecuritiesWalletFilter | null;
};
export type SecuritiesCloseorderFilter = {
  and?: ReadonlyArray<SecuritiesCloseorderFilter> | null;
  not?: SecuritiesCloseorderFilter | null;
  or?: ReadonlyArray<SecuritiesCloseorderFilter> | null;
  securitiesOrderByCloseId?: SecuritiesOrderFilter | null;
  securitiesOrderByCloseIdExists?: boolean | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesTransactionToManySecuritiesLedgerFilter = {
  every?: SecuritiesLedgerFilter | null;
  none?: SecuritiesLedgerFilter | null;
  some?: SecuritiesLedgerFilter | null;
};
export type SecuritiesMintfeeFilter = {
  and?: ReadonlyArray<SecuritiesMintfeeFilter> | null;
  not?: SecuritiesMintfeeFilter | null;
  or?: ReadonlyArray<SecuritiesMintfeeFilter> | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesMoveFilter = {
  and?: ReadonlyArray<SecuritiesMoveFilter> | null;
  not?: SecuritiesMoveFilter | null;
  note?: StringFilter | null;
  or?: ReadonlyArray<SecuritiesMoveFilter> | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type SecuritiesOriginationFilter = {
  and?: ReadonlyArray<SecuritiesOriginationFilter> | null;
  issueId?: IntFilter | null;
  not?: SecuritiesOriginationFilter | null;
  or?: ReadonlyArray<SecuritiesOriginationFilter> | null;
  securitiesIssueByIssueId?: SecuritiesIssueFilter | null;
  securitiesTransactionByTransactionPtrId?: SecuritiesTransactionFilter | null;
  transactionPtrId?: IntFilter | null;
};
export type GameTrialToManyGameScoreFilter = {
  every?: GameScoreFilter | null;
  none?: GameScoreFilter | null;
  some?: GameScoreFilter | null;
};
export type GameScoreFilter = {
  and?: ReadonlyArray<GameScoreFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  createdAt?: DatetimeFilter | null;
  gameTrialByTrialId?: GameTrialFilter | null;
  independent?: FloatFilter | null;
  independentNorm?: FloatFilter | null;
  independentRank?: IntFilter | null;
  independentRewardAllocation?: FloatFilter | null;
  liquidity?: FloatFilter | null;
  liquidityNorm?: FloatFilter | null;
  liquidityRank?: IntFilter | null;
  liquidityRewardAllocation?: FloatFilter | null;
  not?: GameScoreFilter | null;
  or?: ReadonlyArray<GameScoreFilter> | null;
  rowId?: IntFilter | null;
  subordinate?: FloatFilter | null;
  subordinateNorm?: FloatFilter | null;
  subordinateRank?: IntFilter | null;
  subordinateRewardAllocation?: FloatFilter | null;
  trialId?: IntFilter | null;
  userId?: IntFilter | null;
  userIsRanked?: BooleanFilter | null;
};
export type SecuritiesAssetToManyReportingAssetconversionAssetFilter = {
  every?: ReportingAssetconversionAssetFilter | null;
  none?: ReportingAssetconversionAssetFilter | null;
  some?: ReportingAssetconversionAssetFilter | null;
};
export type ReportingAssetconversionAssetFilter = {
  and?: ReadonlyArray<ReportingAssetconversionAssetFilter> | null;
  assetId?: IntFilter | null;
  assetconversionId?: IntFilter | null;
  not?: ReportingAssetconversionAssetFilter | null;
  or?: ReadonlyArray<ReportingAssetconversionAssetFilter> | null;
  reportingAssetconversionByAssetconversionId?: ReportingAssetconversionFilter | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
};
export type ReportingAssetconversionFilter = {
  and?: ReadonlyArray<ReportingAssetconversionFilter> | null;
  code?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  currencyId?: IntFilter | null;
  name?: StringFilter | null;
  not?: ReportingAssetconversionFilter | null;
  or?: ReadonlyArray<ReportingAssetconversionFilter> | null;
  parDenominator?: BigFloatFilter | null;
  parNumerator?: BigFloatFilter | null;
  reportingAssetconversionAssetsByAssetconversionId?: ReportingAssetconversionToManyReportingAssetconversionAssetFilter | null;
  reportingAssetconversionAssetsByAssetconversionIdExist?: boolean | null;
  reportingCurrencyByCurrencyId?: ReportingCurrencyFilter | null;
  rowId?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type ReportingAssetconversionToManyReportingAssetconversionAssetFilter = {
  every?: ReportingAssetconversionAssetFilter | null;
  none?: ReportingAssetconversionAssetFilter | null;
  some?: ReportingAssetconversionAssetFilter | null;
};
export type ReportingCurrencyFilter = {
  and?: ReadonlyArray<ReportingCurrencyFilter> | null;
  code?: StringFilter | null;
  createdAt?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: ReportingCurrencyFilter | null;
  or?: ReadonlyArray<ReportingCurrencyFilter> | null;
  reportingAssetconversionsByCurrencyId?: ReportingCurrencyToManyReportingAssetconversionFilter | null;
  reportingAssetconversionsByCurrencyIdExist?: boolean | null;
  reportingFxratesByFromCurrencyId?: ReportingCurrencyToManyReportingFxrateFilter | null;
  reportingFxratesByFromCurrencyIdExist?: boolean | null;
  reportingFxratesByToCurrencyId?: ReportingCurrencyToManyReportingFxrateFilter | null;
  reportingFxratesByToCurrencyIdExist?: boolean | null;
  rowId?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type ReportingCurrencyToManyReportingAssetconversionFilter = {
  every?: ReportingAssetconversionFilter | null;
  none?: ReportingAssetconversionFilter | null;
  some?: ReportingAssetconversionFilter | null;
};
export type ReportingCurrencyToManyReportingFxrateFilter = {
  every?: ReportingFxrateFilter | null;
  none?: ReportingFxrateFilter | null;
  some?: ReportingFxrateFilter | null;
};
export type ReportingFxrateFilter = {
  and?: ReadonlyArray<ReportingFxrateFilter> | null;
  createdAt?: DatetimeFilter | null;
  denominator?: BigFloatFilter | null;
  effectiveAfter?: DateFilter | null;
  fromCurrencyId?: IntFilter | null;
  not?: ReportingFxrateFilter | null;
  numerator?: BigFloatFilter | null;
  or?: ReadonlyArray<ReportingFxrateFilter> | null;
  reportingCurrencyByFromCurrencyId?: ReportingCurrencyFilter | null;
  reportingCurrencyByToCurrencyId?: ReportingCurrencyFilter | null;
  rowId?: IntFilter | null;
  toCurrencyId?: IntFilter | null;
  updatedAt?: DatetimeFilter | null;
};
export type SecuritiesAssetToManyReportingAssetgroupAssetFilter = {
  every?: ReportingAssetgroupAssetFilter | null;
  none?: ReportingAssetgroupAssetFilter | null;
  some?: ReportingAssetgroupAssetFilter | null;
};
export type SecuritiesAssetToManyReportingJournalFilter = {
  every?: ReportingJournalFilter | null;
  none?: ReportingJournalFilter | null;
  some?: ReportingJournalFilter | null;
};
export type SecuritiesAssetToManySecuritiesAssetDocumentFilter = {
  every?: SecuritiesAssetDocumentFilter | null;
  none?: SecuritiesAssetDocumentFilter | null;
  some?: SecuritiesAssetDocumentFilter | null;
};
export type SecuritiesAssetDocumentFilter = {
  and?: ReadonlyArray<SecuritiesAssetDocumentFilter> | null;
  assetId?: IntFilter | null;
  legaldocId?: IntFilter | null;
  not?: SecuritiesAssetDocumentFilter | null;
  or?: ReadonlyArray<SecuritiesAssetDocumentFilter> | null;
  rowId?: IntFilter | null;
  securitiesAssetByAssetId?: SecuritiesAssetFilter | null;
  securitiesLegaldocByLegaldocId?: SecuritiesLegaldocFilter | null;
};
export type SecuritiesAssetToManySecuritiesAssetimageFilter = {
  every?: SecuritiesAssetimageFilter | null;
  none?: SecuritiesAssetimageFilter | null;
  some?: SecuritiesAssetimageFilter | null;
};
export type SecuritiesAssetToManySecuritiesCompletedquestionnaireFilter = {
  every?: SecuritiesCompletedquestionnaireFilter | null;
  none?: SecuritiesCompletedquestionnaireFilter | null;
  some?: SecuritiesCompletedquestionnaireFilter | null;
};
export type SecuritiesAssetToManySecuritiesConversiontargetFilter = {
  every?: SecuritiesConversiontargetFilter | null;
  none?: SecuritiesConversiontargetFilter | null;
  some?: SecuritiesConversiontargetFilter | null;
};
export type SecuritiesAssetToManySecuritiesDiscretionarygroupFilter = {
  every?: SecuritiesDiscretionarygroupFilter | null;
  none?: SecuritiesDiscretionarygroupFilter | null;
  some?: SecuritiesDiscretionarygroupFilter | null;
};
export type SecuritiesAssetToManySecuritiesVenueFilter = {
  every?: SecuritiesVenueFilter | null;
  none?: SecuritiesVenueFilter | null;
  some?: SecuritiesVenueFilter | null;
};
export type SecuritiesAssetToManySecuritiesIssueFilter = {
  every?: SecuritiesIssueFilter | null;
  none?: SecuritiesIssueFilter | null;
  some?: SecuritiesIssueFilter | null;
};
export type SecuritiesAssetToManySecuritiesLinkFilter = {
  every?: SecuritiesLinkFilter | null;
  none?: SecuritiesLinkFilter | null;
  some?: SecuritiesLinkFilter | null;
};
export type SecuritiesAssetToManySecuritiesMarketFilter = {
  every?: SecuritiesMarketFilter | null;
  none?: SecuritiesMarketFilter | null;
  some?: SecuritiesMarketFilter | null;
};
export type SecuritiesAssetToManySecuritiesOriginationfeeFilter = {
  every?: SecuritiesOriginationfeeFilter | null;
  none?: SecuritiesOriginationfeeFilter | null;
  some?: SecuritiesOriginationfeeFilter | null;
};
export type SecuritiesAssetToManySecuritiesPendingFilter = {
  every?: SecuritiesPendingFilter | null;
  none?: SecuritiesPendingFilter | null;
  some?: SecuritiesPendingFilter | null;
};
export type SecuritiesAssetToManySecuritiesStreetadFilter = {
  every?: SecuritiesStreetadFilter | null;
  none?: SecuritiesStreetadFilter | null;
  some?: SecuritiesStreetadFilter | null;
};
export type SecuritiesAssetToManySecuritiesWalletFilter = {
  every?: SecuritiesWalletFilter | null;
  none?: SecuritiesWalletFilter | null;
  some?: SecuritiesWalletFilter | null;
};
export type SecuritiesAssetToManyTransfersHoldFilter = {
  every?: TransfersHoldFilter | null;
  none?: TransfersHoldFilter | null;
  some?: TransfersHoldFilter | null;
};
export type DjangoContentTypeToManyAuthPermissionFilter = {
  every?: AuthPermissionFilter | null;
  none?: AuthPermissionFilter | null;
  some?: AuthPermissionFilter | null;
};
export type DjangoContentTypeToManyDjangoAdminLogFilter = {
  every?: DjangoAdminLogFilter | null;
  none?: DjangoAdminLogFilter | null;
  some?: DjangoAdminLogFilter | null;
};
export type DjangoAdminLogFilter = {
  actionFlag?: IntFilter | null;
  actionTime?: DatetimeFilter | null;
  and?: ReadonlyArray<DjangoAdminLogFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  changeMessage?: StringFilter | null;
  contentTypeId?: IntFilter | null;
  djangoContentTypeByContentTypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByContentTypeIdExists?: boolean | null;
  not?: DjangoAdminLogFilter | null;
  objectId?: StringFilter | null;
  objectRepr?: StringFilter | null;
  or?: ReadonlyArray<DjangoAdminLogFilter> | null;
  rowId?: IntFilter | null;
  userId?: IntFilter | null;
};
export type DjangoContentTypeToManyFilerFileFilter = {
  every?: FilerFileFilter | null;
  none?: FilerFileFilter | null;
  some?: FilerFileFilter | null;
};
export type DjangoContentTypeToManyGuardianGroupobjectpermissionFilter = {
  every?: GuardianGroupobjectpermissionFilter | null;
  none?: GuardianGroupobjectpermissionFilter | null;
  some?: GuardianGroupobjectpermissionFilter | null;
};
export type DjangoContentTypeToManyGuardianUserobjectpermissionFilter = {
  every?: GuardianUserobjectpermissionFilter | null;
  none?: GuardianUserobjectpermissionFilter | null;
  some?: GuardianUserobjectpermissionFilter | null;
};
export type GuardianUserobjectpermissionFilter = {
  and?: ReadonlyArray<GuardianUserobjectpermissionFilter> | null;
  authPermissionByPermissionId?: AuthPermissionFilter | null;
  authUserByUserId?: AuthUserFilter | null;
  contentTypeId?: IntFilter | null;
  djangoContentTypeByContentTypeId?: DjangoContentTypeFilter | null;
  not?: GuardianUserobjectpermissionFilter | null;
  objectPk?: StringFilter | null;
  or?: ReadonlyArray<GuardianUserobjectpermissionFilter> | null;
  permissionId?: IntFilter | null;
  rowId?: IntFilter | null;
  userId?: IntFilter | null;
};
export type DjangoContentTypeToManyNotificationsNotificationFilter = {
  every?: NotificationsNotificationFilter | null;
  none?: NotificationsNotificationFilter | null;
  some?: NotificationsNotificationFilter | null;
};
export type NotificationsNotificationFilter = {
  actionObjectContentTypeId?: IntFilter | null;
  actionObjectObjectId?: StringFilter | null;
  actorContentTypeId?: IntFilter | null;
  actorObjectId?: StringFilter | null;
  and?: ReadonlyArray<NotificationsNotificationFilter> | null;
  authUserByRecipientId?: AuthUserFilter | null;
  data?: StringFilter | null;
  deleted?: BooleanFilter | null;
  description?: StringFilter | null;
  djangoContentTypeByActionObjectContentTypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByActionObjectContentTypeIdExists?: boolean | null;
  djangoContentTypeByActorContentTypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByTargetContentTypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByTargetContentTypeIdExists?: boolean | null;
  emailed?: BooleanFilter | null;
  level?: StringFilter | null;
  not?: NotificationsNotificationFilter | null;
  or?: ReadonlyArray<NotificationsNotificationFilter> | null;
  public?: BooleanFilter | null;
  recipientId?: IntFilter | null;
  rowId?: IntFilter | null;
  targetContentTypeId?: IntFilter | null;
  targetObjectId?: StringFilter | null;
  timestamp?: DatetimeFilter | null;
  unread?: BooleanFilter | null;
  verb?: StringFilter | null;
};
export type DjangoContentTypeToManyRosettaClasscontentFilter = {
  every?: RosettaClasscontentFilter | null;
  none?: RosettaClasscontentFilter | null;
  some?: RosettaClasscontentFilter | null;
};
export type DjangoContentTypeToManySecuritiesActivityFilter = {
  every?: SecuritiesActivityFilter | null;
  none?: SecuritiesActivityFilter | null;
  some?: SecuritiesActivityFilter | null;
};
export type DjangoContentTypeToManySecuritiesAuthorizationruleFilter = {
  every?: SecuritiesAuthorizationruleFilter | null;
  none?: SecuritiesAuthorizationruleFilter | null;
  some?: SecuritiesAuthorizationruleFilter | null;
};
export type SecuritiesAuthorizationruleFilter = {
  and?: ReadonlyArray<SecuritiesAuthorizationruleFilter> | null;
  ctypeId?: IntFilter | null;
  djangoContentTypeByCtypeId?: DjangoContentTypeFilter | null;
  featureSlug?: StringFilter | null;
  mustCompleteProfile?: BooleanFilter | null;
  mustVerifyEligibility?: BooleanFilter | null;
  mustVerifyIdentity?: BooleanFilter | null;
  not?: SecuritiesAuthorizationruleFilter | null;
  or?: ReadonlyArray<SecuritiesAuthorizationruleFilter> | null;
  policyId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesPolicyByPolicyId?: SecuritiesPolicyFilter | null;
  securitiesPolicyByPolicyIdExists?: boolean | null;
};
export type SecuritiesPolicyFilter = {
  and?: ReadonlyArray<SecuritiesPolicyFilter> | null;
  code?: StringFilter | null;
  not?: SecuritiesPolicyFilter | null;
  or?: ReadonlyArray<SecuritiesPolicyFilter> | null;
  rowId?: IntFilter | null;
  securitiesAuthorizationrulesByPolicyId?: SecuritiesPolicyToManySecuritiesAuthorizationruleFilter | null;
  securitiesAuthorizationrulesByPolicyIdExist?: boolean | null;
  securitiesLegaldocsByPolicyId?: SecuritiesPolicyToManySecuritiesLegaldocFilter | null;
  securitiesLegaldocsByPolicyIdExist?: boolean | null;
  securitiesSecurityeligibilitiesByPolicyId?: SecuritiesPolicyToManySecuritiesSecurityeligibilityFilter | null;
  securitiesSecurityeligibilitiesByPolicyIdExist?: boolean | null;
};
export type SecuritiesPolicyToManySecuritiesAuthorizationruleFilter = {
  every?: SecuritiesAuthorizationruleFilter | null;
  none?: SecuritiesAuthorizationruleFilter | null;
  some?: SecuritiesAuthorizationruleFilter | null;
};
export type SecuritiesPolicyToManySecuritiesLegaldocFilter = {
  every?: SecuritiesLegaldocFilter | null;
  none?: SecuritiesLegaldocFilter | null;
  some?: SecuritiesLegaldocFilter | null;
};
export type SecuritiesPolicyToManySecuritiesSecurityeligibilityFilter = {
  every?: SecuritiesSecurityeligibilityFilter | null;
  none?: SecuritiesSecurityeligibilityFilter | null;
  some?: SecuritiesSecurityeligibilityFilter | null;
};
export type SecuritiesSecurityeligibilityFilter = {
  and?: ReadonlyArray<SecuritiesSecurityeligibilityFilter> | null;
  countryCode?: StringFilter | null;
  not?: SecuritiesSecurityeligibilityFilter | null;
  or?: ReadonlyArray<SecuritiesSecurityeligibilityFilter> | null;
  policyId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesPolicyByPolicyId?: SecuritiesPolicyFilter | null;
};
export type DjangoContentTypeToManySecuritiesDiscretionarycontentFilter = {
  every?: SecuritiesDiscretionarycontentFilter | null;
  none?: SecuritiesDiscretionarycontentFilter | null;
  some?: SecuritiesDiscretionarycontentFilter | null;
};
export type DjangoContentTypeToManySecuritiesLinkFilter = {
  every?: SecuritiesLinkFilter | null;
  none?: SecuritiesLinkFilter | null;
  some?: SecuritiesLinkFilter | null;
};
export type DjangoContentTypeToManySecuritiesTransactionFilter = {
  every?: SecuritiesTransactionFilter | null;
  none?: SecuritiesTransactionFilter | null;
  some?: SecuritiesTransactionFilter | null;
};
export type DjangoContentTypeToManyViewflowProcessFilter = {
  every?: ViewflowProcessFilter | null;
  none?: ViewflowProcessFilter | null;
  some?: ViewflowProcessFilter | null;
};
export type ViewflowProcessFilter = {
  and?: ReadonlyArray<ViewflowProcessFilter> | null;
  artifactContentTypeId?: IntFilter | null;
  artifactObjectId?: IntFilter | null;
  created?: DatetimeFilter | null;
  data?: JSONFilter | null;
  djangoContentTypeByArtifactContentTypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByArtifactContentTypeIdExists?: boolean | null;
  finished?: DatetimeFilter | null;
  flowClass?: StringFilter | null;
  flowsHelloworldprocessByProcessPtrId?: FlowsHelloworldprocessFilter | null;
  flowsHelloworldprocessByProcessPtrIdExists?: boolean | null;
  not?: ViewflowProcessFilter | null;
  or?: ReadonlyArray<ViewflowProcessFilter> | null;
  rowId?: IntFilter | null;
  status?: StringFilter | null;
  viewflowTasksByProcessId?: ViewflowProcessToManyViewflowTaskFilter | null;
  viewflowTasksByProcessIdExist?: boolean | null;
};
export type FlowsHelloworldprocessFilter = {
  and?: ReadonlyArray<FlowsHelloworldprocessFilter> | null;
  approved?: BooleanFilter | null;
  not?: FlowsHelloworldprocessFilter | null;
  or?: ReadonlyArray<FlowsHelloworldprocessFilter> | null;
  processPtrId?: IntFilter | null;
  text?: StringFilter | null;
  viewflowProcessByProcessPtrId?: ViewflowProcessFilter | null;
};
export type ViewflowProcessToManyViewflowTaskFilter = {
  every?: ViewflowTaskFilter | null;
  none?: ViewflowTaskFilter | null;
  some?: ViewflowTaskFilter | null;
};
export type ViewflowTaskFilter = {
  and?: ReadonlyArray<ViewflowTaskFilter> | null;
  artifactContentTypeId?: IntFilter | null;
  artifactObjectId?: IntFilter | null;
  assigned?: DatetimeFilter | null;
  authUserByOwnerId?: AuthUserFilter | null;
  authUserByOwnerIdExists?: boolean | null;
  comments?: StringFilter | null;
  created?: DatetimeFilter | null;
  data?: JSONFilter | null;
  djangoContentTypeByArtifactContentTypeId?: DjangoContentTypeFilter | null;
  djangoContentTypeByArtifactContentTypeIdExists?: boolean | null;
  externalTaskId?: StringFilter | null;
  finished?: DatetimeFilter | null;
  flowTask?: StringFilter | null;
  flowTaskType?: StringFilter | null;
  not?: ViewflowTaskFilter | null;
  or?: ReadonlyArray<ViewflowTaskFilter> | null;
  ownerId?: IntFilter | null;
  ownerPermission?: StringFilter | null;
  processId?: IntFilter | null;
  rowId?: IntFilter | null;
  started?: DatetimeFilter | null;
  status?: StringFilter | null;
  token?: StringFilter | null;
  viewflowProcessByProcessId?: ViewflowProcessFilter | null;
  viewflowTaskPreviousByFromTaskId?: ViewflowTaskToManyViewflowTaskPreviousFilter | null;
  viewflowTaskPreviousByFromTaskIdExist?: boolean | null;
  viewflowTaskPreviousByToTaskId?: ViewflowTaskToManyViewflowTaskPreviousFilter | null;
  viewflowTaskPreviousByToTaskIdExist?: boolean | null;
};
export type ViewflowTaskToManyViewflowTaskPreviousFilter = {
  every?: ViewflowTaskPreviousFilter | null;
  none?: ViewflowTaskPreviousFilter | null;
  some?: ViewflowTaskPreviousFilter | null;
};
export type ViewflowTaskPreviousFilter = {
  and?: ReadonlyArray<ViewflowTaskPreviousFilter> | null;
  fromTaskId?: IntFilter | null;
  not?: ViewflowTaskPreviousFilter | null;
  or?: ReadonlyArray<ViewflowTaskPreviousFilter> | null;
  rowId?: IntFilter | null;
  toTaskId?: IntFilter | null;
  viewflowTaskByFromTaskId?: ViewflowTaskFilter | null;
  viewflowTaskByToTaskId?: ViewflowTaskFilter | null;
};
export type DjangoContentTypeToManyViewflowTaskFilter = {
  every?: ViewflowTaskFilter | null;
  none?: ViewflowTaskFilter | null;
  some?: ViewflowTaskFilter | null;
};
export type AuthPermissionToManyGuardianUserobjectpermissionFilter = {
  every?: GuardianUserobjectpermissionFilter | null;
  none?: GuardianUserobjectpermissionFilter | null;
  some?: GuardianUserobjectpermissionFilter | null;
};
export type AuthGroupToManyAuthUserGroupFilter = {
  every?: AuthUserGroupFilter | null;
  none?: AuthUserGroupFilter | null;
  some?: AuthUserGroupFilter | null;
};
export type AuthGroupToManyCmsGlobalpagepermissionFilter = {
  every?: CmsGlobalpagepermissionFilter | null;
  none?: CmsGlobalpagepermissionFilter | null;
  some?: CmsGlobalpagepermissionFilter | null;
};
export type AuthGroupToManyCmsPagepermissionFilter = {
  every?: CmsPagepermissionFilter | null;
  none?: CmsPagepermissionFilter | null;
  some?: CmsPagepermissionFilter | null;
};
export type CmsPageusergroupFilter = {
  and?: ReadonlyArray<CmsPageusergroupFilter> | null;
  authGroupByGroupPtrId?: AuthGroupFilter | null;
  authUserByCreatedById?: AuthUserFilter | null;
  createdById?: IntFilter | null;
  groupPtrId?: IntFilter | null;
  not?: CmsPageusergroupFilter | null;
  or?: ReadonlyArray<CmsPageusergroupFilter> | null;
};
export type AuthGroupToManyFilerFolderpermissionFilter = {
  every?: FilerFolderpermissionFilter | null;
  none?: FilerFolderpermissionFilter | null;
  some?: FilerFolderpermissionFilter | null;
};
export type AuthGroupToManyGuardianGroupobjectpermissionFilter = {
  every?: GuardianGroupobjectpermissionFilter | null;
  none?: GuardianGroupobjectpermissionFilter | null;
  some?: GuardianGroupobjectpermissionFilter | null;
};
export type AuthUserToManyAuthUserUserPermissionFilter = {
  every?: AuthUserUserPermissionFilter | null;
  none?: AuthUserUserPermissionFilter | null;
  some?: AuthUserUserPermissionFilter | null;
};
export type AuthtokenTokenFilter = {
  and?: ReadonlyArray<AuthtokenTokenFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  created?: DatetimeFilter | null;
  key?: StringFilter | null;
  not?: AuthtokenTokenFilter | null;
  or?: ReadonlyArray<AuthtokenTokenFilter> | null;
  userId?: IntFilter | null;
};
export type AuthUserToManyCmsGlobalpagepermissionFilter = {
  every?: CmsGlobalpagepermissionFilter | null;
  none?: CmsGlobalpagepermissionFilter | null;
  some?: CmsGlobalpagepermissionFilter | null;
};
export type AuthUserToManyCmsPagepermissionFilter = {
  every?: CmsPagepermissionFilter | null;
  none?: CmsPagepermissionFilter | null;
  some?: CmsPagepermissionFilter | null;
};
export type CmsPageuserFilter = {
  and?: ReadonlyArray<CmsPageuserFilter> | null;
  authUserByCreatedById?: AuthUserFilter | null;
  authUserByUserPtrId?: AuthUserFilter | null;
  createdById?: IntFilter | null;
  not?: CmsPageuserFilter | null;
  or?: ReadonlyArray<CmsPageuserFilter> | null;
  userPtrId?: IntFilter | null;
};
export type AuthUserToManyCmsPageuserFilter = {
  every?: CmsPageuserFilter | null;
  none?: CmsPageuserFilter | null;
  some?: CmsPageuserFilter | null;
};
export type AuthUserToManyCmsPageusergroupFilter = {
  every?: CmsPageusergroupFilter | null;
  none?: CmsPageusergroupFilter | null;
  some?: CmsPageusergroupFilter | null;
};
export type AuthUserToManyDjangoAdminLogFilter = {
  every?: DjangoAdminLogFilter | null;
  none?: DjangoAdminLogFilter | null;
  some?: DjangoAdminLogFilter | null;
};
export type AuthUserToManyDjangocmsFormsFormsubmissionFilter = {
  every?: DjangocmsFormsFormsubmissionFilter | null;
  none?: DjangocmsFormsFormsubmissionFilter | null;
  some?: DjangocmsFormsFormsubmissionFilter | null;
};
export type AuthUserToManyFilerClipboardFilter = {
  every?: FilerClipboardFilter | null;
  none?: FilerClipboardFilter | null;
  some?: FilerClipboardFilter | null;
};
export type AuthUserToManyFilerFileFilter = {
  every?: FilerFileFilter | null;
  none?: FilerFileFilter | null;
  some?: FilerFileFilter | null;
};
export type AuthUserToManyFilerFolderFilter = {
  every?: FilerFolderFilter | null;
  none?: FilerFolderFilter | null;
  some?: FilerFolderFilter | null;
};
export type AuthUserToManyFilerFolderpermissionFilter = {
  every?: FilerFolderpermissionFilter | null;
  none?: FilerFolderpermissionFilter | null;
  some?: FilerFolderpermissionFilter | null;
};
export type AuthUserToManyGameScoreFilter = {
  every?: GameScoreFilter | null;
  none?: GameScoreFilter | null;
  some?: GameScoreFilter | null;
};
export type AuthUserToManyGuardianUserobjectpermissionFilter = {
  every?: GuardianUserobjectpermissionFilter | null;
  none?: GuardianUserobjectpermissionFilter | null;
  some?: GuardianUserobjectpermissionFilter | null;
};
export type AuthUserToManyMamaCasProxygrantingticketFilter = {
  every?: MamaCasProxygrantingticketFilter | null;
  none?: MamaCasProxygrantingticketFilter | null;
  some?: MamaCasProxygrantingticketFilter | null;
};
export type MamaCasProxygrantingticketFilter = {
  and?: ReadonlyArray<MamaCasProxygrantingticketFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  consumed?: DatetimeFilter | null;
  expires?: DatetimeFilter | null;
  grantedByPtId?: IntFilter | null;
  grantedByStId?: IntFilter | null;
  iou?: StringFilter | null;
  mamaCasProxyticketByGrantedByPtId?: MamaCasProxyticketFilter | null;
  mamaCasProxyticketByGrantedByPtIdExists?: boolean | null;
  mamaCasProxyticketsByGrantedByPgtId?: MamaCasProxygrantingticketToManyMamaCasProxyticketFilter | null;
  mamaCasProxyticketsByGrantedByPgtIdExist?: boolean | null;
  mamaCasServiceticketByGrantedByStId?: MamaCasServiceticketFilter | null;
  mamaCasServiceticketByGrantedByStIdExists?: boolean | null;
  not?: MamaCasProxygrantingticketFilter | null;
  or?: ReadonlyArray<MamaCasProxygrantingticketFilter> | null;
  rowId?: IntFilter | null;
  ticket?: StringFilter | null;
  userId?: IntFilter | null;
};
export type MamaCasProxygrantingticketToManyMamaCasProxyticketFilter = {
  every?: MamaCasProxyticketFilter | null;
  none?: MamaCasProxyticketFilter | null;
  some?: MamaCasProxyticketFilter | null;
};
export type MamaCasProxyticketFilter = {
  and?: ReadonlyArray<MamaCasProxyticketFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  consumed?: DatetimeFilter | null;
  expires?: DatetimeFilter | null;
  grantedByPgtId?: IntFilter | null;
  mamaCasProxygrantingticketByGrantedByPgtId?: MamaCasProxygrantingticketFilter | null;
  mamaCasProxygrantingticketsByGrantedByPtId?: MamaCasProxyticketToManyMamaCasProxygrantingticketFilter | null;
  mamaCasProxygrantingticketsByGrantedByPtIdExist?: boolean | null;
  not?: MamaCasProxyticketFilter | null;
  or?: ReadonlyArray<MamaCasProxyticketFilter> | null;
  rowId?: IntFilter | null;
  service?: StringFilter | null;
  ticket?: StringFilter | null;
  userId?: IntFilter | null;
};
export type MamaCasProxyticketToManyMamaCasProxygrantingticketFilter = {
  every?: MamaCasProxygrantingticketFilter | null;
  none?: MamaCasProxygrantingticketFilter | null;
  some?: MamaCasProxygrantingticketFilter | null;
};
export type MamaCasServiceticketFilter = {
  and?: ReadonlyArray<MamaCasServiceticketFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  consumed?: DatetimeFilter | null;
  expires?: DatetimeFilter | null;
  mamaCasProxygrantingticketsByGrantedByStId?: MamaCasServiceticketToManyMamaCasProxygrantingticketFilter | null;
  mamaCasProxygrantingticketsByGrantedByStIdExist?: boolean | null;
  not?: MamaCasServiceticketFilter | null;
  or?: ReadonlyArray<MamaCasServiceticketFilter> | null;
  primary?: BooleanFilter | null;
  rowId?: IntFilter | null;
  service?: StringFilter | null;
  ticket?: StringFilter | null;
  userId?: IntFilter | null;
};
export type MamaCasServiceticketToManyMamaCasProxygrantingticketFilter = {
  every?: MamaCasProxygrantingticketFilter | null;
  none?: MamaCasProxygrantingticketFilter | null;
  some?: MamaCasProxygrantingticketFilter | null;
};
export type AuthUserToManyMamaCasProxyticketFilter = {
  every?: MamaCasProxyticketFilter | null;
  none?: MamaCasProxyticketFilter | null;
  some?: MamaCasProxyticketFilter | null;
};
export type AuthUserToManyMamaCasServiceticketFilter = {
  every?: MamaCasServiceticketFilter | null;
  none?: MamaCasServiceticketFilter | null;
  some?: MamaCasServiceticketFilter | null;
};
export type AuthUserToManyNotificationsNotificationFilter = {
  every?: NotificationsNotificationFilter | null;
  none?: NotificationsNotificationFilter | null;
  some?: NotificationsNotificationFilter | null;
};
export type AuthUserToManyOauth2ProviderAccesstokenFilter = {
  every?: Oauth2ProviderAccesstokenFilter | null;
  none?: Oauth2ProviderAccesstokenFilter | null;
  some?: Oauth2ProviderAccesstokenFilter | null;
};
export type Oauth2ProviderAccesstokenFilter = {
  and?: ReadonlyArray<Oauth2ProviderAccesstokenFilter> | null;
  applicationId?: BigIntFilter | null;
  authUserByUserId?: AuthUserFilter | null;
  authUserByUserIdExists?: boolean | null;
  created?: DatetimeFilter | null;
  expires?: DatetimeFilter | null;
  not?: Oauth2ProviderAccesstokenFilter | null;
  oauth2ProviderApplicationByApplicationId?: Oauth2ProviderApplicationFilter | null;
  oauth2ProviderApplicationByApplicationIdExists?: boolean | null;
  oauth2ProviderRefreshtokenByAccessTokenId?: Oauth2ProviderRefreshtokenFilter | null;
  oauth2ProviderRefreshtokenByAccessTokenIdExists?: boolean | null;
  oauth2ProviderRefreshtokenBySourceRefreshTokenId?: Oauth2ProviderRefreshtokenFilter | null;
  oauth2ProviderRefreshtokenBySourceRefreshTokenIdExists?: boolean | null;
  or?: ReadonlyArray<Oauth2ProviderAccesstokenFilter> | null;
  rowId?: BigIntFilter | null;
  scope?: StringFilter | null;
  sourceRefreshTokenId?: BigIntFilter | null;
  token?: StringFilter | null;
  updated?: DatetimeFilter | null;
  userId?: IntFilter | null;
};
export type Oauth2ProviderRefreshtokenFilter = {
  accessTokenId?: BigIntFilter | null;
  and?: ReadonlyArray<Oauth2ProviderRefreshtokenFilter> | null;
  applicationId?: BigIntFilter | null;
  authUserByUserId?: AuthUserFilter | null;
  created?: DatetimeFilter | null;
  not?: Oauth2ProviderRefreshtokenFilter | null;
  oauth2ProviderAccesstokenByAccessTokenId?: Oauth2ProviderAccesstokenFilter | null;
  oauth2ProviderAccesstokenByAccessTokenIdExists?: boolean | null;
  oauth2ProviderAccesstokenBySourceRefreshTokenId?: Oauth2ProviderAccesstokenFilter | null;
  oauth2ProviderAccesstokenBySourceRefreshTokenIdExists?: boolean | null;
  oauth2ProviderApplicationByApplicationId?: Oauth2ProviderApplicationFilter | null;
  or?: ReadonlyArray<Oauth2ProviderRefreshtokenFilter> | null;
  revoked?: DatetimeFilter | null;
  rowId?: BigIntFilter | null;
  token?: StringFilter | null;
  updated?: DatetimeFilter | null;
  userId?: IntFilter | null;
};
export type Oauth2ProviderApplicationFilter = {
  and?: ReadonlyArray<Oauth2ProviderApplicationFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  authUserByUserIdExists?: boolean | null;
  authorizationGrantType?: StringFilter | null;
  clientId?: StringFilter | null;
  clientSecret?: StringFilter | null;
  clientType?: StringFilter | null;
  created?: DatetimeFilter | null;
  name?: StringFilter | null;
  not?: Oauth2ProviderApplicationFilter | null;
  oauth2ProviderAccesstokensByApplicationId?: Oauth2ProviderApplicationToManyOauth2ProviderAccesstokenFilter | null;
  oauth2ProviderAccesstokensByApplicationIdExist?: boolean | null;
  oauth2ProviderGrantsByApplicationId?: Oauth2ProviderApplicationToManyOauth2ProviderGrantFilter | null;
  oauth2ProviderGrantsByApplicationIdExist?: boolean | null;
  oauth2ProviderRefreshtokensByApplicationId?: Oauth2ProviderApplicationToManyOauth2ProviderRefreshtokenFilter | null;
  oauth2ProviderRefreshtokensByApplicationIdExist?: boolean | null;
  or?: ReadonlyArray<Oauth2ProviderApplicationFilter> | null;
  redirectUris?: StringFilter | null;
  rowId?: BigIntFilter | null;
  skipAuthorization?: BooleanFilter | null;
  updated?: DatetimeFilter | null;
  userId?: IntFilter | null;
};
export type Oauth2ProviderApplicationToManyOauth2ProviderAccesstokenFilter = {
  every?: Oauth2ProviderAccesstokenFilter | null;
  none?: Oauth2ProviderAccesstokenFilter | null;
  some?: Oauth2ProviderAccesstokenFilter | null;
};
export type Oauth2ProviderApplicationToManyOauth2ProviderGrantFilter = {
  every?: Oauth2ProviderGrantFilter | null;
  none?: Oauth2ProviderGrantFilter | null;
  some?: Oauth2ProviderGrantFilter | null;
};
export type Oauth2ProviderGrantFilter = {
  and?: ReadonlyArray<Oauth2ProviderGrantFilter> | null;
  applicationId?: BigIntFilter | null;
  authUserByUserId?: AuthUserFilter | null;
  code?: StringFilter | null;
  created?: DatetimeFilter | null;
  expires?: DatetimeFilter | null;
  not?: Oauth2ProviderGrantFilter | null;
  oauth2ProviderApplicationByApplicationId?: Oauth2ProviderApplicationFilter | null;
  or?: ReadonlyArray<Oauth2ProviderGrantFilter> | null;
  redirectUri?: StringFilter | null;
  rowId?: BigIntFilter | null;
  scope?: StringFilter | null;
  updated?: DatetimeFilter | null;
  userId?: IntFilter | null;
};
export type Oauth2ProviderApplicationToManyOauth2ProviderRefreshtokenFilter = {
  every?: Oauth2ProviderRefreshtokenFilter | null;
  none?: Oauth2ProviderRefreshtokenFilter | null;
  some?: Oauth2ProviderRefreshtokenFilter | null;
};
export type AuthUserToManyOauth2ProviderApplicationFilter = {
  every?: Oauth2ProviderApplicationFilter | null;
  none?: Oauth2ProviderApplicationFilter | null;
  some?: Oauth2ProviderApplicationFilter | null;
};
export type AuthUserToManyOauth2ProviderGrantFilter = {
  every?: Oauth2ProviderGrantFilter | null;
  none?: Oauth2ProviderGrantFilter | null;
  some?: Oauth2ProviderGrantFilter | null;
};
export type AuthUserToManyOauth2ProviderRefreshtokenFilter = {
  every?: Oauth2ProviderRefreshtokenFilter | null;
  none?: Oauth2ProviderRefreshtokenFilter | null;
  some?: Oauth2ProviderRefreshtokenFilter | null;
};
export type AuthUserToManyPaypalOrderFilter = {
  every?: PaypalOrderFilter | null;
  none?: PaypalOrderFilter | null;
  some?: PaypalOrderFilter | null;
};
export type RegistrationRegistrationprofileFilter = {
  activated?: BooleanFilter | null;
  activationKey?: StringFilter | null;
  and?: ReadonlyArray<RegistrationRegistrationprofileFilter> | null;
  authUserByUserId?: AuthUserFilter | null;
  not?: RegistrationRegistrationprofileFilter | null;
  or?: ReadonlyArray<RegistrationRegistrationprofileFilter> | null;
  registrationSupervisedregistrationprofileByRegistrationprofilePtrId?: RegistrationSupervisedregistrationprofileFilter | null;
  registrationSupervisedregistrationprofileByRegistrationprofilePtrIdExists?: boolean | null;
  rowId?: IntFilter | null;
  userId?: IntFilter | null;
};
export type RegistrationSupervisedregistrationprofileFilter = {
  and?: ReadonlyArray<RegistrationSupervisedregistrationprofileFilter> | null;
  not?: RegistrationSupervisedregistrationprofileFilter | null;
  or?: ReadonlyArray<RegistrationSupervisedregistrationprofileFilter> | null;
  registrationRegistrationprofileByRegistrationprofilePtrId?: RegistrationRegistrationprofileFilter | null;
  registrationprofilePtrId?: IntFilter | null;
};
export type AuthUserToManySecuritiesAgreementFilter = {
  every?: SecuritiesAgreementFilter | null;
  none?: SecuritiesAgreementFilter | null;
  some?: SecuritiesAgreementFilter | null;
};
export type AuthUserToManySecuritiesCancelorderFilter = {
  every?: SecuritiesCancelorderFilter | null;
  none?: SecuritiesCancelorderFilter | null;
  some?: SecuritiesCancelorderFilter | null;
};
export type AuthUserToManySecuritiesGrantFilter = {
  every?: SecuritiesGrantFilter | null;
  none?: SecuritiesGrantFilter | null;
  some?: SecuritiesGrantFilter | null;
};
export type AuthUserToManySecuritiesOrderFilter = {
  every?: SecuritiesOrderFilter | null;
  none?: SecuritiesOrderFilter | null;
  some?: SecuritiesOrderFilter | null;
};
export type AuthUserToManySecuritiesOriginationfeeFilter = {
  every?: SecuritiesOriginationfeeFilter | null;
  none?: SecuritiesOriginationfeeFilter | null;
  some?: SecuritiesOriginationfeeFilter | null;
};
export type AuthUserToManySecuritiesProfileFilter = {
  every?: SecuritiesProfileFilter | null;
  none?: SecuritiesProfileFilter | null;
  some?: SecuritiesProfileFilter | null;
};
export type AuthUserToManySecuritiesReferralfingerprintFilter = {
  every?: SecuritiesReferralfingerprintFilter | null;
  none?: SecuritiesReferralfingerprintFilter | null;
  some?: SecuritiesReferralfingerprintFilter | null;
};
export type SecuritiesReferralfingerprintFilter = {
  and?: ReadonlyArray<SecuritiesReferralfingerprintFilter> | null;
  authUserByReferralId?: AuthUserFilter | null;
  fingerprintId?: IntFilter | null;
  not?: SecuritiesReferralfingerprintFilter | null;
  or?: ReadonlyArray<SecuritiesReferralfingerprintFilter> | null;
  referralId?: IntFilter | null;
  rowId?: IntFilter | null;
  securitiesFingerprintByFingerprintId?: SecuritiesFingerprintFilter | null;
};
export type SecuritiesFingerprintFilter = {
  and?: ReadonlyArray<SecuritiesFingerprintFilter> | null;
  fhash?: StringFilter | null;
  not?: SecuritiesFingerprintFilter | null;
  or?: ReadonlyArray<SecuritiesFingerprintFilter> | null;
  rowId?: IntFilter | null;
  securitiesReferralfingerprintsByFingerprintId?: SecuritiesFingerprintToManySecuritiesReferralfingerprintFilter | null;
  securitiesReferralfingerprintsByFingerprintIdExist?: boolean | null;
};
export type SecuritiesFingerprintToManySecuritiesReferralfingerprintFilter = {
  every?: SecuritiesReferralfingerprintFilter | null;
  none?: SecuritiesReferralfingerprintFilter | null;
  some?: SecuritiesReferralfingerprintFilter | null;
};
export type AuthUserToManyViewflowTaskFilter = {
  every?: ViewflowTaskFilter | null;
  none?: ViewflowTaskFilter | null;
  some?: ViewflowTaskFilter | null;
};
export type SecuritiesLegaldocToManySecuritiesAssetDocumentFilter = {
  every?: SecuritiesAssetDocumentFilter | null;
  none?: SecuritiesAssetDocumentFilter | null;
  some?: SecuritiesAssetDocumentFilter | null;
};
export type SecuritiesLegaldocToManySecuritiesDiscretionarygroupFilter = {
  every?: SecuritiesDiscretionarygroupFilter | null;
  none?: SecuritiesDiscretionarygroupFilter | null;
  some?: SecuritiesDiscretionarygroupFilter | null;
};
export type SecuritiesLegaldocToManySecuritiesIssueDocumentFilter = {
  every?: SecuritiesIssueDocumentFilter | null;
  none?: SecuritiesIssueDocumentFilter | null;
  some?: SecuritiesIssueDocumentFilter | null;
};
export type SecuritiesIssueToManySecuritiesIssueofferFilter = {
  every?: SecuritiesIssueofferFilter | null;
  none?: SecuritiesIssueofferFilter | null;
  some?: SecuritiesIssueofferFilter | null;
};
export type useSubscribeIssueOfferQuery$variables = {
  assetId: number;
  issueFilter: SecuritiesIssueFilter;
};
export type useSubscribeIssueOfferQuery$data = {
  readonly asset: {
    readonly issues: {
      readonly nodes: ReadonlyArray<{
        readonly offers: {
          readonly nodes: ReadonlyArray<{
            readonly baseAssetId: number;
            readonly basePrice: number | null;
            readonly bonusPercent: number | null;
            readonly closeDate: any;
            readonly feeRate: number | null;
            readonly maxUnits: number | null;
            readonly minUnits: number | null;
            readonly offerDate: any;
            readonly quote: {
              readonly exchange: {
                readonly nodes: ReadonlyArray<{
                  readonly rate: number;
                } | null>;
              };
            } | null;
            readonly quoteAssetId: number;
            readonly rowId: number;
            readonly securitiesIssueByBonusId: {
              readonly ofId: number;
            } | null;
            readonly subs: {
              readonly nodes: ReadonlyArray<{
                readonly quantity: number;
              } | null>;
            };
            readonly units: number;
          } | null>;
        };
      } | null>;
    };
  } | null;
};
export type useSubscribeIssueOfferQuery = {
  response: useSubscribeIssueOfferQuery$data;
  variables: useSubscribeIssueOfferQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "assetId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "issueFilter"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "offerDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closeDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baseAssetId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quoteAssetId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "basePrice",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feeRate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxUnits",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minUnits",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "units",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bonusPercent",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ofId",
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "DATE_DESC"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSubscribeIssueOfferQuery",
    "selections": [
      {
        "alias": "asset",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesAsset",
        "kind": "LinkedField",
        "name": "securitiesAssetByRowId",
        "plural": false,
        "selections": [
          {
            "alias": "issues",
            "args": (v2/*: any*/),
            "concreteType": "SecuritiesIssuesConnection",
            "kind": "LinkedField",
            "name": "securitiesIssuesByOfId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SecuritiesIssue",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": "offers",
                    "args": null,
                    "concreteType": "SecuritiesIssueoffersConnection",
                    "kind": "LinkedField",
                    "name": "securitiesIssueoffersByIssueId",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SecuritiesIssueoffer",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SecuritiesIssue",
                            "kind": "LinkedField",
                            "name": "securitiesIssueByBonusId",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": "quote",
                            "args": null,
                            "concreteType": "SecuritiesProxyasset",
                            "kind": "LinkedField",
                            "name": "securitiesProxyassetByQuoteAssetId",
                            "plural": false,
                            "selections": [
                              {
                                "alias": "exchange",
                                "args": (v15/*: any*/),
                                "concreteType": "SecuritiesIssueofferexchangeratesConnection",
                                "kind": "LinkedField",
                                "name": "securitiesIssueofferexchangeratesByQuoteAssetId",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SecuritiesIssueofferexchangerate",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                      (v16/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "securitiesIssueofferexchangeratesByQuoteAssetId(first:1,orderBy:\"DATE_DESC\")"
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": "subs",
                            "args": null,
                            "concreteType": "SecuritiesIssuesubscriptionsConnection",
                            "kind": "LinkedField",
                            "name": "securitiesIssuesubscriptionsByOfferId",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SecuritiesIssuesubscription",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  (v17/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSubscribeIssueOfferQuery",
    "selections": [
      {
        "alias": "asset",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesAsset",
        "kind": "LinkedField",
        "name": "securitiesAssetByRowId",
        "plural": false,
        "selections": [
          {
            "alias": "issues",
            "args": (v2/*: any*/),
            "concreteType": "SecuritiesIssuesConnection",
            "kind": "LinkedField",
            "name": "securitiesIssuesByOfId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SecuritiesIssue",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": "offers",
                    "args": null,
                    "concreteType": "SecuritiesIssueoffersConnection",
                    "kind": "LinkedField",
                    "name": "securitiesIssueoffersByIssueId",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SecuritiesIssueoffer",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SecuritiesIssue",
                            "kind": "LinkedField",
                            "name": "securitiesIssueByBonusId",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": "quote",
                            "args": null,
                            "concreteType": "SecuritiesProxyasset",
                            "kind": "LinkedField",
                            "name": "securitiesProxyassetByQuoteAssetId",
                            "plural": false,
                            "selections": [
                              {
                                "alias": "exchange",
                                "args": (v15/*: any*/),
                                "concreteType": "SecuritiesIssueofferexchangeratesConnection",
                                "kind": "LinkedField",
                                "name": "securitiesIssueofferexchangeratesByQuoteAssetId",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SecuritiesIssueofferexchangerate",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                      (v16/*: any*/),
                                      (v18/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "securitiesIssueofferexchangeratesByQuoteAssetId(first:1,orderBy:\"DATE_DESC\")"
                              },
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": "subs",
                            "args": null,
                            "concreteType": "SecuritiesIssuesubscriptionsConnection",
                            "kind": "LinkedField",
                            "name": "securitiesIssuesubscriptionsByOfferId",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SecuritiesIssuesubscription",
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  (v17/*: any*/),
                                  (v18/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38f80ba6783b83c77184b1ad62109b9b",
    "id": null,
    "metadata": {},
    "name": "useSubscribeIssueOfferQuery",
    "operationKind": "query",
    "text": "query useSubscribeIssueOfferQuery(\n  $assetId: Int!\n  $issueFilter: SecuritiesIssueFilter!\n) {\n  asset: securitiesAssetByRowId(rowId: $assetId) {\n    issues: securitiesIssuesByOfId(filter: $issueFilter) {\n      nodes {\n        offers: securitiesIssueoffersByIssueId {\n          nodes {\n            rowId\n            offerDate\n            closeDate\n            baseAssetId\n            quoteAssetId\n            basePrice\n            feeRate\n            maxUnits\n            minUnits\n            units\n            bonusPercent\n            securitiesIssueByBonusId {\n              ofId\n              id\n            }\n            quote: securitiesProxyassetByQuoteAssetId {\n              exchange: securitiesIssueofferexchangeratesByQuoteAssetId(first: 1, orderBy: DATE_DESC) {\n                nodes {\n                  rate\n                  id\n                }\n              }\n              id\n            }\n            subs: securitiesIssuesubscriptionsByOfferId {\n              nodes {\n                quantity\n                id\n              }\n            }\n            id\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "497daa2e73d8c818606af2adf00d781a";

export default node;
